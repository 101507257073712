<template>
  <q-card v-if="campus" class="text-left">
    <q-card-section class="text-h5 text-primary">
      <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense @click="router.back()"/>
      {{ campus.campus_name }} Campus
    </q-card-section>
    <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
        >
          <q-tab name="details" label="Details" />
          <q-tab v-if="userStore.check_roles(['Superuser', 'Support']) && org.billing_level==='Campus'" name="licenses" label="Licenses" />
          <q-tab name="users" label="Users" />
          <q-tab name="courses" label="Courses" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details">
        <q-form @submit.prevent="save" >
          <q-input
              class="q-pa-md"
              label="Campus Name"
              v-model="campus.campus_name"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-input>
          <q-input class="q-pa-md" label="City" v-model="campus.city" outlined></q-input>
          <q-input class="q-pa-md" label="State" v-model="campus.state" outlined></q-input>
          <q-select
              class="q-pa-md"
              label="Country"
              v-model="campus.country"
              map-options
              emit-value
              :options="countries"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-select>
          <q-btn class="q-ma-md" color="secondary" type="submit">Save</q-btn>
          <q-btn class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="licenses">
        <LicensePolicy entity_type="campus" :entity_uid="campus_uid" :entity="campus"></LicensePolicy>
      </q-tab-panel>
      <q-tab-panel name="users">
        <UsersView :organization_uid="organization_uid" :campus_uid="campus_uid"></UsersView>
      </q-tab-panel>
      <q-tab-panel name="courses">
        <Courses :organization_uid="organization_uid" :campus_uid="campus_uid"></Courses>
      </q-tab-panel>
    </q-tab-panels>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the Campus: {{campus.campus_name }}?</span><br>
          <span>This will delete the campus</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="regionStore.DeleteCampus(campus); $router.replace(`/organization/${organization_uid}`)" />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-card>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
</template>

<script>
import { ref, reactive, onMounted, nextTick, watch } from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore} from "../stores/region";
import { storeToRefs } from 'pinia'
import UsersView from "./UsersView.vue";
import Courses from "./Courses.vue";
import { useRouter} from "vue-router";
import LicensePolicy from "../components/LicensePolicy.vue";

export default {
  name: "Campus",
  components: {LicensePolicy, Courses, UsersView},
  props:['is_new', 'campus_uid', 'organization_uid'],
  setup(props){
    const router = useRouter();
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore);
    const campus = ref()
    const loading = ref(false);
    const confirm_delete = ref(false);
    onMounted(() => {
      if (!regionStore.org || props.organization_uid != regionStore.org.uid) {
        regionStore.GetOrganization(props.organization_uid);
      }
      if (props.is_new) {
        campus.value = {
          uid: null,
          campus_name: '',
          city: '',
          state: '',
          country: '',
        }
      } else {
        regionStore.GetCampus(props.campus_uid).then(res => {
          campus.value = res;
        })
      }
    });
    return {
      org,
      userStore,
      confirm_delete,
      regionStore,
      router,
      tab: ref('details'),
      campus,
      countries: [
        {label: 'United States', value: 'US'},
        {label: 'Canada', value: 'CA'},
        {label: 'United Kingdom', value: 'UK'},
        {label: 'Australia', value: 'AU'},
        {label: 'Thailand', value: 'TH'}
      ],
      loading: loading,
      save(){
        loading.value = true;
        regionStore.UpdateCampus(campus.value).then((camp)=>{
          loading.value = false;
          campus.value = camp;
          if(props.is_new){
            router.replace(`/organization/${props.organization_uid}/campus/${camp.uid}`);
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
