<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
      <q-card style="min-height: 400px; min-width: 200px">
        <Form v-if="flowsheet" :flowsheet="flowsheet" :data="data" dialog ref="inner_form" :timestamp="timestamp" :show_timestamp="show_timestamp" :readonly="readonly" @update:timestamp="update_timestamp"></Form>
        <q-card-actions>
          <q-btn v-if="!readonly"
            color="secondary"
            label="Save"
            @click="onOKClick"
          />
        </q-card-actions>
        <q-btn class="absolute-top-right" icon="close" flat round dense @click="onCancelClick()"></q-btn>
        <q-inner-loading
          :showing="loading"
          label="Please wait..."
          label-class="text-secondary"
          label-style="font-size: 1.1em"
        />
      </q-card>
  </q-dialog>
</template>

<script>
import {useDialogPluginComponent} from "quasar";
import Form from "./Form.vue";
import {onMounted, ref, inject} from "vue";
import {usePatientStore} from "@/stores/patient";

export default {
  name: "FlowsheetDialog",
  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],
  components: {Form},
  props: ["patient", "flowsheet_uid", "version", "timestamp", "show_timestamp", "readonly"],
  setup(props) {
    const flowsheet = ref(null);
    const data = ref(null);
    const loading = ref(false);
    const patientStore = usePatientStore();
    const inner_form = ref(null);
    const current_timestamp = ref(null);

    const versioned = inject("versioned");

    onMounted(() => {
      current_timestamp.value = props.timestamp;
      console.log("FlowsheetDialog mounted");
      loading.value = true;
      patientStore.GetPatientDataForFlowsheet(props.patient.uid, props.flowsheet_uid, props.version.uid, props.version.uid).then((output) => {
        flowsheet.value = output.flowsheet;
        data.value = output.patient_data;
        if(!data.value){
          data.value = {times:[]};
        }
        if(inner_form.value) inner_form.value.reset_data(data.value);
        loading.value = false;
      });
    });


    // REQUIRED; must be called inside of setup()
    const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome

    return {
      inner_form,
      flowsheet,
      data,
      loading,
      update_timestamp(timestamp){
        current_timestamp.value = timestamp;
      },
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        inner_form.value.save();
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK({timestamp: current_timestamp.value})
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel
    }
  }
}
</script>

<style scoped>

</style>