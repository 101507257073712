<template>
    <q-drawer show-if-above v-model="leftDrawerOpen" side="left" elevated :width="200">
      <q-list>
        <q-item clickable to="/dashboard" :active="$route.path=='/dashboard'" active-class="bg-primary text-white">
          <q-item-section>
            Home
          </q-item-section>
        </q-item>
        <q-item v-if="user.schools.length > 0" clickable to="/courses" active-class="bg-primary text-white">
          <q-item-section>
            Courses
          </q-item-section>
        </q-item>
        <q-item v-if="userStore.check_roles(['Superuser', 'Support'])" clickable to="/organization" :active="$route.path.startsWith('/organization')" active-class="bg-primary text-white text-bold">
          <q-item-section>
            Organizations
          </q-item-section>
        </q-item>
        <q-item v-else-if="userStore.check_roles(['Organization Admin'], org.uid)" clickable :to="`/organization/${org.uid}`" :active="$route.path.startsWith('/organization')" active-class="bg-primary text-white text-bold">
          <q-item-section>
            Organization
          </q-item-section>
        </q-item>
        <q-item v-if="userStore.check_roles(['Superuser', 'Support'])" clickable to="/user" :active="$route.path.startsWith('/user')" active-class="bg-primary text-white text-bold">
          <q-item-section>
            Users
          </q-item-section>
        </q-item>
        <q-expansion-item
          expand-separator
          label="Templates"
          default-collapsed
        >
          <q-item :inset-level=".25" clickable to="/template_patients" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Patient Templates
            </q-item-section>
          </q-item>
          <q-item :inset-level=".25" clickable to="/template_activities" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Activity Templates
            </q-item-section>
          </q-item>
          <q-item :inset-level=".25" clickable to="/template_courses" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Course Templates
            </q-item-section>
          </q-item>
        </q-expansion-item>
          <q-expansion-item
          expand-separator
          label="Content Management"
          default-collapsed
          v-if="userStore.check_roles(['Superuser'])"
        >
          <q-item :inset-level=".25" clickable to="/versions" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Versions
            </q-item-section>
          </q-item>
          <q-item :inset-level=".25" clickable to="/flowsheets" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Flowsheets
            </q-item-section>
          </q-item>
          <q-item :inset-level=".25" clickable to="/lookups" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Lookups
            </q-item-section>
          </q-item>
          <q-item :inset-level=".25" clickable to="/charts" active-class="bg-primary text-white text-bold">
            <q-item-section>
              Visit Types
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-list>
    </q-drawer>

    <q-page-container class="bg-grey-4">
      <q-page class="q-pa-md-lg">
        <router-view />
      </q-page>
    </q-page-container>

</template>

<script>
import {useRouter} from "vue-router";
import {ref, inject} from "vue";
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";
import {storeToRefs} from "pinia";

export default {
  name: "LoggedIn",
  setup () {
    const leftDrawerOpen = inject('leftDrawerOpen')
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore)

    return {
      user,
      org,
      userStore,
      leftDrawerOpen,
      toggleLeftDrawer () {
        leftDrawerOpen.value = !leftDrawerOpen.value
      },
    }
  },
}
</script>

<style scoped>

</style>
