<template>
        <q-table
            title="Programs"
            style="height: calc(100vh - 140px);"
            :columns="columns" :rows="programs" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            v-model:pagination="pagination"
            @request="onRequest"
            :grid="$q.screen.xs"
            flat
            >
          <template v-slot:top-left>
            <div>
              <div class="row text-h5 text-primary q-mb-md">Programs</div>
              <div class="row">
                <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>
          </template>
          <template v-slot:top-right>
            <q-btn color="primary" :to="`/organization/${org.uid}/new_program`" icon="add">New Program</q-btn>
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td>
              <q-btn class="q-ma-sm" rounded color="primary" @click="select_program(props.row)" size="sm">Manage Program</q-btn>
            </q-td>
          </template>
          <template v-slot:loading>
              <q-inner-loading showing color="primary" />
          </template>
        </q-table>
</template>

<script>
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";
import {storeToRefs} from "pinia";
import {onMounted, ref} from "vue";

export default {
  name: "Programs",
  setup(){
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore)
    const programs = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'program_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        regionStore.GetPrograms(
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter).then(data => {
          if(typeof  data === 'string'){
            loading.value = false
            return
          }

          pagination.value.rowsNumber = data.length
          programs.value.splice(0, programs.value.length, ...data);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

    return {
      columns: [
        {
          name: 'name',
          label: 'Program Name',
          field: 'program_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'program_type',
          label: 'Program Type',
          field: 'program_type',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      org,
      programs,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      onRequest,
    }
  },
  methods:{
    select_program(program){
      this.$router.push(`/organization/${this.org.uid}/program/${program.uid}`)
    },
  }
}
</script>

<style scoped>

</style>
