<template>
  <q-card>
    <q-card-section>
      <q-btn class="absolute-top-right q-ma-md" color="primary" @click="codedialog=true; course_code=''" icon="add">Course Code</q-btn>
      <div v-if="!loading && courses.length !== 0" class="row text-h5 text-primary q-mb-md">Courses</div>
      <div class="row q-col-gutter-lg">
        <div class="col-12" v-for="course in courses">
          <q-card>
            <q-card-section class="bg-amber text-h5">
                      <span class="text-bold text-primary">{{ course.course_short_name }}</span><br>
              <span>{{ course.course_name }}</span><br>
            </q-card-section>
            <q-card-section>
              <span class="text-bold text-primary">End Date: </span>{{ date.formatDate(course.end_date, 'DD MMM YYYY HH:mm' ) }}
            </q-card-section>
            <q-markup-table separator="vertical" v-if="$q.screen.gt.sm">
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Patients</th>
                  <th>Due Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="activity in course.activities">
                  <td>{{ activity.activity_name }}</td>
                  <td style="max-width: 500px;">
                    <div style="white-space: break-spaces">
                      <q-chip size="sm" color="secondary" class="text-white" v-for="patient in activity.template_patients">{{ patient.name }}</q-chip>
                    </div>
                  </td>
                  <td>{{ date.formatDate(activity.activity_end_date, 'DD MMM YYYY HH:mm' ) }}</td>
                  <td><q-btn rounded size="sm" :to="`/student/course/${activity.course_uid}/activity/${ activity.uid }`" color="accent">View Activity</q-btn></td>
                </tr>
                <tr v-if="course.activity_count > 5">
                  <td colspan="4" class="text-center"><q-btn :to="`/student/course/${course.uid}/activities`">View All Activities</q-btn></td>
                </tr>
              </tbody>
            </q-markup-table>
            <template v-else>
              <q-card-section v-for="(activity, index) in course.activities" :class="index % 2 == 0 ? 'bg-grey-4' : ''">
                <span class="text-h6">{{ activity.activity_name }}</span><br>
                <q-chip size="sm" color="secondary" class="text-white" v-for="patient in activity.template_patients">{{ patient.name }}</q-chip><br>
                <span v-if="activity.activity_end_date" class="text-bold">Due:&nbsp;</span>{{ date.formatDate(activity.activity_end_date, 'DD MMM YYYY HH:mm' ) }}<br>
                <q-btn rounded size="sm" :to="`/student/course/${activity.course_uid}/activity/${ activity.uid }`" color="accent">View Activity</q-btn>
              </q-card-section>
            </template>
            <q-card-actions>
              <q-btn color="primary" :to="`/student/course/${course.uid}/activities`">View Course</q-btn>
            </q-card-actions>
          </q-card>
        </div>
        <div v-if="!loading && courses.length === 0" class="flex-center">
          <q-card>
            <q-card-section>
              <p class="text-h5 text-secondary text-center">Welcome to ChartFlow</p>
              <p class="text-h6">Click <q-btn @click="codedialog=true; course_code=''" color="primary" icon="add">Course Code</q-btn> to Add a New Course.</p>
              <p>Contact your Instructor if you do not have a Course Code.</p>
              <p>Once you have a Course you'll be able to View and Complete Activities</p>
            </q-card-section>
            <q-card-section>
              For more help, Click Here for our <a href="https://help.chartflow.io/articles/student-quick-start-guide" target="_blank">Student Quick Start Guide</a>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-card-section>
  </q-card>
    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />

    <q-dialog v-model="codedialog">
      <q-card>
        <q-card-section>
          <q-btn class="absolute-top-right q-ma-md" icon="close" flat round dense @click="codedialog=false"></q-btn>
          <div class="q-mt-lg text-h5">Enter Course Access Code to add course</div>
          <q-input label="Access Code" outlined pattern="[^0-9a-fA-f]*$" v-model="course_code"
                   :rules="[ val => /[^0-9a-fA-f]*$/.test(val) || `Course Codes must be digits and the letters a-f`]"
          ></q-input>
        </q-card-section>
        <q-card-actions>
          <q-btn color="secondary" @click="addCourse">OK</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

</template>

<script>
import {useUserStore} from "../../stores/user";
import {useRegionStore} from "../../stores/region";
import {storeToRefs} from "pinia";
import {onMounted, ref} from "vue";
import StudentActivites from "./StudentActivities.vue";
import {date} from "quasar";

export default {
  name: "StudentDashboard",
  components: {StudentActivites},
  setup() {
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { user } = storeToRefs(userStore);
    const { org } = storeToRefs(regionStore)
    const courses = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const codedialog = ref(false)
    const course_code = ref("")

    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'course_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        regionStore.GetStudentDashboardActivities().then(data => {
            courses.value = []
            const school = user.value.schools.find(school => school.organization_uid === org.value.uid)
            for(let course of school.courses) {
                if(course.end_date && date.getDateDiff(course.end_date, new Date(), 'minutes') <= 0) continue;
                const course_data = data.data.find(course_data => course_data.course_uid === course.uid)
                if (course_data){
                  course.activities = course_data.activities;
                  course.activity_count = course_data.activity_count;
                }
                courses.value.push(course)
            }
            loading.value = false
        });
    }

    function addCourse() {
      loading.value = true;
      userStore.AddCourseCode(course_code.value).then(data => {
        onRequest({
          pagination: pagination.value,
          filter: undefined
        })
        codedialog.value = false;
        course_code.value = '';
      })
    }

    return {
      course_code,
      codedialog,
      show: true,
      courses,
      loading,
      addCourse,
      date
    }
  },
}
</script>

<style scoped>

</style>
