<template>
  <q-drawer side="left" v-model="show">
    <q-list>
      <q-item class="q-pa-md bg-primary text-white text-h5">
        <q-item-section>
          <q-item-label>Flowsheets</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
        </q-item-section>
      </q-item>
      <draggable v-model="available_flowsheets" item-key="uid" :sort="false" :group="{name: 'flowsheets', put: false}">
        <template #item="{element}">
          <q-card class="q-ma-md">
            <q-card-section>
              {{ element.flowsheet_name }}
            </q-card-section>
          </q-card>
        </template>
        <template #footer>
          <div v-intersection="intersection">More...</div>
        </template>
      </draggable>
    </q-list>
    <q-inner-loading
      :showing="flowsheets_loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  </q-drawer>

</template>

<script>
import {inject, ref, watch} from 'vue'
import draggable from 'vuedraggable'
import { useFlowsheetStore} from "../../stores/flowsheet";

export default {
  name: "FlowsheetList",
  components: {
    draggable
  },
  props: ["flowsheet_context"],
  setup(props){
    const flowsheetStore = useFlowsheetStore();
    const show = ref(true);
    const flowsheets_loading = ref(false);
    const filter = ref('');
    const available_flowsheets = ref([
       {
          flowsheet_type: 'Details',
          flowsheet_name: 'Patient Details',
          flowsheet_context: 'Patient',
          uid: 'patient_details'
        },
        {
          flowsheet_type: 'History',
          flowsheet_name: 'Visit History',
          flowsheet_context: 'Patient',
          uid: 'visit_history'
        }
    ]);
    const has_more = ref(true);
    const page = ref(1);

    const versioned = inject('versioned', null)

    function get_flowsheets(){
      flowsheets_loading.value = true;
      flowsheetStore.GetFlowsheets(page.value, null, null, null, null, versioned.value.uid).then(f => {
        if(props.flowsheet_context === "Patient"){
          f.flowsheets = f.flowsheets.filter(ff => ff.flowsheet_context === "Patient");
        }
        available_flowsheets.value.splice(available_flowsheets.value.length, 0, ...f.flowsheets);
        flowsheets_loading.value = false;
        page.value++;
        if(f.flowsheets.length === 0) has_more.value = false;
      });
    }

    watch(versioned, (newVal, oldVal) => {
      get_flowsheets()
    });

    return {
      show,
      flowsheets_loading,
      filter,
      available_flowsheets,
      intersection(entry){
      if(entry.isIntersecting && has_more.value && versioned.value){
        get_flowsheets()
      }
    },

    }
  }
}
</script>

<style scoped>

</style>