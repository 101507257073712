<template>
        <q-table
            class="q-pa-md"
            title="Patient Templates"
            style="height: calc(100vh - 120px);"
            :columns="columns" :rows="templates" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            v-model:pagination="pagination"
            @request="onRequest"
            :grid="$q.screen.xs"
            wrap-cells
            >
          <template v-slot:top-left>
            <div>
              <div class="row text-h5 text-primary q-mb-md">Patient Templates</div>
              <div class="row">
                <div class="col"><q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:top-right>
            <div style="display: block;">
              <div class="row q-mb-md">
                <q-btn-toggle class="block" v-model="filtering" :options="filter_options"/>
              </div>
              <div class="row float-right">
                <q-btn color="primary" icon="add" label="New Template Patient" to="/new_patient?template=true">
                </q-btn>
              </div>
            </div>
          </template>
          <template v-slot:loading>
              <q-inner-loading showing color="primary" />
          </template>
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th auto-width>Details</q-th>
              <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
              >
                {{ col.label }}
              </q-th>
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr :style="props.rowIndex % 2 ? 'background-color: white;' : ''" :props="props">
              <q-td auto-width>
                <q-btn size="sm" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'" />
              </q-td>
              <q-td
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
              >
                <span v-if="col.name==='actions'">
                  <q-btn v-if="can_edit(props.row)" class="q-ma-sm" size="sm" rounded color="primary" @click="edit_patient(props.row)">Edit Patient</q-btn>
                  <q-btn v-else class="q-ma-sm" size="sm" rounded color="primary" @click="edit_patient(props.row)">Preview Patient</q-btn>
                  <q-btn class="q-ma-sm" size="sm" outline rounded color="primary" @click="copyedit_patient(props.row)">Copy &amp; Edit</q-btn>
                </span>
                <span v-else-if="col.name==='owner'">
                  <span v-if="props.row.system_patient===true">System Patient</span>
                  <span v-else-if="props.row.system_draft_patient===true">Draft System Patient</span>
                  <template v-else-if="props.row.user_embed">
                    <UserIcon :user="props.row.user_embed" color="primary" size="30px"/>
                    <span>&nbsp;{{ props.row.user_embed.username }}</span>
                    <span class="text-caption text-grey-8" v-if="props.row.organization_embed"><br>Visible to {{ props.row.organization_embed.name }}</span>
                    <span class="text-caption text-grey-8" v-else-if="props.row.program_embed"><br>Visible to {{ props.row.program_embed.name }}</span>
                    <span class="text-caption text-grey-8" v-else><br>Private</span>
                  </template>
                  <template v-else-if="props.row.organization_embed">
                    {{ props.row.organization_embed.name }}
                  </template>
                  <template v-else-if="props.row.organization_draft_embed">
                    {{ props.row.organization_draft_embed.name }}
                  </template>
                  <template v-else-if="props.row.program_embed">
                    {{ props.row.program_embed.name }}
                  </template>
                  <template v-else-if="props.row.program_draft_embed">
                    {{ props.row.program_draft_embed.name }}
                  </template>

                </span>
                <span v-else-if="col.name==='name'">
                  <q-avatar v-if="props.row.patient_picture" color="white" class="text-primary q-mr-sm" size="sm">
                    <img :src="props.row.patient_picture" alt="Patient Picture" />
                  </q-avatar>
                  <span>{{ col.value }}</span> <q-badge v-if="props.row.scenes && props.row.scenes.length > 0" color="secondary" label="Events"></q-badge>
                </span>
                <span v-else>
                  {{ col.value }}
                </span>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%">
                <div class="text-left" v-html="props.row.patient_description"></div>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:item="props">
            <q-card style="width: 100%" class="q-ma-md">
              <q-card-section class="bg-primary text-white">
                  <q-avatar v-if="props.row.patient_picture" color="white" class="text-primary q-mr-sm" size="sm">
                    <img :src="props.row.patient_picture" alt="Patient Picture" />
                  </q-avatar>
                  <span>{{ props.row.name }}</span>
              </q-card-section>
              <q-card-section>
                <span v-if="props.row.system_patient===true">System Patient</span>
                <span v-else-if="props.row.system_draft_patient===true">Draft System Patient</span>
                <template v-else-if="props.row.user_embed">
                  <UserIcon :user="props.row.user_embed" color="primary" size="30px"/>
                  <span>&nbsp;{{ props.row.user_embed.username }}</span>
                  <span class="text-caption text-grey-8" v-if="props.row.organization_embed"><br>Visible to {{ props.row.organization_embed.name }}</span>
                  <span class="text-caption text-grey-8" v-else-if="props.row.program_embed"><br>Visible to {{ props.row.program_embed.name }}</span>
                  <span class="text-caption text-grey-8" v-else><br>Private</span>
                </template>
                <template v-else-if="props.row.organization_embed">
                  {{ props.row.organization_embed.name }}
                </template>
                <template v-else-if="props.row.organization_draft_embed">
                  {{ props.row.organization_draft_embed.name }}
                </template>
                <template v-else-if="props.row.program_embed">
                  {{ props.row.program_embed.name }}
                </template>
                <template v-else-if="props.row.program_draft_embed">
                  {{ props.row.program_draft_embed.name }}
                </template>
              </q-card-section>
              <q-expansion-item v-if="props.row.patient_description" label="Description">
                <q-card-section>
                  <div class="text-left" v-html="props.row.patient_description"></div>
                </q-card-section>
              </q-expansion-item>
              <q-card-actions>
                <q-btn v-if="can_edit(props.row)" class="q-ma-sm" size="sm" rounded color="primary" @click="edit_patient(props.row)">Edit Patient</q-btn>
                <q-btn v-else class="q-ma-sm" size="sm" rounded color="primary" @click="edit_patient(props.row)">Preview Patient</q-btn>
                <q-btn class="q-ma-sm" size="sm" outline rounded color="primary" @click="copyedit_patient(props.row)">Copy &amp; Edit</q-btn>
              </q-card-actions>
            </q-card>
          </template>


        </q-table>
</template>

<script>
import {useUserStore} from "../../stores/user";
import {useRegionStore} from "../../stores/region";
import {storeToRefs} from "pinia";
import {onMounted, ref, watch} from "vue";
import {usePatientStore} from "../../stores/patient";
import UserIcon from "../../components/UserIcon.vue";

export default {
  name: "PatientTemplates",
  components: {UserIcon},
  setup(){
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const regionStore = useRegionStore();
    const patientStore = usePatientStore();
    const { org } = storeToRefs(regionStore)
    const templates = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const filtering = ref('all');
    const filter_options = ref( [])
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })

      filter_options.value = [
        {label: 'All', value: 'all'},
        {label: 'System', value: 'system'},
      ]
      if(org.value){
        filter_options.value.push({label: 'Organization', value: 'organization'})
      }
      if(org.value && userStore.programs_for_organization(org.value.uid).length > 0){
        filter_options.value.push({label: 'Program', value: 'program'})
      }
      filter_options.value.push({label: 'My Patients', value: 'mine'})
    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        patientStore.GetTemplatePatients(
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter,
            filtering.value).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            templates.value.splice(0, templates.value.length, ...data.templates);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

    watch(filtering, () => {
      onRequest({
        pagination: pagination.value,
        filter: filter.value
      })
    })

    return {
      columns: [
        {
          name: 'name',
          label: 'Patient Name',
          field: 'name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'mrn',
          label: 'MRN',
          field: 'mrn',
          align: 'left',
          sortable: true,
        },
        {
          name: 'sex',
          label: 'sex',
          field: 'sex',
          align: 'left',
          sortable: true
        },
        {
          name: 'age',
          label: 'age',
          field: 'age',
          align: 'left',
          sortable: true
        },
        {
          name: 'owner',
          label: 'Owner',
          field: 'organization_uid',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      user,
      org,
      templates,
      loading,
      userStore,
      regionStore,
      filter,
      filtering,
      filter_options,
      pagination,
      onRequest,
      patientStore
    }
  },
  methods:{
    edit_patient(patient){
      this.patientStore.SetPatient(null);
      this.$router.push(`/patient/${patient.uid}`)
    },
    copyedit_patient(patient){
      this.patientStore.SetPatient(null);
      this.loading = true;
      this.patientStore.copy_patient(patient.uid).then(data => {
        this.$router.push(`/patient/${data.patient.uid}`)
      });
    },
    can_edit(patient){
      if(patient.user_embed && patient.user_embed.uid === this.user.uid){
        return true
      }
      if(patient.organization_embed && this.userStore.check_roles(['Organization Admin', 'Organization Content Admin'], patient.organization_embed.uid)){
        return true
      }
      if(patient.program_embed && this.userStore.check_roles(['Program Admin', 'Program Content Admin'], this.org.uid, patient.program_embed.uid)){
        return true
      }
      if(patient.organization_draft_embed && this.userStore.check_roles(['Organization Admin', 'Organization Content Admin'], patient.organization_draft_embed.uid)){
        return true
      }
      if(patient.program_draft_embed && this.userStore.check_roles(['Program Admin', 'Program Content Admin'], this.org.uid, patient.program_draft_embed.uid)){
        return true
      }
      if((patient.system_patient===true || patient.system_draft_patient===true) && this.userStore.check_roles(['Superuser', 'Content Manager']) ) return true;
      return false;
    }
  }
}
</script>

<style scoped>

</style>
