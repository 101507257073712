<template>
  <q-inner-loading
      :showing="true"
      label="Logging Out..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"></q-inner-loading>
</template>

<script>
import {useUserStore} from "../stores/user";

export default {
  name: "Logout",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    }
  },
  mounted() {
      this.userStore.Logout().then(() => {
        this.$router.push(this.userStore.default_route)
      }).catch(() => {
        this.$router.push(this.userStore.default_route)
      })
    }
}

</script>

<style scoped>

</style>
