<template>
  <span v-if="!edit">{{ modelValue ? modelValue.join(', ') : '' }}</span>
  <template v-else>
    <q-select
      outlined
      label="Enter Schedule Times"
      v-model="data"
      use-input
      use-chips
      multiple
      input-debounce="0"
      @input-value="onInputValue"
      @new-value="createValue"
      @blur="onBlur"
      :options="filterOptions"
      @filter="filterFn"
      ref="scheduleField"
    />
    <span class="text-caption">Please enter in military format (03:30), separate by enter</span>
  </template>
</template>

<script>
import { onMounted, ref, watch } from "vue";

export default {
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'readonly', 'outlined', 'dense', 'patient'],
  name: "ScheduleField",
  setup(props, context){
    const data = ref(null);
    const iValue = ref('');
    const scheduleField = ref(null);
    const filterOptions = ref([]);

    onMounted(() => {
      if (props.modelValue === undefined || props.modelValue === null && props.edit) {
        data.value = [];
      }
      else{
        data.value = props.modelValue;
      }
    })

    watch(data, (newVal) => {
      newVal = newVal.filter(n => n)
      if(newVal && newVal.length === 0){
        newVal.value = null;
      }
      context.emit('update:modelValue', newVal);
    }, {deep: true});

    const createValue = function(val, done) {
          // Calling done(var) when new-value-mode is not set or is "add", or done(var, "add") adds "var" content to the model
          // and it resets the input textbox to empty string
          // ----
          // Calling done(var) when new-value-mode is "add-unique", or done(var, "add-unique") adds "var" content to the model
          // only if is not already set and it resets the input textbox to empty string
          // ----
          // Calling done(var) when new-value-mode is "toggle", or done(var, "toggle") toggles the model with "var" content
          // (adds to model if not already in the model, removes from model if already has it)
          // and it resets the input textbox to empty string
          // ----
          // If "var" content is undefined/null, then it doesn't tampers with the model
          // and only resets the input textbox to empty string

          if (val.length > 0) {
            const modelValue = (data.value || []).slice()

            val
              .split(/[,;|]+/)
              .map(v => v.trim())
              .filter(v => v.length > 0)
              .forEach(v => {
                /*if (stringOptions.includes(v) === false) {
                  stringOptions.push(v)
                }*/
                if (modelValue.includes(v) === false) {
                  modelValue.push(v)
                }
              })

            done();
            data.value = modelValue
            scheduleField.value.updateInputValue('')
          }
      }

    return {
      filterOptions,
      data,
      scheduleField,
      createValue,
      onInputValue(val) {
        console.log(val)
        console.log(`iValue: ${iValue.value}, val: ${val}`)
        if(iValue.value.length === 3 && val.length === 2){
          scheduleField.value.updateInputValue(val.slice(0, 1));
          return;
        }
        for(let i = 0; i < val.length; i++){
          if([0, 1, 3, 4].includes(i) && isNaN(val[i])){
            scheduleField.value.updateInputValue(iValue.value);
            return;
          }
        }
        if(val.length === 2){
          iValue.value = `${val}:`
          scheduleField.value.updateInputValue(val + ':');
          return;
        }

        let input = val.split(/[,;|\s]+/)
        if (input.length > 1) {
          createValue(val, () => {})
          return
        }

        if(val.length > 5){
          scheduleField.value.updateInputValue(iValue.value);
          return;
        }

        iValue.value = val.slice(0);
      },

      onBlur() {
        if(iValue.value){
          createValue(iValue.value, () => {})
        }
      },

      filterFn (val, update) {
          update(() => {
            if (val === '') {
              filterOptions.value = []//stringOptions
            }
            else {
              const needle = val.toLowerCase()
              /*filterOptions.value = stringOptions.filter(
                v => v.toLowerCase().indexOf(needle) > -1
              )*/
            }
          })
        }
      }

  }
}
</script>

<style scoped>

</style>