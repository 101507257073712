<template>
  <q-card v-if="program" class="text-left">
    <q-card-section class="text-h5 text-primary">
      <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/organization/${organization_uid}`"/>
      {{ program.program_name }} Program
    </q-card-section>
    <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
        >
          <q-tab name="details" label="Details" />
          <q-tab v-if="userStore.check_roles(['Superuser', 'Support']) && org.billing_level==='Program'" name="licenses" label="Licenses" />
          <q-tab name="users" label="Users" />
          <q-tab name="courses" label="courses" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details">
        <q-form @submit.prevent="save" >
          <q-input
              class="q-pa-md"
              label="Program Name"
              v-model="program.program_name"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-input>
          <q-select
              class="q-pa-md"
              label="Program Type"
              v-model="program.program_type"
              :options="program_types"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-select>
          <q-btn class="q-ma-md" color="secondary" type="submit">Save</q-btn>
          <q-btn class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="licenses">
        <LicensePolicy entity_type="program" :entity_uid="program_uid" :entity="program"></LicensePolicy>
      </q-tab-panel>
      <q-tab-panel name="users">
        <UsersView :organization_uid="organization_uid" :program_uid="program_uid"></UsersView>
      </q-tab-panel>
      <q-tab-panel name="courses">
        <Courses :organization_uid="organization_uid" :program_uid="program_uid"></Courses>
      </q-tab-panel>
    </q-tab-panels>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the Program: {{program.program_name }}?</span><br>
          <span>This will delete the program</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="regionStore.DeleteProgram(program); $router.replace(`/organization/${organization_uid}`)" />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-card>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
</template>

<script>
import { ref, reactive, onMounted, nextTick, watch } from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore} from "../stores/region";
import { useRouter} from "vue-router";
import { storeToRefs } from 'pinia'
import UsersView from "./UsersView.vue";
import Courses from "./Courses.vue";
import LicensePolicy from "@/components/LicensePolicy.vue";

export default {
  name: "Program",
  components: {LicensePolicy, Courses, UsersView},
  props:['is_new', 'program_uid', 'organization_uid'],
  setup(props){
    const router = useRouter();
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore);
    const program = ref(null);
    const loading = ref(false);
    const confirm_delete = ref(false);
    onMounted(() => {
      if (!regionStore.org || props.organization_uid != regionStore.org.uid) {
        regionStore.GetOrganization(props.organization_uid);
      }
      if (props.is_new) {
        program.value = {
          program_name: '',
          program_type: '',
        }
      } else {
        regionStore.GetProgram(props.program_uid).then(res => {
          program.value = res;
        });
      }
    });
    return {
      org,
      userStore,
      confirm_delete,
      regionStore,
      tab: ref('details'),
      program,
      program_types: ref([
        'Medical Assisting',
        'LPN',
        'BSN',
        'CNA',
        'MSN',
        'ADN',
        'Simulation',
        'Physician Assistant',
        'Respiratory Therapist',
        'Patient Care Technician',
        'Physical Therapy',
        'Physical Therapy Assistant',
        'Dental Assistant'
      ]),
      loading: loading,
      save(){
        loading.value = true;
        regionStore.UpdateProgram(program.value).then((p)=>{
          if(props.is_new){
            program.value = p;
            router.replace(`/organization/${props.organization_uid}/program/${program.value.uid}`);
          }
          loading.value = false;
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
