<template>
    <Versioned @selected="versioned = $event"></versioned>
    <q-table
        class="q-pa-md"
        title="Lookup Types"
        style="height: calc(100vh - 120px);"
        :columns="columns" :rows="lookup_types" row-key="uid"
          separator="vertical" :loading="loading" :filter="filter"
        v-model:pagination="pagination"
        @request="onRequest"
        :grid="$q.screen.xs"
        >
        <template v-slot:top-left>
          <div>
            <div class="row text-h5 text-primary q-mb-md">Lookup Types</div>
            <div class="row">
              <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
        </template>

          <template v-slot:header="props">
              <q-tr :props="props">
                <q-th auto-width />
                <q-th
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                >
                  {{ col.label }}
                </q-th>
              </q-tr>
            </template>
            <template v-slot:body="props">
            <q-tr :style="props.rowIndex % 2 ? 'background-color: white;' : ''" :props="props">
              <q-td auto-width>
                <q-btn v-if="props.row.versions.length > 1" size="sm" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'" />
              </q-td>
              <template v-for="col in props.cols" :key="col.name">
                <q-td v-if="col.name === 'actions'" auto-width>
                  <q-btn v-if="versioned && versioned.uid === props.row.flowsheet_version && versioned.uid!=='published'" class="q-ma-sm" rounded color="primary" :to="`/lookups/${props.row.flowsheet_uid}/${props.row.uid}?version=${props.row.version}`"  size="sm">Manage Lookups</q-btn>
                  <q-btn v-else-if="versioned && versioned.uid !== 'published'" class="q-ma-sm" rounded color="primary" :to="`/lookups/${props.row.flowsheet_uid}/${props.row.uid}?from=${props.row.flowsheet_version}&version=${versioned.uid}`" size="sm">Copy and Edit</q-btn>
                </q-td>
                <q-td v-else-if="col.name === 'lookup_type'">
                   <span>{{ col.value }}
                    <q-tooltip>
                      {{ props.row.uid }}
                    </q-tooltip>
                   </span>
                </q-td>
                <q-td v-else-if="col.name === 'favorite'" auto-width class="text-center">
                  <q-rating
                  v-model="props.row.favorite"
                  size="2em"
                  :max="1"
                  color="primary"
                  @update:model-value="onFavoriteUpdate(props.row)"
                  />
                </q-td>
                <q-td v-else>
                  {{ col.value }}
                </q-td>
              </template>
            </q-tr>
            <template v-for="row in props.row.versions">
              <q-tr v-if="row.version !== props.row.flowsheet_version" v-show="props.expand" :props="props">
                <q-td colspan="2">
                </q-td>
                <q-td>
                  {{ row.version_name}}
                </q-td>
                <q-td auto-width>
                   <q-btn v-if="versioned && versioned.uid === row.flowsheet_version && versioned.uid!=='published'" class="q-ma-sm" rounded color="primary" :to="`/lookups/${row.flowsheet_uid}/${row.uid}?version=${row.flowsheet_version}`" size="sm">Manage Lookups</q-btn>
                    <q-btn v-else-if="versioned && versioned.uid !== 'published' && props.row.flowsheet_version !== versioned.uid" class="q-ma-sm" rounded color="primary" :to="`/lookups/${row.flowsheet_uid}/${row.uid}?from=${row.flowsheet_version}&version=${versioned.uid}`" size="sm">Copy and Edit</q-btn>
                </q-td>
              </q-tr>
            </template>
          </template>


    </q-table>
</template>

<script>
  import { ref, onMounted, watch } from 'vue';
  import { useFlowsheetStore } from "../stores/flowsheet";
  import Versioned from "../components/Versioned.vue";
  import {debounce} from "quasar";

  export default {
  name: "LookupTypes",
  components: {
    Versioned
  },
  setup(){
    const versioned = ref(null);
    const flowsheetStore = useFlowsheetStore();
    const loading = ref(false);
    const filter = ref('');
    const lookup_types = ref([]);
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'favorite',
        descending: true,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    watch(() => versioned.value, () => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    })

    function onRequest(props) {
        if(!versioned.value) return
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        flowsheetStore.GetLookupTypes(
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter,
            versioned.value.uid
        ).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            lookup_types.value.splice(0, lookup_types.value.length, ...data.lookup_types);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

    function onFavoriteUpdate(lookup_type) {
      flowsheetStore.SaveLookupType(lookup_type).then(f => {
        console.log(f);
      });
    }
    onFavoriteUpdate = debounce(onFavoriteUpdate, 1000);


    return {
      columns: [
        {
          name: 'lookup_type',
          label: 'Lookup Type',
          field: 'field_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'version_name',
          label: 'Version',
          field: 'version_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'favorite',
          label: 'Favorite',
          field: 'favorite',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          align: 'left',
          sortable: false,
        }
      ],
      versioned,
      lookup_types,
      loading,
      filter,
      pagination,
      onRequest,
      onFavoriteUpdate
    }
  }

}
</script>

<style scoped>

</style>
