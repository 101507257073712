<template>
        <q-table
            title="Activities"
            style="height: calc(100vh - 240px);"
            :visible-columns="visibleColumns"
            :columns="columns" :rows="activities" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            v-model:pagination="pagination"
            @request="onRequest"
            :grid="$q.screen.xs"
            flat
            >
              <template v-if="!loading" v-slot:no-data="{ icon, message, filter }">
                <div class="full-width row flex-center q-gutter-sm">
                  <div class="block">
                    <p>Click <span class="text-bold">+New Activity</span> to create an assignment for your Students to Complete.</p>
                    <p>For more information, click here for our <a href="https://help.chartflow.io/articles/activities" target="_blank">Activities Guide</a></p>
                  </div>
                </div>
              </template>

          <template v-slot:top-left>
            <div>
              <div class="row text-h5 text-primary q-mb-md">Activities</div>
              <div class="row">
                <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>
          </template>
          <template v-slot:top-right>
            <q-btn-dropdown v-if="can_edit" color="primary" label="Add Activity" class="q-mr-md">
              <q-list>
                <q-item clickable v-close-popup @click="new_activity">
                  <q-item-section>
                    <q-item-label>New Activity</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="add_activity">
                  <q-item-section>
                    <q-item-label>Activity From Template</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
          <template v-slot:body-cell-patients="props">
            <q-td style="max-width: 500px;">
              <div style="white-space: break-spaces">
                <q-chip size="sm" color="secondary" class="text-white" v-for="patient in props.row.template_patients">{{ patient.name }}</q-chip>
              </div>
            </q-td>
          </template>
          <template v-slot:body-cell-visibility="props">
            <q-td>
              <q-toggle
                name="visibility"
                v-model="props.row.visibility"
                :label="props.row.visibility ? 'Visible' : 'Hidden'"
                @update:model-value="updateVisibility(props.row)"
              />
              <q-chip color="secondary" class="text-white" v-if="!props.row.visibility && props.row.activity_start_date"> Visible: {{ props.row.activity_start_date ? date.formatDate(props.row.activity_start_date, 'D MMM YYYY HH:mm') : '' }}</q-chip>
            </q-td>
          </template>
          <template v-slot:body-cell-activity_type="props">
            <q-td>
              <span>{{ props.value }}</span>
              <span v-if="props.row.assignment_type==='Group'">&nbsp; <q-badge color="secondary" class="text-bold">Group</q-badge></span>
            </q-td>
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td auto-width>
              <q-btn v-if="can_edit && course.course_type === 'template'" class="q-ma-sm" rounded color="primary" @click="edit_activity(props.row)" size="sm">Manage Activity</q-btn>
              <q-btn v-if="!can_edit && course.course_type === 'template'" class="q-ma-sm" rounded color="primary" @click="edit_activity(props.row)" size="sm">Preview Activity</q-btn>
              <q-btn v-if="course.course_type !== 'template'" class="q-ma-sm" rounded color="primary" @click="edit_activity(props.row)" size="sm">Grade &amp; Manage</q-btn>
              <q-btn v-if="course.course_type !== 'template'" class="q-ma-sm" rounded color="accent" :to="`/student/course/${props.row.course_uid}/activity/${props.row.uid}`" size="sm">Student View</q-btn>
              <q-btn v-if="course.course_type !== 'template' && props.row.activity_type==='Simulation'" class="q-ma-sm" rounded :to="`/sim/course/${props.row.course_uid}/activity/${props.row.uid}`" size="sm" icon="tune">Simulation Control</q-btn>
            </q-td>
          </template>
          <template v-slot:loading>
              <q-inner-loading showing color="primary" />
          </template>
          <template v-slot:item="props">
            <q-card style="width: 100%" class="q-ma-md">
              <q-card-section>
                <div class="text-h6">{{ props.row.activity_name }}</div>
                <div class="text-caption">{{ props.colsMap.activity_type.format(props.row) }}</div>
                <div class="text-caption" v-if="props.row.activity_end_date">Due: {{ date.formatDate(props.row.activity_end_date, 'D MMM YYYY HH:mm') }}</div>
              </q-card-section>
              <q-card-section>
                <q-toggle
                  name="visibility"
                  v-model="props.row.visibility"
                  :label="props.row.visibility ? 'Visible' : 'Hidden'"
                  @update:model-value="updateVisibility(props.row)"
                />
                <q-chip color="secondary" class="text-white" v-if="!props.row.visibility && props.row.activity_start_date"> Visible: {{ props.row.activity_start_date ? date.formatDate(props.row.activity_start_date, 'D MMM YYYY HH:mm') : '' }}</q-chip>
              </q-card-section>
              <q-card-actions>
                <q-btn v-if="course.course_type === 'template'" class="q-ma-sm" rounded color="primary" @click="edit_activity(props.row)" size="sm">Manage Activity</q-btn>
                <q-btn v-if="course.course_type !== 'template'" class="q-ma-sm" rounded color="primary" @click="edit_activity(props.row)" size="sm">Grade &amp; Manage</q-btn>
                <q-btn v-if="course.course_type !== 'template'" class="q-ma-sm" rounded color="accent" :to="`/student/course/${props.row.course_uid}/activity/${props.row.uid}`" size="sm">Student View</q-btn>
                <q-btn v-if="course.course_type !== 'template' && props.row.activity_type==='Simulation'" class="q-ma-sm" rounded :to="`/sim/course/${props.row.course_uid}/activity/${props.row.uid}`" size="sm" icon="tune">Simulation Control</q-btn>
              </q-card-actions>
            </q-card>
          </template>
        </q-table>
</template>

<script>
import TemplateActivitySelector from "@/components/TemplateActivitySelector.vue";
import {useUserStore} from "@/stores/user";
import {useRegionStore} from "@/stores/region";
import {date, useQuasar} from 'quasar'
import {storeToRefs} from "pinia";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

export default {
  name: "Activites",
  props: ['course_uid', 'course'],
  setup(props){
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const router = useRouter();
    const { user } = storeToRefs(userStore)
    const { org, activity } = storeToRefs(regionStore)
    const activities = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'activity_name',
        descending: false,
    })

    const visibleColumns = ref(['activity_name', 'patients', 'activity_type', 'actions'])

    const $q = useQuasar()

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(p) {
        const { page, rowsPerPage, sortBy, descending } = p.pagination
        loading.value = true

        if(props.course.course_type === 'template'){
          regionStore.GetTemplateActivities(
              page,
              rowsPerPage,
              sortBy,
              descending,
              filter.value,
              null,
              props.course_uid,
          ).then(data => {
            if (typeof data === 'string') {
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            activities.value.splice(0, activities.value.length, ...data.activities);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
          });
        }
        else {
          regionStore.GetActivities(
              props.course_uid,
              page,
              rowsPerPage,
              sortBy,
              descending,
              filter.value).then(data => {
            if (typeof data === 'string') {
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            activities.value.splice(0, activities.value.length, ...data.activities);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending
            visibleColumns.value.push('due_date', 'visibility')
            loading.value = false
          });
        }
    }

    const can_edit= computed(() => {
      if(props.course.course_type !== 'template') return true;

      if(props.course.user_embed && props.course.user_embed.uid === user.value.uid){
        return true
      }
      if(props.course.organization_embed && userStore.check_roles(['Organization Admin', 'Organization Content Admin'], props.course.organization_embed.uid)){
        return true
      }
      if(props.course.program_embed && userStore.check_roles(['Program Admin', 'Program Content Admin'], org.value.uid, props.course.program_embed.uid)){
        return true
      }
      if(props.course.organization_draft_embed && userStore.check_roles(['Organization Admin', 'Organization Content Admin'], props.course.organization_draft_embed.uid)){
        return true
      }
      if(props.course.program_draft_embed && userStore.check_roles(['Program Admin', 'Program Content Admin'], org.value.uid, props.course.program_draft_embed.uid)){
        return true
      }
      if((props.course.system_course===true || props.course.system_draft_course===true) && userStore.check_roles(['Superuser', 'Content Manager']) ) return true;
      return false;
    });
 
    
    return {
      columns: [
        {
          name: 'activity_name',
          label: 'Activity Name',
          field: 'activity_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'patients',
          label: 'Patients',
          field: 'template_patients',
          align: 'left',
          sortable: false,
        },
        {
          name: 'due_date',
          label: 'Due Date',
          field: 'activity_end_date',
          align: 'left',
          sortable: true,
          format: (val) => {
            return date.formatDate(val, 'D MMM YYYY HH:mm')
          }
        },
        {
          name: 'visibility',
          label: 'Visibility',
          field: 'visibility',
          align: 'left',
          sortable: true,
        },
        {
          name: 'activity_type',
          label: 'Activity Experience',
          field: 'activity_type',
          align: 'left',
          format: (val) => {
            if(val === 'Clinical') return 'New Patients'
            if(val === 'Simulation') return 'Simulation'
            return 'Template Patients'
          },
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      can_edit,
      visibleColumns,
      org,
      activities,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      onRequest,
      date,
      add_activity() {
          $q.dialog({
            component: TemplateActivitySelector,

            // props forwarded to your custom component
            componentProps: {

              // ...more..props...
            }
          }).onOk(({activity: new_activity}) => {
            loading.value = true;
            new_activity.course_uid = props.course_uid;
            new_activity.organization_uid = org.value.uid;
            new_activity.uid = null;
            new_activity.visibility = true;
            new_activity.activity_mode = props.course.course_type;
            regionStore.UpdateActivity(new_activity, 'Activity added to Course').then((res) => {
              activities.value.push(res);
              activity.value = res;
              loading.value = false;
              router.push(`/course/${props.course_uid}/activity/${res.uid}`)
            });

          }).onCancel(() => {
            console.log('Cancel')
          }).onDismiss(() => {
            console.log('Called on OK or Cancel')
          })
      }
    }
  },
  methods:{
    new_activity(){
      if(this.course.course_type === 'template') {
        this.$router.push(`/new_activity?course_uid=${this.course_uid}&template=true`)
      }
      else{
        this.$router.push(`/new_activity?course_uid=${this.course_uid}`)
      }
    },

    edit_activity(activity){
      this.$router.push(`/course/${this.course_uid}/activity/${activity.uid}`)
    },
    updateVisibility(row){
      this.regionStore.UpdateActivity(row)
    }
  }
}
</script>

<style scoped>

</style>
