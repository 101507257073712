<template>
  <q-avatar :size="size" :color="color" >
      <img v-if="user.picture" :src="user.picture" />
      <span v-else :class="'text-body2 ' + text_color ? text_color : 'text-white'">{{ user.initials }}</span>
      <q-tooltip class="bg-secondary text-white">
        {{ user.username }}
      </q-tooltip>
  </q-avatar>
</template>

<script>
export default {
  props: ['user', 'color', 'size', 'text_color'],
  name: "UserIcon"
}
</script>

<style scoped>

</style>
