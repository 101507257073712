import {computed, nextTick, onMounted, ref, watchEffect, watch, reactive, toRaw, inject} from "vue";
import {acceptHMRUpdate, storeToRefs} from "pinia";
import {datetime_to_epoch_offset} from "../util";
import {generate} from "xksuid";
import NumericField from "../components/flowsheets/NumericField.vue";
import MultilineText from "../components/flowsheets/MultilineText.vue";
import SelectField from "../components/flowsheets/SelectField.vue";
import PatientDateTimeField from "../components/PatientDateTimeField.vue";
import TimeField from "../components/flowsheets/TimeField.vue";
import TextField from "../components/flowsheets/TextField.vue";
import LookupField from "../components/flowsheets/LookupField.vue";
import {usePatientStore} from "../stores/patient";
import {useUserStore} from "../stores/user";
import {useQuasar} from "quasar";
import AdvancedDialog from "../components/flowsheets/AdvancedDialog.vue";
import FlowsheetDialog from "../components/flowsheets/FlowsheetDialog.vue";
import LookupSearch from "../components/LookupSearch.vue";
import FrequencyField from "../components/flowsheets/FrequencyField.vue";
import ScheduleField from "../components/flowsheets/ScheduleField.vue";
import BarcodeField from "../components/flowsheets/BarcodeField.vue";
import FlowsheetField from "../components/flowsheets/FlowsheetField.vue";
import FileField from "../components/flowsheets/FileField.vue";
import {isEqual} from "lodash";

export function usePatientData(props){
    const old_timestamp = ref(null);
    const u_error = ref(false);
    const newcolumn = ref(null);
    const advanced_obj = ref(null);
    const advanced_element = ref(null);
    const new_ele = ref(null);
    const new_mode = ref(props.new_mode === true ? true : false);
    const edit_mode = ref(null);
    const userStore = useUserStore();
    const patientStore = usePatientStore();
    const { viewing_visit, patient } = storeToRefs(patientStore);
    const confirm_delete = ref(false);
    const delete_holder = ref(null);
    const original_data = ref(null);
    const versioned = inject('versioned')

    const $q = useQuasar()

    onMounted(() => {
      if (props.new_mode) new_entry();
      console.log('Original Data Set', props.data);
      original_data.value = JSON.parse(JSON.stringify(props.data));
      console.log('Original Data Reset', original_data.value);
    });

    const readonly = computed(() => {
        console.log('readonly computed: ', props.readonly, patient.value.patient_type === 'preview', patient.value.readonly)
        if(props.readonly === true) return true;
        if(patient.value.patient_type === 'preview') return true;
        return patient.value.readonly
    });

    watchEffect(() => {
       console.log('watchEffect');
       if(!edit_mode.value && !newcolumn.value) return;
       let d = edit_mode.value ? edit_mode.value.timestamp : newcolumn.value.timestamp;

       for (let i = 0; i < props.data.times.length; i++) {
            if (props.data.times[i].timestamp === d && props.data.times[i] !== (edit_mode.value ? edit_mode.value : newcolumn.value)) {
                u_error.value = true;
                return;
            }
        }
        u_error.value = false;
    });

    function new_entry() {
      let timestamp = 0
      if(!['template', 'scene'].includes(patientStore.patient.patient_type)){
        timestamp = datetime_to_epoch_offset(patientStore.patient, patientStore.patient_time(new Date()));
      }
      const col = {
        user_embed: userStore.user,
        timestamp: timestamp,
      }
      for(let field of props.flowsheet.fields) {
        col[field.uid] = null;
      }
      newcolumn.value = col;
      new_mode.value = true;
      nextTick(() => {
        new_ele.value.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
      })
      if(props.editing===true) props.data['times'].push(newcolumn.value);
    }

    function save() {
      console.log('save');
      return nextTick(() => {
          if (u_error.value) return;
          new_mode.value = false;
          edit_mode.value = null;
          old_timestamp.value = null;

          if (newcolumn.value) {
              props.data.times.push(newcolumn.value);
              newcolumn.value = null;
          }
          original_data.value.times = original_data.value.times.sort((a, b) => {
                return a.timestamp - b.timestamp
          });
          props.data.times = props.data.times.sort((a, b) => {
              return a.timestamp - b.timestamp
          });
          let add_change_delete = {
              addchange: {timestamp: {}},
              delete: {timestamp: {}},
              patient_id: patient.value.uid,
              flowsheet_uid: props.flowsheet.uid,
              version: props.flowsheet.version
          };
          if (viewing_visit.value) add_change_delete.visit_uid = viewing_visit.value.uid;

          for (let p in props.data) {
              if (p === 'times') {
                  let prop_index = 0;
                  let original_index = 0;
                  while (prop_index < props.data['times'].length || original_index < original_data.value['times'].length) {
                      let timestamp_data = props.data['times'][prop_index] ? props.data['times'][prop_index].timestamp : null;
                      let timestamp_original = original_data.value['times'][original_index] ? original_data.value['times'][original_index].timestamp : null;
                      if (timestamp_data === timestamp_original) {
                          for (let f in original_data.value[p][original_index]) {
                              if (props.data['times'][prop_index][f] === undefined || (props.data['times'][prop_index][f] === null && original_data.value['times'][original_index][f] !== null)) {
                                  console.log('delete detected inside timestamp');
                                  console.log(f)
                                  if (!add_change_delete.delete['timestamp'][timestamp_data]) add_change_delete.delete['timestamp'][timestamp_data] = {
                                              timestamp: timestamp_data,
                                              user_embed: props.data['times'][prop_index].user_embed
                                          };
                                  add_change_delete.delete['timestamp'][timestamp_data][f] = original_data.value[p][original_index][f];
                              }
                          }
                          for (let f in props.data['times'][prop_index]) {
                              if (props.data['times'][prop_index][f] && props.data['times'][prop_index][f]['action']) {
                                  for (let field_id in original_data.value['times'][original_index][f]) {
                                      if (props.data['times'][prop_index][f][field_id] === undefined) {
                                          if (field_id === 'action') continue;
                                          if (!add_change_delete.delete['timestamp'][timestamp_data]) add_change_delete.delete['timestamp'][timestamp_data] = {
                                              timestamp: timestamp_data,
                                              user_embed: props.data['times'][prop_index].user_embed
                                          };
                                          if (!add_change_delete.delete['timestamp'][timestamp_data][f]) add_change_delete.delete['timestamp'][timestamp_data][f] = {action: true};
                                          add_change_delete.delete['timestamp'][timestamp_data][f][field_id] = original_data.value[p][original_index][f][field_id];
                                      }
                                  }
                                  for (let field_id in props.data['times'][prop_index][f]) {
                                      if (field_id === 'action') continue;
                                      if (!original_data.value['times'][original_index][f] ||
                                          !isEqual(props.data['times'][prop_index][f][field_id], original_data.value['times'][original_index][f][field_id])) {
                                          if (!add_change_delete.addchange['timestamp'][timestamp_data]) add_change_delete.addchange['timestamp'][timestamp_data] = {
                                              timestamp: timestamp_data,
                                              user_embed: props.data['times'][prop_index].user_embed
                                          };
                                          add_change_delete.addchange['timestamp'][timestamp_data][f] = props.data['times'][prop_index][f];
                                      }
                                  }
                              } else if (!original_data.value['times'][original_index].read_only && !isEqual(props.data['times'][prop_index][f], original_data.value['times'][original_index][f]) && props.data['times'][prop_index][f] !== null) {
                                  console.log('change detected');
                                  if (!add_change_delete.addchange['timestamp'][timestamp_data]) add_change_delete.addchange['timestamp'][timestamp_data] = {
                                      timestamp: timestamp_data,
                                      user_embed: props.data['times'][prop_index].user_embed
                                  };
                                  add_change_delete.addchange['timestamp'][timestamp_data][f] = props.data['times'][prop_index][f];
                              }
                          }

                          prop_index++;
                          original_index++;
                      } else if (timestamp_data !== null && (timestamp_data < timestamp_original || timestamp_original === null)) {
                          for (let f in props.data['times'][prop_index]) {
                              if (props.data['times'][prop_index][f] !== null) {
                                  console.log('add detected');
                                  if (!add_change_delete.addchange['timestamp'][timestamp_data]) add_change_delete.addchange['timestamp'][timestamp_data] = {
                                      timestamp: timestamp_data,
                                      user_embed: props.data['times'][prop_index].user_embed
                                  };
                                  add_change_delete.addchange['timestamp'][timestamp_data][f] = props.data['times'][prop_index][f];
                              }
                          }
                          prop_index++;
                      } else if ((timestamp_data > timestamp_original || (timestamp_data === null && timestamp_original !== null)) && original_data.value['times'][original_index]) {
                          console.log('delete detected');
                          add_change_delete.delete['timestamp'][timestamp_original] = original_data.value['times'][original_index];
                          original_index++;
                      }
                  }
                  //Do the time comparison here
              } else {
                  for (let field_id in props.data[p]) {
                      if (original_data.value[p] === undefined || original_data.value[p][field_id] === undefined) {
                          console.log(`Adding ${p} to add_change_delete`);
                          if (!add_change_delete.addchange[p]) add_change_delete.addchange[p] = {};
                          add_change_delete.addchange[p][field_id] = props.data[p][field_id];
                      } else if (!isEqual(original_data.value[p][field_id], props.data[p][field_id])) {
                          if (!add_change_delete.addchange[p]) add_change_delete.addchange[p] = {};
                          add_change_delete.addchange[p][field_id] = props.data[p][field_id];
                          console.log(`Change ${p} - ${field_id}`);
                          console.log(original_data.value[p][field_id]);
                          console.log(props.data[p][field_id]);
                      }
                  }
              }
          }
          for (let p in original_data.value) {
              if (p !== 'times') {
                  for (let field_id in original_data.value[p]) {
                      if (props.data[p] === undefined || props.data[p][field_id] === undefined) {
                          console.log(`delete ${p} - ${field_id}`);
                          if (!add_change_delete.delete[p] && p !== 'times') add_change_delete.delete[p] = {};
                          add_change_delete.delete[p === 'times' ? 'timestamp' : p][field_id] = original_data.value[p][field_id];
                      }
                  }
              }
          }
          return patientStore.SaveData(add_change_delete).then(() => {
              original_data.value = JSON.parse(JSON.stringify(props.data));
              console.log('Saved Original Data Reset');
          });
      });
    }

    function edit(column) {
      old_timestamp.value = column.timestamp;
      edit_mode.value = column;
    }

    function delete_data(d){
      confirm_delete.value = true;
      delete_holder.value = d;
    }

    function delete_confirmed(){
      confirm_delete.value = false;
      props.data.times.splice(props.data.times.indexOf(delete_holder.value), 1);
      save()
    }

    function open_advanced(element, obj, field_id, ok, show_timestamp) {
      let column = null;
      if(newcolumn.value) column = newcolumn.value;
      if(edit_mode.value !== null) column = edit_mode.value;
      advanced_obj.value = obj;
      $q.dialog({
        component: AdvancedDialog,

        // props forwarded to your custom component
        componentProps: {
            flowsheet: props.flowsheet,
            patient: patient.value,
            show_timestamp: show_timestamp,
            timestamp: column.timestamp,
            data_items: [{
                active_field: element,
                data: advanced_obj.value,
                field_id: field_id,
            }],
          // ...more..props...
        }
      }).onOk(({timestamp}) => {
          advanced_obj.value.timestamp = timestamp;
          advanced_obj.value.user_embed = column.user_embed;
          let advanced_key = element.uid;
          if(element.data_type === 'Lookup Advanced') advanced_key = obj.lookup.lookup_type;
          if(!props.data[advanced_key]) {
            props.data[advanced_key] = {};
          }
          props.data[advanced_key][field_id] = advanced_obj.value;
          if(ok) ok();
      }).onCancel(() => {
        console.log('Cancel')
      }).onDismiss(() => {
        console.log('Called on OK or Cancel')
      })
    }

    function start_advanced_add(element, ok, show_timestamp) {
      let obj = reactive({});

      advanced_element.value = element;

      if(element.data_type === 'Lookup Advanced' || element.data_type === 'Lookup') {
          $q.dialog({
              component: LookupSearch,
              componentProps: {
                field: element,
                flowsheet: props.flowsheet,
              }
          }).onOk((lookup) => {
              console.log(lookup)
              obj['lookup'] = lookup;
              const field_id = generate();
              for(let field of props.flowsheet.lookup_data[lookup.lookup_type].fields) {
                obj[field.uid] = reactive(null);
              }
              open_advanced(props.flowsheet.lookup_data[lookup.lookup_type], obj, field_id, ok, show_timestamp);
          }).onCancel(() => {

          }).onDismiss(() => {

          })
      }
      else{
          for(let field of element.fields) {
            obj[field.uid] = null;
          }
          const field_id = generate();
          open_advanced(element, obj, field_id, ok, show_timestamp);
      }
    }

    function delete_advanced(element, field_id, obj){
        delete props.data[element.uid][field_id];
    }

    function delete_action(data, element, field_id, action_uid, obj){
        let advanced_key = element.uid;
        if(element.data_type === 'Lookup Advanced') advanced_key = obj.lookup.lookup_type;
        delete data[advanced_key][field_id];
    }

    let action_dialog = null;
    let data_items = ref([]);
    function select_action(data, element, field_id, action, obj, readonly, options)
    {
      const ok = options && options.ok ? options.ok : null;
      const show_action = options && options.show_action ? options.show_action : false;
      const show_timestamp = options && options.show_timestamp ? options.show_timestamp : false;
      let advanced_key = element.uid;
      if(element.data_type === 'Lookup Advanced') advanced_key = obj.lookup.lookup_type;
      if(readonly === null || readonly === undefined) readonly = false;
      if(action.fields.length){
          advanced_obj.value = null;
          let key = data[advanced_key];
          if(key){
              let field = key[field_id];
              if(field){
                  let action_key = field[action.uid];
                  if(action_key){
                      advanced_obj.value = data[advanced_key][field_id][action.uid];
                  }
              }
          }
          if(!advanced_obj.value){
            advanced_obj.value = {};
            for(let field of action.fields) {
                advanced_obj.value[field.uid] = null;
            }
          }
          data_items.value.push(reactive({
                active_field: action,
                data: advanced_obj.value,
                parent: obj.lookup && obj.lookup.lookup_type ? props.flowsheet.lookup_data[obj.lookup.lookup_type] : null,
                parent_data: obj,
                advanced_key: advanced_key,
                field_id: field_id,
            }))
          console.log(action_dialog);
          if(action_dialog)
          {
              return
          }

          action_dialog = $q.dialog({
            component: AdvancedDialog,

            // props forwarded to your custom component
            componentProps: {
                data_items: data_items.value,
                patient: patient.value,
                readonly: readonly,
                flowsheet: props.flowsheet,
                show_action: show_action,
                show_timestamp: show_timestamp,
                timestamp: data.timestamp,
                user_embed: data.user_embed,
              // ...more..props...
            }
          }).onOk(({timestamp}) => {
              action_dialog = null;
              data.timestamp = timestamp;
              console.log(`new_mode: ${new_mode.value}`);
              if(newcolumn.value && !new_mode.value){
                let found = props.data.times.find(f => f.timestamp === timestamp);
                if(found){
                  data = found;
                  edit(found);
                  newcolumn.value = null;
                }
              }

              if(edit_mode.value !== null) edit_mode.value.timestamp = data.timestamp;
              for(let item of data_items.value)
              {
                  if (data[item.advanced_key] === null || data[item.advanced_key] === undefined) {
                      data[item.advanced_key] = {"action": true};
                  }
                  if (!data[item.advanced_key][item.field_id]) {
                      data[item.advanced_key][item.field_id] = {};
                  }
                  data[item.advanced_key][item.field_id][item.active_field.uid] = item.data;

              }
              console.log(data);
              data_items.value = [];



              if(ok) ok();
          }).onCancel(() => {
              action_dialog = null;
              data_items.value = [];
            console.log('Cancel')
          }).onDismiss(() => {
            console.log('Called on OK or Cancel')
          })
      }
      else if(action.flowsheet){
          action_dialog = $q.dialog({
            component: FlowsheetDialog,

            // props forwarded to your custom component
            componentProps: {
                version: versioned.value,
                data_items: data_items.value,
                patient: patient.value,
                readonly: readonly,
                flowsheet_uid: obj.lookup.data[action.flowsheet].uid,
                show_action: show_action,
                show_timestamp: show_timestamp,
                timestamp: data.timestamp,
                user_embed: data.user_embed,
              // ...more..props...
            }
          }).onOk(({timestamp}) => {
              console.log("OK Pressed");
              if(timestamp) data.timestamp = timestamp;
              if(newcolumn.value && !new_mode.value){
                let found = props.data.times.find(f => f.timestamp === timestamp);
                if(found){
                  data = found;
                  edit(found);
                  newcolumn.value = null;
                }
              }
              if(!data[advanced_key]) data[advanced_key] = {"action": true};
              if(!data[advanced_key][field_id]) data[advanced_key][field_id] = {};
              data[advanced_key][field_id][action.uid] = {};
              if(ok) ok();
          }).onCancel(() => {
              action_dialog = null;
              data_items.value = [];
            console.log('Cancel')
          }).onDismiss(() => {
            console.log('Called on OK or Cancel')
          })
      }
      else if(!readonly){
          if(newcolumn.value && !new_mode.value){
            let found = props.data.times.find(f => f.timestamp === data.timestamp);
            if(found){
              data = found;
              edit(found);
              newcolumn.value = null;
            }
          }
          if(!data[advanced_key]) data[advanced_key] = {"action": true};
          if(!data[advanced_key][field_id]) data[advanced_key][field_id] = {};
          data[advanced_key][field_id][action.uid] = {};
          if(ok) ok();
      }
    }

    function can_edit(user_uid){
        if(userStore.check_roles(['Student'], patient.value.organization_uid, null, null, patient.value.course_uid) && user_uid !== userStore.user.uid) return false;
        return true;
    }

    function get_fields(element, obj) {
        if(element.data_type === 'Lookup Advanced'){
            return props.flowsheet.lookup_data[obj.lookup.lookup_type].fields;
        }
        else{
            return element.fields;
        }
    }

    function get_actions(element, obj) {
        if(element.data_type === 'Lookup Advanced'){
            return props.flowsheet.lookup_data[obj.lookup.lookup_type].actions;
        }
        else{
            return element.actions;
        }
    }

    function get_entries(element){
        if(element.data_type === 'Lookup Advanced'){
            return element.lookup_type;
        }
        else{
            return [element.uid];
        }
    }

    function get_action(element, obj, action_uid) {
        if(element.data_type === 'Lookup Advanced'){
            return props.flowsheet.lookup_data[obj.lookup.lookup_type].actions.find(action => action.uid === action_uid);
        }
        else{
            return element.actions.find(action => action.uid === action_uid);
        }
    }

    function is_unique(d){
        for (let i = 0; i < props.data.times.length; i++) {
            if (props.data.times[i].timestamp === d.timestamp && props.data.times[i] !== d) {
                return true;
            }
        }
        return false;
    }

    function unique_timestamp(times, d) {
        function unique_timestamp_rule(val) {
            for (let i = 0; i < times.length; i++) {
                if (times[i].timestamp === d.timestamp && times[i] !== d) {
                    return 'Date Time entries must be unique';
                }
             }
            return true;
         }
        return unique_timestamp_rule;
     }

    return {
        reset_data(data){
          original_data.value = JSON.parse(JSON.stringify(data));
        },
        patient,
        newcolumn,
        advanced_obj,
        advanced_element,
        new_ele,
        new_mode,
        edit_mode,
        readonly,
        userStore,
        patientStore,
        viewing_visit,
        new_entry,
        save,
        edit,
        start_advanced_add,
        select_action,
        lookup,
        can_edit,
        get_fields,
        get_actions,
        get_action,
        u_error,
        delete_data,
        confirm_delete,
        delete_holder,
        delete_confirmed,
        open_advanced,
        delete_advanced,
        delete_action,
        get_entries,
    }
}

export function lookup(component) {
      switch (component) {
        case "Number":
          return NumericField;
        case "Multiline Text":
          return MultilineText;
        case "List Select":
          return SelectField;
        case "Date/Time":
          return PatientDateTimeField;
        case "Time":
          return TimeField;
        case "Lookup":
          return LookupField;
        case "Frequency":
          return FrequencyField;
        case "Schedule":
          return ScheduleField;
        case "Barcode":
          return BarcodeField;
        case "Flowsheet":
          return FlowsheetField;
        case "File":
          return FileField;
        default:
          return TextField
      }
    }



if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePatientData, import.meta.hot))
}
