import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from "axios";
import {useRegionStore} from "./region";
import {intercept, error_intercept} from "./restapi";
import {intersection} from "../util";
import { storeToRefs } from 'pinia'
import { Notify } from 'quasar'

axios.defaults.withCredentials = true

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
api.interceptors.response.use(intercept, error_intercept);

const api_no_intercept = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});


export const useUserStore = defineStore('user', {
  state: () => {
    return { user: null, loggedIn: localStorage.getItem('logged_in')}
  },
  getters: {
    org_for_user: (state) => {
        if(!state.user) return null;
        const regionStore = useRegionStore();
        for(let o of state.user.schools) {
            if(o.organization_uid === regionStore.org.uid) return o;
        }
    },
    default_route(state) {
        if(!state.user) return '/';
        if(intersection(['Superuser', 'Support'], state.user.roles).length) return '/user';
        if(intersection(['Content Manager'], state.user.roles).length) return '/template_patients';
        let org = this.org_for_user;
        if(org){
            if(intersection(['Instructor', 'Organization Admin', 'Organization Content Admin', 'Program Admin', 'Campus Admin', 'Program Content Admin'], org.roles).length) return '/dashboard';
        }
        else if(intersection(['Demo'], state.user.roles).length) return '/demo';
        return '/student'
    },
    manage_content: (state) => {
        if(!state.user) return false;
        return intersection(['Superuser', 'Content Manager'], state.user.roles).length > 0;
    },

  },
  actions: {
    check_roles(roles, organization_uid, program_uid, campus_uid, course_uid) {
      let has_roles = [];
      if(this.user.roles && this.user.roles.length > 0)
      {
        has_roles.push(...this.user.roles);
      }
      const school = this.user.schools.find(school => school.organization_uid === organization_uid);
      let program = null;
      let course = null;
      let campus = null;
      if(school) {
        program = school.programs.find(program => program.program_uid === program_uid);
        campus = school.campuses.find(campus => campus.campus_uid === campus_uid);
        course = school.courses.find(course => course.uid === course_uid);
        has_roles.push(...intersection(school.roles, ['Organization Admin', 'Organization Content Admin']));
      }
      if(program) {
        has_roles.push(...intersection(program.roles, ['Program Admin', 'Program Content Admin']));
      }
      if(campus) {
          has_roles.push(...intersection(campus.roles, ['Campus Admin']));
      }
      if(course) {
          has_roles.push(...course.roles);
      }
      return intersection(roles, has_roles).length > 0;
    },
    check_license(){
      for(let license of this.user.licenses){
          if(new Date(license.start_date) <= new Date() && new Date(license.end_date) >= new Date()) return true;
      }
      return false;
    },
    programs_for_organization(organization_uid) {
        const school = this.user.schools.find(school => school.organization_uid === organization_uid);
        if(!school) return [];
        return school.programs;
    },
    get_user_program(organization_uid, program_uid) {
        const school = this.user.schools.find(school => school.organization_uid === organization_uid);
        if(!school) return null;
        return school.programs.find(program => program.program_uid === program_uid);
    },
    get_user_campus(organization_uid, campus_uid) {
        const school = this.user.schools.find(school => school.organization_uid === organization_uid);
        if(!school) return null;
        return school.campuses.find(campus => campus.campus_uid === campus_uid);
    },
    available_roles(organization_uid, program_uid, campus_uid, course_uid) {
      const school = this.user.schools.find(school => school.organization_uid === organization_uid);
      let program = null;
      let course = null;
      let campus = null;
      if(school) {
        program = school.programs.find(program => program.program_uid === program_uid);
        campus = school.campuses.find(campus => campus.campus_uid === campus_uid);
        course = school.courses.find(course => course.uid === course_uid);
      }
      let potential_roles = [];
      if(course_uid) {
        potential_roles = ['Student', 'Instructor', 'Adjunct']
        if(intersection(this.user.roles, ['Superuser', 'Support'])) return potential_roles;
        if(school && 'Organization Admin' in school.roles) return potential_roles;
        if(program && 'Program Admin' in program.roles) return potential_roles;
        if(campus && 'Campus Admin' in campus.roles) return potential_roles;
        if(course && 'Instructor' in course.roles) return potential_roles;
      }
      else if(program_uid) {
        potential_roles = ['Student', 'Instructor', 'Adjunct', 'Program Admin', 'Program Content Admin']
        if(intersection(this.user.roles, ['Superuser', 'Support'])) return potential_roles;
        if(school && 'Organization Admin' in school.roles) return potential_roles;
        if(program && 'Program Admin' in program.roles) return potential_roles;
        if(program && 'Program Content Admin' in program.roles) return ['Program Content Admin'];
      }
      else if(campus_uid) {
        potential_roles = ['Student', 'Instructor', 'Adjunct', 'Campus Admin']
        if(intersection(this.user.roles, ['Superuser', 'Support'])) return potential_roles;
        if(school && 'Organization Admin' in school.roles) return potential_roles;
        if(campus && 'Campus Admin' in campus.roles) return potential_roles;
      }
      else if (organization_uid) {
        potential_roles = ['Student', 'Instructor', 'Adjunct', 'Organization Admin', 'Organization Content Admin', 'Billing']
        if(intersection(this.user.roles, ['Superuser', 'Support'])) return potential_roles;
        if(school && 'Organization Admin' in school.roles) return potential_roles;
        if(school && 'Organization Content Admin' in school.roles) return ['Organization Content Admin'];
        if(school && 'Billing' in school.roles) return ['Billing'];
      }
      else {
        if(intersection(this.user.roles, ['Superuser', 'Support'])){
          return ['Superuser', 'Content Manager', 'Support'];
        }
        if('Content Manager' in this.user.roles){
          return ['Content Manager'];
        }
      }

      return null;
    },
    set_session(session){
        this.user = session.user;
        this.loggedIn = true;
        localStorage.setItem('logged_in', true);
        if(session.organization){
            const regionStore = useRegionStore();
            regionStore.org = session.organization;
        }
    },
    async get_checkout_session(cart){
        const response= await api.post('/user/checkout_session', {cart: cart});
        if(response.status === 200) {
            return response.data;
        }
    },
    async get_session_status(session_id){
        const response= await api.get(`/user/session_status/${session_id}`);
        if(response.status === 200) {
            return response.data;
        }
    },
    async CheckUser(email, course_code, demo) {
        try {
            const response= await api_no_intercept.post('/login_check', {email: email, demo: demo, course_code: course_code});
            if (response.status === 200) {
                return true;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async VerifyUser(email, verification_code) {
        try {
            const response = await api_no_intercept.post('/verify_email', {email: email, verification_code: verification_code});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async GetOrganizations(page, rowsPerPage, sortBy, descending, filter, show_orgs_only) {
        const response = await api.get('/get_organizations', {params:
                {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, show_orgs_only: show_orgs_only}});
        if (response.status === 200) {
            return response.data;
        }
    },
    async GetUsers(organization_uid, campus_uid, program_uid, course_uid, page, rowsPerPage, sortBy, descending, filter, students) {
        const response = await api.get('/users', {params:
                {organization_uid: organization_uid, campus_uid: campus_uid, program_uid: program_uid,
                    course_uid: course_uid, query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy,
                    descending: descending, students: students}});
        if (response.status === 200) {
            return response.data;
        }
    },
    async UpdateUser(user, new_demo){
        if(user.uid === this.user.uid) {
            this.user = user;
        }
        try {
            const response = await api.post('/update_user', {user: user, new_demo: new_demo ? new_demo: null});
            if (response.status === 200) {
                if (user.uid == this.user.uid) {
                    this.user = response.data.user;
                    if(response.data.organization){
                        const regionStore = useRegionStore();
                        regionStore.org = response.data.organization;
                    }
                }
                Notify.create({
                  color: 'positive',
                  message: `User ${response.data.user.username} updated successfully`,
                  icon: 'save'
                })

                return response.data.user;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async ContactUs(new_demo){
        try {
            const response = await api.post('/contact_us', {new_demo: new_demo ? new_demo: null});
            if (response.status === 200) {
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async DeleteUser(user){
        const response = await api.delete(`/user/${user.uid}`);
    },
    async Login(email, password) {
        try {
            const response = await api_no_intercept.post('/login', {email: email, password: password});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
            localStorage.removeItem('logged_in');
        }
    },
    async BecomeUser(uid) {
        try {
            const response = await api_no_intercept.post(`/become_user/${uid}`);
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async GetUploadUrl(file_name, file_type) {
        try {
            const response = await api.post('/get_upload_url', {file_name: file_name, file_type: file_type});
            if (response.status === 200) {
                return response.data;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async Logout() {
        try {
            this.user = null;
            const response = await api_no_intercept.post('/logout');
            if (response.status === 200) {
                if(response.data.session) {
                    this.set_session(response.data.session);
                }
                else {
                    localStorage.removeItem('logged_in');
                    const regionStore = useRegionStore();
                    regionStore.org = null;
                    this.user = null;
                    this.loggedIn = false;
                }

            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
            localStorage.removeItem('logged_in');
            this.loggedIn = false;
        }
    },
    async CreateOTP(email, redirect_to) {
        try {
            const response = await api_no_intercept.post('/create_otp', {email: email, redirect_to: redirect_to});
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
        }
    },
    async VerifyOTP(email, otp) {
        try {
            const response = await api_no_intercept.post('/verify_otp', {email: email, otp: otp});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
        }
    },
    async getUserSession() {
        try {
            const regionStore = useRegionStore();
            regionStore.session_promise = regionStore.session();
            const r = await regionStore.session_promise;
            localStorage.setItem('logged_in', true)
            this.loggedIn = true;
            this.user = r.user;
            if(this.user) return true;
        } catch (error) {
            this.loggedIn = false;
            this.user = null;
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
            throw error;
        }
    },
    async UpdateSession(org_id){
        const response = await api.post('/user_session', {organization_uid: org_id});
    },
    async getUser(uid) {
        try {
            const response = await api.get(`/user/${uid}`);
            if (response.status === 200) {
                return response.data.user;
            }
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
        }
    },
    async NewPassword(password, confirm_password, user_uid){
        try{
            const response = await api.post('/new_password', {password: password, user_uid: user_uid});
            if(response.status === 200) {
                Notify.create({
                  color: 'positive',
                  message: `User ${this.user.username} password updated successfully`,
                  icon: 'save'
                })
                return true;

            }
        }
        catch(error){
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async AddCourseCode(course_code){
        try{
            const response = await api.post('/add_course_code', {course_code: course_code});
            if(response.status === 200) {
                this.user = response.data.user;
                Notify.create({
                  color: 'positive',
                  message: `User ${this.user.username} successfully added to course`,
                  icon: 'save'
                })

                return response.data.user;
            }
        }
        catch(error){
            Notify.create({
              color: 'negative',
              message: `Course not found for code ${course_code}`,
              icon: 'error'
            })
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    },
    async ApplyPaymentCode(payment_code){
        try {
            const response = await api.post('/apply_payment_code', {payment_code: payment_code});
            this.user = response.data.user;
            return true;
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
            throw error;
        }
    },
    async GetUserData(url){
        const response = await api.get('handle_user_data', {params: {url: url}});
        if(response.status === 200) {
            return response.data;
        }
    },
    async AddEmail(user, email){
      const response = await api.post('/add_email', {uid: user.uid, email: email});
      if(response.status === 200) {
          return response.data;
      }
    },
    async DeleteEmail(user, email){
        const response = await api.post('/delete_email', {uid: user.uid, system:'email', system_key: email});
        if(response.status === 200) {
            return response.data;
        }
    }

  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
