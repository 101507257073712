<template>
  <div>
    <q-card class="text-center q-pa-lg">
      <h4 class="text-primary">Student Payment</h4>
      <div class="row q-col-gutter-md">
          <q-select
            class="col"
            v-if="org.billing_level === 'Program' && !has_program"
            use-input
            label="Program"
            outlined
            v-model="program"
            :options="programs"
            @filter="fetchPrograms"
            option-label="program_name"
            option-value="uid"
          ></q-select>
          <q-select
            class="col"
            v-if="org.billing_level === 'Campus' && !has_campus"
            use-input
            label="Campus"
            outlined
            v-model="campus"
            :options="campuses"
            @filter="fetchCampuses"
            option-label="campus_name"
            option-value="uid"
          ></q-select>

        <div :class="['col-xs-12', policy && (policy.bookstore === true || policy.codes===true) ? 'col-md-6' : 'col-md-12']" v-if="policy && policy.stripe === true">
          <h5 class="text-primary">Online Payment</h5>

          <template v-for="price in license_prices">
            <q-btn
                class="q-ma-md"
                v-if="price.default_price && policy.student_pay_prices.includes(price.default_price.id)"
                color="accent"
                :to="'/stripe_payment?price_id=' + price.default_price.id"
            >
              Pay ${{ display_price(price.default_price.unit_amount) }} for {{ price.name }}
            </q-btn>
          </template>
        </div>
        <div :class="['col-xs-12', policy && policy.stripe === true ? 'col-md-6' : 'col-md-12']" v-if="policy && (policy.bookstore === true || policy.codes===true)">
          <q-form @submit="apply_payment_code">
            <h5 class="text-primary">Bookstore / School Payment Code</h5>
            <q-input
                label="Enter Code"
                outlined
                mask="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                hint="0-9 and a-f"
                :rules="[ val => regex.test(val) || `Payment Code must be digits and the letters a-f`]"
                :error-message="code_error" :error="code_error?true:false"
                v-model="payment_code"
            ></q-input>
            <q-btn type="submit" color="secondary" class="q-ma-md">Submit Payment Code</q-btn>
          </q-form>
        </div>
      </div>
    <q-inner-loading
        :showing="loading"
        label="Please wait..."
        label-class="text-secondary"
        label-style="font-size: 1.1em"
    />

    </q-card>
    <div class="text-center q-pa-lg">
      <p class="text-primary">
        <span class="text-primary text-h4">This page is for Students Only</span><br>
        If you are an Instructor or Adjunct please contact your Program’s Administrator to have an account created.
      </p>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, computed, watch} from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore } from "../stores/region";
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

export default {
  name: "StudentPayment",
  setup(){
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const router = useRouter();
    const { org } = storeToRefs(regionStore);
    const { user } = storeToRefs(userStore);
    const payment_code = ref('');
    const loading = ref(false);
    const code_error = ref(null);
    const license_products = ref([]);
    const add_on_products = ref([]);
    const license_prices = ref([]);
    const policy = ref(null);
    const programs = ref([]);
    const campuses = ref([]);
    const program = ref(null);
    const campus = ref(null);

    function get_product_prices(){
      loading.value = true;
      regionStore.get_product_prices_for_user().then((data) => {
        license_prices.value = data.prices;
        policy.value = data.policy;
        loading.value = false;
      });
    }

    onMounted(() => {
      if (org.value.billing_level === 'Organization' || (org.value.billing_level === 'Program' && has_program.value) || (org.value.billing_level === 'Campus' && has_campus.value)) {
        get_product_prices();
      }
    });

    function fetchPrograms(val, doneFN, abortFN) {
      regionStore.GetPrograms(1, 50, null, false, val).then((data) => {
        programs.value = data;
        doneFN();
      });
    }

    function fetchCampuses(val, doneFN, abortFN) {
      regionStore.GetCampuses(1, 50, null, false, val).then((data) => {
        campuses.value = data;
        doneFN();
      });
    }

    const has_program = computed(() => {
      return user.value.schools.find(school=>school.organization_uid===org.value.uid).programs.length > 0;
    });

    const has_campus = computed(() => {
      return user.value.schools.find(school=>school.organization_uid===org.value.uid).campuses.length > 0;
    });

    watch([program, campus], () => {
      let entity = {};
      if(program.value){
        entity.entity_type = "program";
        entity.uid = program.value.uid;
      }
      if(campus.value){
        entity.entity_type = "campus";
        entity.uid = campus.value.uid;
      }
      loading.value = true;
      regionStore.AddOrInviteUsers([user.value], "Student", entity).then((users) => {
        user.value = users[0];
        get_product_prices();
      });
    });

    return {
      program,
      campus,
      programs,
      campuses,
      has_program,
      has_campus,
      fetchPrograms,
      fetchCampuses,
      policy,
      display_price(price) {
          return price / 100.0;
      },
      license_prices,
      license_products,
      loading,
      org,
      user,
      regex: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
      payment_code,
      code_error,
      apply_payment_code(){
        loading.value = true;
        userStore.ApplyPaymentCode(payment_code.value).then((r) => {
          loading.value = false;
          if(r === true){
            router.push('/student')
          }
          else {
            code_error.value = r;
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
