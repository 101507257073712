<template>
        <q-table
            class="q-pa-md"
            title="Versions"
            style="height: calc(100vh - 120px);"
            :columns="columns" :rows="versions" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            v-model:pagination="pagination"
            @request="onRequest"
            :grid="$q.screen.xs"
            >
          <template v-slot:top-left>
            <div>
              <div class="row text-h5 text-primary q-mb-md">Versions</div>
              <div class="row">
                <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>
          </template>
          <template v-slot:top-right>
            <q-btn color="primary" icon="add" to="/new_version" label="New Version">
            </q-btn>
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td>
              <q-btn class="q-ma-sm" rounded color="primary" :to="`/version/${props.row.uid}`" size="sm">Edit Version</q-btn>
            </q-td>
          </template>
          <template v-slot:loading>
              <q-inner-loading showing color="primary" />
          </template>
        </q-table>
</template>

<script>
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";
import {useFlowsheetStore} from "../stores/flowsheet";
import {storeToRefs} from "pinia";
import {onMounted, ref} from "vue";

export default {
  name: "Versions",
  setup(){
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const flowsheetStore = useFlowsheetStore();
    const { org } = storeToRefs(regionStore)
    const versions = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'version_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        flowsheetStore.GetVersions(
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            versions.value.splice(0, versions.value.length, ...data.versions);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

    return {
      columns: [
        {
          name: 'version_name',
          label: 'Versions Name',
          field: 'version_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      org,
      versions,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      onRequest,
    }
  },
  methods:{
    edit_chart(chart){
      this.$router.push(`/flowsheet_builder/${flowsheet.uid}`);
    },
  }
}
</script>

<style scoped>

</style>
