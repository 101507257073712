<template>
  <div>
    <q-editor :model-value="modelValue" @update:model-value="$emit('update:model-value', $event)" ref="editor"
          :toolbar="toolbar" :disable="disable" :definitions="definitions"
    >

    </q-editor>
    <q-resize-observer @resize="onResize" />
  </div>
</template>

<script>
import {ref, computed} from 'vue'
import { useQuasar } from 'quasar'
import ImageDialog from "./ImageDialog.vue";

export default {
  name: "TCEEditor",
  props: ['modelValue', 'disable'],
  components: {ImageDialog},
  emits: ['update:model-value'],
  setup(){
    const editor = ref(null);
    const width = ref(0);
    const $q = useQuasar();
    const toolbar = computed(() => {
      const bar = [[
          {
            label: $q.lang.editor.align,
            icon: $q.iconSet.editor.align,
            fixedLabel: true,
            list: 'only-icons',
            options: ['left', 'center', 'right', 'justify']
          },
      ]];
      if(width.value < 400)
      {
        bar.push(['bold', 'link', 'unordered', 'viewsource'])
      }
      else if (width.value < 600)
      {
        bar.push(['bold', 'italic', 'link', 'unordered', 'viewsource', 'removeFormat'])
      }
      else{
        bar.push(
          ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
          ['token', 'hr', 'link', 'image'],
          ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
          ['undo', 'redo'],
          ['viewsource', 'removeFormat']
        )
      }
      return bar;
    });

    function add_image(evt, ed, caret){
      $q.dialog({
        component: ImageDialog,
        parent: this,
        persistent: true
      }).onOk((data) => {
        console.log('Image Added', data);
        //editor.value.runCmd('insertImage', data.image);
        editor.value.runCmd('insertHTML', `<img src="${data.image}" style="max-width: 100%; height: auto; max-height: 80vh"/>`);
      });
    }

    const definitions = {
      image:
        {
          tip: 'Add an Image',
          icon: 'image',
          handler: add_image
        }
    }

    return {
      editor, toolbar, width, definitions

      /**
       * Capture the <CTL-V> paste event, only allow plain-text, no images.
       * See: https://stackoverflow.com/a/28213320
       */
      /*onPaste (evt) {
        // Let inputs do their thing, so we don't break pasting of links.
        if (evt.target.nodeName === 'INPUT') return
        let text, onPasteStripFormattingIEPaste
        evt.preventDefault()
        evt.stopPropagation()
        if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
          text = evt.originalEvent.clipboardData.getData('text/plain')
          editor.value.runCmd('insertText', text)
        }
        else if (evt.clipboardData && evt.clipboardData.getData) {
          text = evt.clipboardData.getData('text/plain')
          editor.value.runCmd('insertText', text)
        }
        else if (window.clipboardData && window.clipboardData.getData) {
          if (!onPasteStripFormattingIEPaste) {
            onPasteStripFormattingIEPaste = true
            editor.value.runCmd('ms-pasteTextOnly', text)
          }
          onPasteStripFormattingIEPaste = false
        }
      }*/
    }
  },
  methods: {
    focus(){
      this.editor.focus();
      console.log(this.editor);
    },
    onResize (size) {
      this.width = size.width
    }
  }
}
</script>

<style scoped>

</style>