<template>
    <teleport to="#toolbar_container" v-if="activity && activity.current_sim && ['running', 'paused'].includes(activity.current_sim.status)">
    <div class="col-grow bg-black text-amber rounded-borders q-pa-xs text-center row">
      <div v-if="activity.current_sim && activity.current_sim.status==='paused'" class="col text-h3">
        <q-icon name="pause"></q-icon>
      </div>
      <div class="col-grow">
        <span class="text-amber">{{ date.formatDate(sim_time(now), 'DD MMM YYYY') }}<br></span>
        <span class="text-h4">{{ date.formatDate(sim_time(now), 'HH:mm:ss') }}</span>
      </div>
      <div v-if="activity.current_sim && activity.current_sim.status==='paused'" class="col text-h3">
        <q-icon name="pause"></q-icon>
      </div>
    </div>
  </teleport>

  <q-card v-if="activity && course">
    <q-card-section class="text-primary">
      <q-btn v-if="userStore.check_roles(['Instructor'], activity.organization_uid, null, null, activity.course_uid)" class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/course/${course.uid}`"/>
      <q-btn v-else class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/student/course/${activity.course_uid}/activities`"/>
      <div class="text-h5">{{ activity.activity_name }}</div>
      <div><span class="text-bold">Course: </span>{{ course.course_short_name }}-{{ course.course_name }} | <span class="text-bold">Due: </span>{{ date.formatDate(activity.activity_end_date, 'DD MMM YYYY HH:mm' ) }}</div>
    </q-card-section>
    <q-card-section>
      <div class="text-bold text-white bg-primary q-pa-md">Instructions</div>
      <div class="description_box q-pa-md" v-html="activity.student_instructions"></div>
    </q-card-section>
    <q-card-section class="row q-col-gutter-md">
      <div class="col-md-4 col-12" v-for="patient in activity.template_patients">
        <q-card v-if="patient.patient_type==='user_creates'" class="bg-secondary ">
          <q-card-section class="bg-primary text-white" style="border-radius: 6px;">
            <div class="row">
              <div class="col-shrink">
                <q-avatar color="white" class="text-primary"><q-icon name="add"></q-icon></q-avatar>
              </div>
            <div class="col-grow q-ml-md vertical-middle"><span class="text-bold">New Patient: </span>{{ patient.name }}<br>
              <span class="text-bold">Due: </span>{{ date.formatDate(activity.activity_end_date, 'DD MMM YYYY HH:mm' ) }}
            </div>
            <div class="col-shrink text-right q-mt-md q-mt-md-none">
              <q-btn v-if="!past_due" outline color="white" class="float-right"
                 :to="`/student/course/${course.uid}/activity/${activity.uid}/create_patient?name=${patient.name}&template_patient_id=${patient.uid}`"
              >Create Patient</q-btn>
            </div>
          </div>
          </q-card-section>
          <q-card-section class="text-white q-pa-sm">
            Add a new patient and create this chart
          </q-card-section>
        </q-card>
        <q-card v-else-if="activity.activity_type!=='Simulation' || activity.sim_preview===true || activity.current_sim" class="bg-secondary">
          <q-card-section class="bg-primary text-white">
            <div class="row">
              <div class="col-shrink">
                <q-avatar color="white" class="text-primary">
                  <img v-if="patient.patient_picture" :src="patient.patient_picture" alt="Patient Picture" />
                  <span v-else>{{ initials(patient) }}</span>
                </q-avatar>
              </div>
              <div class="col-grow q-ml-md vertical-middle"><span class="text-bold">{{ patient.name }}</span><br>
                <span v-if="patient.age || patient.sex || patient.date_of_birth">{{ patient.age}} | {{ patient.date_of_birth_override ? patient.date_of_birth_override : date_string(patient, patient.date_of_birth, true) }} | {{ patient.sex }}</span>
              </div>
              <div class="col-shrink text-right q-mt-md q-mt-md-none">
                <q-btn v-if="(past_due && patient.patient_type!=='template') || (patient.simulation_uid && activity.current_sim && activity.current_sim !== patient_simulation_uid) " icon="lock" outline color="white" class="float-right" :to="`/patient/${patient.uid}`">Review Chart</q-btn>
                <q-btn v-else-if="!past_due && (activity.activity_type!=='Simulation' || activity.current_sim)" outline color="white" class="float-right" @click="launchpatient(patient)">Patient Chart</q-btn>
                <q-btn v-else icon="lock" outline color="white" class="float-right" :to="`/patient/${patient.uid}?preview=true&activity_uid=${activity.uid}&course_uid=${course.uid}`">Preview Chart</q-btn>
              </div>
            </div>
          </q-card-section>
          <q-card-section v-if="patient.mrn" class="text-white q-pa-sm">
            <span class="text-bold">MRN:</span>{{ patient.mrn}}
          </q-card-section>
          <q-card-section v-if="patient.grade || patient.comment" class="bg-white">
            <span class="text-bold">Grade</span>: {{ patient.grade }}<br>
            {{ patient.comment }}
          </q-card-section>
        </q-card>
      </div>
    </q-card-section>
    <q-card-section v-if="activity.activity_type==='Simulation' && !activity.current_sim" class="bg-warning">
      Your Simulation has not started yet
    </q-card-section>
  </q-card>
    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
</template>

<script>
import {onMounted, ref, computed} from 'vue';
import {useRegionStore} from "../../stores/region";
import {usePatientStore} from "../../stores/patient";
import {useUserStore} from "../../stores/user";
import {useSocketStore} from "../../stores/socket";
import { useRouter } from "vue-router";
import { date_string } from "../../util";
import { date } from "quasar";
import {storeToRefs} from "pinia";

export default {
  name: "StudentActivity",
  props: ['activity_uid', 'course_uid'],
  setup(props){
    const regionStore = useRegionStore()
    const patientStore = usePatientStore()
    const socketStore = useSocketStore()
    const {activity} = storeToRefs(patientStore);
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const { org } = storeToRefs(regionStore);
    const course = ref(null);
    const patients = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const now = ref(new Date());

    setInterval(function() {
            now.value = new Date();
        }, 1000);

    const past_due = computed(()=>{
      if(!activity.value.activity_end_date) return false;
      return new Date() > new Date(activity.value.activity_end_date)
    })

    onMounted(() => {
        loading.value = true;
        console.log('activity mounted');
        regionStore.GetStudentActivity(props.course_uid, props.activity_uid).then(response => {
          activity.value = response.activity;
          course.value = response.course;
          for(let p of activity.value.template_patients){
            for(let patient of response.patients){
              if(p.uid === patient.patient_uid){
                p.uid = patient.instance_patient_uid;
                p.comment = patient.comment;
                p.grade = patient.grade;
                p.patient_type = activity.value.assignment_type==='Individual' ? 'individual' : 'shared';
              }
            }
          }
          if(activity.value.activity_type === 'Simulation'){
            console.log('Connecting to Sim Websocket')
            socketStore.connect_sim(activity.value.current_sim ? activity.value.current_sim.uid : null, props.course_uid, props.activity_uid, org.value.uid)
          }
          loading.value = false;
        });
    });

    return {
      now,
      userStore,
      loading,
      past_due,
      date_string,
      date,
      router,
      activity,
      course,
      patients,
      launchpatient(patient){
        loading.value = true;
        patientStore.LaunchPatient(props.activity_uid, props.course_uid, patient).then(route => {
          setTimeout(function() {
            router.push(route);
            loading.value = false;
          }, 1000);
        });
      },
      initials(patient){
        return patient.name.split(' ').map(n => n[0]).join('');
      },
      sim_time(now){
        if(activity.value.current_sim.simulation_clock_offset !== null){
        const seconds = activity.value.current_sim.simulation_clock_offset * 60;
          if(activity.value.current_sim.status === 'running') {
            return date.addToDate(now, {seconds: seconds})
          }
          else {
            return date.addToDate(activity.value.current_sim.paused_datetime, {seconds: seconds})
          }
        }
        return now;
      }

    }
  },
}
</script>

<style scoped lang="scss">
.description_box {
  border: 1px solid $primary;
}
</style>
