import { useWebSocket } from '@vueuse/core'
import { usePatientStore } from "@/stores/patient";
import { useRegionStore} from "@/stores/region";
import { useUserStore } from "@/stores/user";
import {defineStore, storeToRefs} from "pinia";
import {Notify} from "quasar";
import {useRouter} from "vue-router";
import { Dialog } from 'quasar'

export const useSocketStore = defineStore('socket', {
    state: () => {
        return {
            ws: null,
        }
    },
    actions: {
        connect_sim(simulation_uid, course_uid, activity_uid, organization_uid) {
            console.log('Connect Sim Called')
            if(!this.ws) {
                //Get the Region
                const regionStore = useRegionStore();
                regionStore.GetOrganization(organization_uid).then(() => {
                    const {org} = storeToRefs(regionStore);
                    console.log('Creating WebSocket at ' + `wss://ws.${org.value.region}.${import.meta.env.VITE_STAGE}.chartflow.io`)
                    const that = this;
                    this.ws = useWebSocket(`wss://ws.${org.value.region}.${import.meta.env.VITE_STAGE}.chartflow.io`,
                        {
                            immediate: true,
                            autoClose: false,
                            autoReconnect: {
                                retries: -1,
                                delay: 1000,
                                onFailed() {
                                    console.log('Failed to connect WebSocket')
                                    that.ws.close()
                                },
                            },
                            /*heartbeat: {
                                message: JSON.stringify({action: 'heartbeat'}),
                                interval: 60000,
                                onTimeout() {
                                    console.log('Heartbeat timeout, closing socket')
                                    close()
                                },
                            },*/
                            onConnected() {
                                console.log('WebSocket connected!')
                                const userStore = useUserStore();
                                const {user} = storeToRefs(userStore);
                                that.ws.send(JSON.stringify({
                                    action: 'connect_sim',
                                    simulation_uid: simulation_uid,
                                    course_uid: course_uid,
                                    activity_uid: activity_uid,
                                    user_embed: user.value
                                }))
                            },
                            onDisconnected() {
                                console.log('WebSocket disconnected!')
                            },
                            onMessage(ws, e) {
                                const message = JSON.parse(e.data);
                                console.log(message);

                                const patientStore = usePatientStore();
                                const {students} = storeToRefs(patientStore);
                                const {activity, selected_sim, patient, viewing_visit} = storeToRefs(patientStore);

                                if (message.connect) {
                                    if (!students.value.find(s => s.uid === message.connect.user_embed.uid)) {
                                        students.value.push(message.connect.user_embed);
                                    }
                                }
                                if (message.disconnect) {
                                    students.value = students.value.filter(s => s.uid !== message.disconnect.user_embed.uid);
                                }

                                const userStore = useUserStore();
                                const {user} = storeToRefs(userStore);

                                if (message.completed && user.default_route === '/student') {
                                    const d = Dialog.create({
                                        title: 'Simulation Completed',
                                        message: 'This simulation has ended. You will be returned to the student page.'
                                    }).onDismiss(() => {
                                        const router = useRouter();
                                        router.push(`/student`)
                                    })
                                }
                                if (message.activity && activity.value && activity.value.uid === message.activity.uid) {
                                    activity.value = message.activity;
                                }
                                if (message.simulation_run && selected_sim.value && selected_sim.value.uid === message.simulation_run.uid) {
                                    selected_sim.value = message.simulation_run;
                                }
                                if (message.simulation_run && activity.value && activity.value.current_sim && activity.value.current_sim.uid === message.simulation_run.uid) {
                                    activity.value.current_sim = message.simulation_run;
                                }
                                if (message.simulation_run && patient.value && patient.value.activity && patient.value.activity.current_sim && patient.value.activity.current_sim.uid === message.simulation_run.uid) {
                                    patient.value.activity.current_sim = message.simulation_run;
                                }
                                if (message.patient) {
                                    if (message.visit) {
                                        Notify.create({
                                            color: 'info',
                                            message: `${message.patient.name} visit has been updated`,
                                            timeout: 10000,
                                            actions: [
                                                {
                                                    label: 'View',
                                                    flat: false,
                                                    color: 'primary',
                                                    to: `/patient/${message.patient.uid}/visit/${message.visit.uid}`
                                                },
                                                {
                                                    icon: 'close', color: 'white', round: true, handler: () => { /* ... */
                                                    }
                                                }]
                                        })
                                    } else if (message.scene_uid) {
                                        Notify.create({
                                            color: 'info',
                                            message: `${message.patient.name} has been updated`,
                                            timeout: 10000,
                                            actions: [
                                                {
                                                    label: 'View',
                                                    flat: false,
                                                    color: 'primary',
                                                    to: `/patient/${message.patient.uid}`
                                                },
                                                {
                                                    icon: 'close', color: 'white', round: true, handler: () => { /* ... */
                                                    }
                                                }]
                                        })
                                    } else if (message.flowsheet) {
                                        Notify.create({
                                            color: 'info',
                                            message: `${message.patient.name} ${message.flowsheet.flowsheet_name} has been updated`,
                                            timeout: 10000,
                                            actions: [
                                                {
                                                    label: 'View',
                                                    flat: false,
                                                    color: 'primary',
                                                    to: `/patient/${message.patient.uid}/flowsheet/${message.flowsheet.uid}`
                                                },
                                                {
                                                    icon: 'close', color: 'white', round: true, handler: () => { /* ... */
                                                    }
                                                }]
                                        })
                                    } else {
                                        Notify.create({
                                            color: 'info',
                                            message: `${message.patient.name} has been updated`,
                                            timeout: 10000,
                                            actions: [
                                                {
                                                    label: 'View',
                                                    flat: false,
                                                    color: 'primary',
                                                    to: `/patient/${message.patient.uid}`
                                                },
                                                {
                                                    icon: 'close', color: 'white', round: true, handler: () => { /* ... */
                                                    }
                                                }]
                                        })
                                    }
                                }
                                if (message.patient && patient.value && patient.value.uid === message.patient.uid) {
                                    if (message.scene_uid) {
                                        console.log('Scene Added')
                                    }
                                    if (message.visit) {
                                        if (viewing_visit.value && viewing_visit.value.uid === message.visit.uid) {
                                            console.log('Visit Update')
                                            viewing_visit.value = message.visit;
                                        }
                                    }
                                    if (message.flowsheet) {
                                        console.log('Flowsheet Update');
                                        console.log(message.flowsheet);
                                    }
                                    message.patient.activity = patient.value.activity;
                                    patient.value = message.patient;
                                }
                            }
                        });
                });
            }
            else{
                console.log('WebSocket already created');
                console.log('Socket Status: ' + this.ws.status);
            }

        }
    }
});
