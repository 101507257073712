<template>
    <div class="row q-pa-md">
      <div class="col-6 text-h5">
        {{ flowsheet.flowsheet_name }}
        <q-btn class="absolute-top-right q-ma-md" v-if="!readonly" color="primary" icon="add" @click="new_form()">New Entry</q-btn>
      </div>
    </div>
      <q-markup-table separator="vertical" ref="table" wrap-cells>
        <thead>
          <draggable v-model="flowsheet.fields" item-key="uid" group="fields" :disabled="!editing" tag="tr">
            <template #item="{element}">
              <th :class="{ 'active-row': active_field ? element.uid === active_field.uid : false}" @click="$emit('selected', element)" scope="col">
                {{ element.field_name }}
              </th>
            </template>
            <template #header>
              <th @click="time_sorted = time_sorted === 'asc' ? 'dsc' : 'asc'" class="cursor-pointer">Date/Time
                <q-icon flat color="white" :name="time_sorted === 'asc' ? 'arrow_upward' : 'arrow_downward'"></q-icon>
              </th>
            </template>
            <template #footer>
              <th>Action History</th>
              <th></th>
            </template>
          </draggable>
        </thead>
        <tbody>
          <template v-for="{key, obj, field_id, lookup_data} in row_data">
              <tr>
                <td>
                  <q-avatar size="36px" color="secondary">
                    <img v-if="obj.user_embed.picture" :src="obj.user_embed.picture"/>
                    <span v-else>{{ obj.user_embed.initials }}</span>
                    <q-tooltip class="bg-secondary text-white">
                      {{ obj.user_embed.username }}
                    </q-tooltip>
                  </q-avatar>
                  <div class="q-ml-sm vertical-middle inline-block text-center">
                    {{ date_string(patient, obj.timestamp) }}<br><span v-if="!['template', 'scene'].includes(patient.patient_type)">{{ time_string(patient, obj.timestamp) }}</span>
                  </div>
                </td>
                <template v-for="element in flowsheet.fields">
                  <td>
                    <template v-if="element.data_type==='Lookup Advanced'">
                      <TipTap v-model="lookup_data.advanced_render" :edit="false" :lookup_type="lookup_data" :data="obj"></TipTap>
                    </template>
                    <template v-else>
                      <TipTap v-if="element.advanced_render" v-model="element.advanced_render" :lookup_type="element" :edit="false" :data="obj"></TipTap>
                      <template v-else v-for="field in get_fields(element, obj)">
                        <span class="text-bold">{{field.field_name}}:&nbsp;</span>
                        <component :edit="false" outlined dense :is="lookup(field.data_type)" v-model="obj[field.uid]" :patient="patient" :field="field"></component>
                        <br>
                      </template>
                    </template>
                  </td>
                  <td>
                    <template v-for="(a, timestamp) in get_value(key, field_id, data.times)">
                      {{ date_string(patient, timestamp) }}&nbsp;<span v-if="!['template', 'scene'].includes(patient.patient_type)">{{ time_string(patient, timestamp) }}</span>:&nbsp;
                      <template
                        v-if="a[key] && a[key][field_id]"
                        v-for="(action_data, action_uid) in a[key][field_id]"
                      >
                        <q-chip
                            v-if="get_action(element, obj, action_uid)"
                            :removable="!readonly && can_edit(obj.user_embed.uid) && !obj.read_only"
                            :clickable="get_action(element, obj, action_uid).fields.length > 0 || ![null, undefined].includes(get_action(element, obj, action_uid).flowsheet)"
                            @remove="delete_action(a, element, field_id, action_uid, obj); do_save();"
                            @click.prevent.stop="select_edit_time(timestamp); select_action(a, element, field_id, get_action(element, obj, action_uid), obj, readonly || !can_edit(obj.user_embed.uid) || obj.read_only, {ok: do_save, show_timestamp: true} );"
                        >
                          <!--<TipTap v-if="get_action(element, obj, action_uid).advanced_render" v-model="get_action(element, obj, action_uid).advanced_render" :lookup_type="get_action(element, obj, action_uid)" :edit="false" :data="d[element.uid][field_id][action_uid]"></TipTap>-->
                          <q-icon v-if="get_action(element, obj, action_uid).fields.length > 0 || get_action(element, obj, action_uid).flowsheet" name="comment" class="q-pr-sm"></q-icon>
                          <span>{{get_action(element, obj, action_uid).action_name}}</span>
                        </q-chip>
                      </template>
                    </template>
                  </td>
                  <td style="max-width: 175px">
                    <q-btn-dropdown label="Actions" color="accent" @click.stop.prevent>
                      <q-list dense>
                        <q-item clickable v-close-popup v-for="action in get_actions(element, obj)" @click="add_action(['template', 'scene'].includes(patient.patient_type) ? 0 : datetime_to_epoch_offset(patient, patientStore.patient_time(new Date())), element, field_id, action, obj)">
                          <q-item-section>
                            <q-item-label>
                              {{action.action_name}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-btn-dropdown>
                    <q-btn v-if="!readonly && can_edit(obj.user_embed.uid) && !obj.read_only" color="grey-4" round size="sm" class="q-ma-sm" @click="select_med(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, obj, field_id)">
                      <q-icon class="text-primary" name="edit"></q-icon>
                    </q-btn>
                    <q-btn v-if="['template', 'scene'].includes(patient.patient_type) && !readonly && can_edit(obj.user_embed.uid) && !obj.read_only" color="negative" round size="sm" class="q-ma-sm" @click="delete_advanced(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, field_id, obj); save();">
                      <q-icon class="text-white" name="close"></q-icon>
                    </q-btn>
                  </td>
                </template>
              </tr>
          </template>
        </tbody>
      </q-markup-table>
      <div v-if="Object.keys(data).length === 1 && data.times.length==0">This patient has no {{ flowsheet.flowsheet_name }} to view</div>
    <q-page-sticky class="q-ma-md">
      <q-fab v-if="!editing && (new_mode || edit_mode!==null)" color="secondary" label="Save" icon="save" @click="save"></q-fab>
    </q-page-sticky>
    <q-dialog v-model="confirm_delete" persistent>
      <q-card>
      <q-card-section>
        Are you sure you want to delete the date: {{ date_time_string(patient, delete_holder.timestamp) }}
      </q-card-section>
      <q-card-actions>
        <q-btn class="q-ma-sm" @click="confirm_delete=false">Cancel</q-btn>
        <q-btn class="q-ma-sm" color="negative" @click="delete_confirmed()">Delete</q-btn>
      </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import { epoch_offset_to_datetime, date_time_string, datetime_to_epoch_offset, date_string, time_string} from "../../util";
import NumericField from "./NumericField.vue";
import PatientDateTimeField from "../PatientDateTimeField.vue";
import {inject, nextTick, provide, ref, toRef, watch, computed} from "vue";
import AdvancedForm from "./AdvancedForm.vue";
import {usePatientData} from "../../composables/patient_data";
import PatientHelp from "./PatientHelp.vue";
import TipTap from "../TipTap.vue";
import Form from "./Form.vue";
import {useQuasar} from "quasar";

export default {
  name: "FormTable",
  props: ["flowsheet", "editing", "active_field", "data", "new_mode"],
  components: {
    PatientHelp,
    AdvancedForm,
    PatientDateTimeField,
    draggable,
    NumericField,
    Form,
    TipTap
  },
  setup(props) {
    const {
        patient,
        advanced_add,
        newcolumn,
        advanced_obj,
        advanced_element,
        new_ele,
        new_mode,
        edit_mode,
        patientStore,
        userStore,
        viewing_visit,
        readonly,
        save,
        edit,
        new_entry,
        start_advanced_add,
        finish_advanced_add,
        open_advanced,
        delete_advanced,
        select_action,
        lookup,
        can_edit,
        delete_data,
        confirm_delete,
        delete_holder,
        delete_confirmed,
        reset_data,
        get_action,
        get_actions,
        delete_action,
        set_data
    } = usePatientData(props);
    const entry_form = ref(false);
    const form_data = null
    const versioned = inject('versioned')
    const time_sorted = ref('dsc')

    provide('flowsheet', toRef(props, 'flowsheet'));
    provide('patient', patient);

    const $q = useQuasar()

    const row_data = computed(() => {
      let data = [];
      for(let key in props.data){
        if(key === 'times') continue;
        let d = props.data[key];
        for(let field_id in d){
          let obj = d[field_id];
          let lookup_data = props.flowsheet.lookup_data[obj.lookup.lookup_type]
          data.push({key, field_id, obj, lookup_data});
        }
      }
      data.sort((a, b) => {
        let a_time = a.obj.timestamp;
        let b_time = b.obj.timestamp;
        if(time_sorted.value === 'asc'){
          return a_time - b_time;
        }
        if(time_sorted.value === 'dsc'){
          return b_time - a_time;
        }
        return 0;
      });
      return data;
    });

    function select_edit_time(t) {
        t = typeof t === 'number' ? t : parseInt(t);
        let found = props.data.times.find(f => f.timestamp === t);
        if(found){
          edit(found);
          newcolumn.value = null;
        }
        else{
          newcolumn.value = {
            user_embed: userStore.user,
            timestamp: t
          }
          edit_mode.value = null;
        }
        return found || newcolumn.value;
    }

    function do_save(){
      if(!props.editing) save();
    }

    function add_action(t, element, field_id, action, obj){
      if(readonly.value){
        return;
      }
      newcolumn.value = {
            user_embed: userStore.user,
            timestamp: t
      }
      select_action(newcolumn.value, element, field_id, action, obj, false, {ok: do_save, show_action: true, show_timestamp: true});
    }

    return {
      row_data,
      time_sorted,
      patient,
      readonly,
      viewing_visit,
      userStore,
      patientStore,
      newcolumn,
      new_mode,
      edit_mode,
      new_ele,
      new_form(){
        let timestamp = datetime_to_epoch_offset(patientStore.patient, patientStore.patient_time(new Date()));
        if(["template", "scene"].includes(patientStore.patient.patient_type)){
          timestamp = 0
        }

        let element = null;
        for(let field of props.flowsheet.fields){
          if(field.data_type === "Lookup Advanced"){
            element = field;
            break;
          }
        }

        select_edit_time(timestamp);
        start_advanced_add(element, do_save, true);
      },
      date_string,
      time_string,
      datetime_to_epoch_offset,
      lookup,
      save,
      edit,
      can_edit,
      delete_data,
      confirm_delete,
      delete_holder,
      delete_confirmed,
      date_time_string,
      reset_data,
      entry_form,
      select_med(element, obj, field_id){
        select_edit_time(obj.timestamp);
        open_advanced(element, obj, field_id, do_save, false);
      },
      get_value(key, field_id, data) {
        let t = null;
        let value = null;
        for(let d of data){
          if((d.timestamp > t || t === null) && d[key] && d[key][field_id]){
            t = d.timestamp;
            value = d;
          }
        }
        let r = {};
        if(t!==null) r[t] = value;
        return r;
      },
      delete_advanced,
      do_save,
      select_action,
      get_action,
      get_actions,
      delete_action,
      add_action,
      select_edit_time
    }
  }
}
</script>

<style scoped>

</style>
