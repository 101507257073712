<template>
  <q-markup-table separator="vertical">
    <thead>
      <tr>
        <th>Student Name</th>
        <th v-for="patient in activity.template_patients" class="text-body1">
          <div class="row">
            <div class="col-shrink q-ma-md">
              <q-avatar color="white" class="text-primary">
                <img v-if="patient.patient_picture" :src="patient.patient_picture" alt="Patient Picture" />
                <span v-else>{{ initials(patient) }}</span></q-avatar>
            </div>
            <div class="col-auto">
              {{ patient.name }}<br>
              <span class="text-bold">Age: </span>{{ patient.age}} |  <span class="text-bold">Sex: </span>{{ patient.sex }}<br>
              <span class="text-bold">MRN: </span>{{ patient.mrn}}
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(submission, index) in submissions">
        <td>{{ submission.username }}</td>
        <td v-for="patient in activity.template_patients">
          <span v-if="!submission.submissions[patient.uid]">&nbsp;</span>
          <span v-else-if="submission.submissions[patient.uid].grade || submission.submissions[patient.uid].comment">
            <router-link :to="`/patient/${submission.submissions[patient.uid].instance_patient_uid}?grading=${submission.uid}`">
              {{ submission.submissions[patient.uid].grade }}
              <span v-if="!submission.submissions[patient.uid].grade">No Grade</span>
              <q-btn v-if="submission.submissions[patient.uid].comment" icon="comment" class="q-ml-md" flat round dense color="primary" @click.prevent.capture="comment = true; current_submission = submission.submissions[patient.uid];"/>
            </router-link>
          </span>
          <span v-else>
            <router-link :to="`/patient/${submission.submissions[patient.uid].instance_patient_uid}?grading=${submission.uid}`">In Progress</router-link>
          </span>
        </td>
      </tr>
    </tbody>
  </q-markup-table>
    <q-inner-loading
    :showing="loading"
    label="Please wait..."
    label-class="text-secondary"
    label-style="font-size: 1.1em"
    style="min-height: 500px;"
  />
  <q-dialog v-model="comment">
    <q-card>
      <q-card-section>
        <div class="text-subtitle1 text-bold">Grading Comment</div>
      </q-card-section>

      <q-card-section class="q-pt-none" v-html="current_submission.comment.replaceAll('\n', '<br>')">
      </q-card-section>

      <q-card-actions align="right">
        <q-btn label="OK" color="secondary" v-close-popup @click="comment=false; current_submission=null"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {onMounted, ref} from "vue";
import { useRegionStore} from "../stores/region";
import {storeToRefs} from "pinia";
import {date_string} from "../util";

export default {
  name: "Submissions",
  props: ['course_uid', 'activity'],
  setup(props){
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore)
    const loading = ref(false);
    const filter = ref('');
    const submissions = ref([]);
    const comment = ref(null);
    const current_submission = ref(null);
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'activity_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest() {
        const { page, rowsPerPage, sortBy, descending } = pagination
        loading.value = true

        regionStore.GetStudentSubmissions(
            props.course_uid,
            props.activity.uid,
            ).then(data => {
          if(typeof  data === 'string'){
            loading.value = false
            return
          }
          pagination.value.rowsNumber = data.total
          submissions.value.splice(0, submissions.value.length, ...data.students);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

    return {
      current_submission,
      comment,
      date_string,
      loading,
      regionStore,
      filter,
      pagination,
      onRequest,
      submissions,
      initials(patient){
        if(patient && patient.name) return patient.name.split(' ').map(name => name[0]).join('');
      }
    }
  },
}
</script>

<style scoped>

</style>
