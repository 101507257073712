<template>
  <div>
    <q-card>
      <img src="/logo.svg" class="q-pa-xl">
      <q-form class="q-gutter-md q-pa-md text-center">
        <q-card>
          <q-card-section>We’ll email you a magic code for a password-free sign in.<br/> Or you can sign in with your password instead.</q-card-section>
        </q-card>
        <q-input class="q-pa-sm" label="Login" outlined name="email" v-model="email" type="email" autocomplete="username"/>
        <q-input class="q-pa-sm" label="Password" outlined name="password" v-model="password" type="password"
            :error-message="login_error" :error="login_error?true:false"
            autocomplete="current-password"
        />
        <q-btn color="secondary" @click.prevent="login" type="submit">Login</q-btn>
      </q-form>
      <q-inner-loading
        :showing="loading"
        label="Please wait..."
        label-class="text-secondary"
        label-style="font-size: 1.1em"
      />
    </q-card>
    <div class="text-center">
      <q-btn outlined rounded class="q-ma-md" to="/request_reset">Reset Password</q-btn><br>
      <q-btn rounded color="primary" class="q-ma-md" to="/signup">Create Student Account</q-btn><br>
      <p class="text-primary">
        <span class="text-primary text-h4">Need an Instructor Account?</span><br>
        Instructors should contact their Program's Admin to create an Account
      </p>
    </div>
  </div>
</template>

<script>
import {useRouter, useRoute} from "vue-router";
import {ref, onMounted} from "vue";
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";

export default {
  name: "Login.vue",
  setup () {
    const router = useRouter();
    const route = useRoute();
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const loading = ref(false);

    onMounted(() => {
      if (route.query.email) {
        loading.value = true;
        userStore.CreateOTP(route.query.email, route.query.redirect_to)
              .then(() => {
                router.push({path: '/verification', query: {email: route.query.email, redirect_to: route.query.redirect_to}});
              });
      }
      console.log('In Login')
      if(!(window.location.hostname.startsWith(import.meta.env.VITE_STAGE) || window.location.hostname.startsWith('www'))){
        let subdomain = window.location.hostname.split('.')[0];
        if(!['www', 'chartflow', 'dev', 'mike-local'].includes(subdomain)){
          loading.value = true;
          regionStore.GetOrgLoginOptions(subdomain)
              .then((result) => {
                loading.value = false;
                if(result.redirect){
                  window.location.href = result.redirect;
                }
              });

        }
      }

    });

    return {
      route,
      router: router,
      login_error: ref(null),
      email: ref(""),
      loading,
      password: ref(""),
      userStore: userStore
    }
  },
  methods: {
      login() {
        if (this.email && this.password) {
          this.loading = true;
          this.userStore.Login(this.email, this.password)
              .then((result) => {
                if(result === true) {
                  this.login_error = null;
                  if(this.route.query.redirect_to && this.route.query.redirect_to !== "/logout") {
                    this.router.push(this.route.query.redirect_to);
                  } else {
                    this.router.push(this.userStore.default_route);
                  }
                }
                else{
                  this.login_error = result;
                }
                this.loading = false;
              });
        }
        else if (this.email){
          this.loading = true;
          this.userStore.CreateOTP(this.email)
              .then(() => {
                this.login_error = null;
                this.loading = false;
                this.router.push("/verification?email=" + encodeURIComponent(this.email))
              });
        }
      }

    }

}
</script>

<style scoped>

</style>
