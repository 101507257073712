<template>
  <div>
    <q-card class="text-center q-pa-lg">
      <h4 class="text-primary">Create Student Account</h4>
      <q-stepper v-model="step"
          :vertical="$q.screen.lt.sm"
          ref="stepper"
          color="primary"
          animated
      >
        <q-step :name="1" title="Enter Your Email Address" :done="step > 1" :error="email_error?true:false">
          <q-input label="Email" outlined v-model="email" :error-message="email_error" :error="email_error?true:false"></q-input>
          <p class="text-bold">Enter a Course Code Below</p>
          <q-input label="Course Code" outlined pattern="[^0-9a-fA-f]*$" v-model="course_code"
                    :error-message="course_error" :error="course_error?true:false"
                   :rules="[ val => regex.test(val) || `Course Codes must be digits and the letters a-f`]"
          ></q-input>
        </q-step>
        <q-step :name="2" title="Verify your Email Address" :done="step > 2">
          <p>An E-mail has been sent to the address: <span class="text-bold">{{ email }}</span> please enter the code below or click the link in the email</p>
          <q-input label="Verification Code" outlined v-model="verification_code"
                   :error-message="verification_error" :error="verification_error?true:false"
                   max-length="6" min-length="6" type="tel" pattern="[0-9]*"
                   :rules="[ val => val.length <= 6 || `Please use 6 digits, current length: ${val.length}`]"></q-input>
        </q-step>
        <q-step :name="3" title="Verify Your School or Organization" :done="step > 2">
          <q-select
              use-input
              label="School or Organization"
              outlined
              v-model="org"
              :options="options"
              @filter="fetchOrganizations"
              option-label="name"
              option-value="uid"
              class="q-ma-md"
          ></q-select>
          <q-select
            v-if="org && org.billing_level === 'Program'"
            use-input
            label="Program"
            outlined
            v-model="program"
            :options="programs"
            @filter="fetchPrograms"
            option-label="program_name"
            option-value="uid"
            class="q-ma-md"
          ></q-select>
          <q-select
            v-if="org && org.billing_level === 'Campus'"
            use-input
            label="Campus"
            outlined
            v-model="campus"
            :options="campuses"
            @filter="fetchCampuses"
            option-label="campus_name"
            option-value="uid"
            class="q-ma-md"
          ></q-select>
        </q-step>
        <q-step :name="4" title="Enter your personal details" :done="step > 3">
          <ProfilePicUploader v-model="user.picture" />
          <input type="text" name="username" :value="email" class="hidden" autocomplete="username">
          <q-input class="q-pa-sm" label="First Name" v-model="first_name" outlined></q-input>
          <q-input class="q-pa-sm" label="Last Name" v-model="last_name" outlined></q-input>
          <q-input class="q-pa-sm" label="Password" v-model="password" outlined type="password"></q-input>
          <q-input class="q-pa-sm" label="Confirm Password" v-model="confirm_password" outlined type="password"
            :rules="[ val => val === password || `Passwords do not match`]"
          ></q-input>
        </q-step>
        <template v-slot:navigation>
          <q-stepper-navigation>
            <q-btn @click="nextStep" color="primary" :label="step === 4 ? 'Finish' : 'Continue'" />
          </q-stepper-navigation>
        </template>
      </q-stepper>
      <q-inner-loading
        :showing="loading"
        label="Please wait..."
        label-class="text-secondary"
        label-style="font-size: 1.1em"
      />
    </q-card>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {onMounted, ref} from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from "../stores/user";
import { useRegionStore } from "../stores/region";

export default {
  name: "SignUp",
  props: ['verify', 'email'],
  setup(props, { attrs, slots, emit, expose }) {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore);
    const verification_code = ref(props.verify || '')
    const step = ref( verification_code.value ? 3 : 1 );
    const email = ref( props.email || '' );
    const program = ref('');
    const campus = ref('');
    const course_code = ref('');
    const first_name = ref('');
    const last_name = ref('');
    const password = ref('');
    const confirm_password = ref('');
    const loading = ref(false);
    const regex = /^[0-9a-fA-f]*$/;
    const email_error = ref('');
    const course_error = ref('');
    const verification_error = ref('');
    const options = ref([]);
    const programs = ref([]);
    const campuses = ref([]);
    const router = useRouter();
    const stepper = ref(null);



    function nextStep(){
        if (step.value == "1") {
          loading.value = true;
          userStore.CheckUser(email.value, course_code.value).then((success) => {
            if (success === true) {
              email_error.value = "";
              stepper.value.next();
            }
            else{
              if(success === 'Course not found') course_error.value = success;
              else email_error.value = success;
            }
            loading.value = false;
          });
        }
        if(step.value == "2"){
          loading.value = true;
          userStore.VerifyUser(email.value, verification_code.value).then((success) => {
            if (success === true) {
              verification_error.value = "";
              if(user.value.schools.length){
                stepper.value.goTo(4);
              }
              else{
                stepper.value.next();
              }
            }
            else{
              verification_error.value = success;
            }
            loading.value = false;
          });
        }
        if(step.value==3){
          stepper.value.next();
          return;
        }
        if(step.value==4){
          const u = {
            uid: user.value.uid,
            email: email.value,
            first_name: first_name.value,
            last_name: last_name.value,
            password: password.value,
            organization: org.value,
            campus: campus.value,
            program: program.value,
            username: first_name.value + ' ' + last_name.value,
            picture: null
          }
          loading.value = true;
          userStore.UpdateUser(u).then((success) => {
            router.push(userStore.default_route);
            loading.value = false;
          });
        }
      }

      function fetchOrganizations(val, doneFN, abortFN) {
        userStore.GetOrganizations(1, 50, null, false, val, true).then((success) => {
          options.value = success.organizations;
          doneFN();
        });
      }

      function fetchPrograms(val, doneFN, abortFN) {
        regionStore.GetPrograms(1, 50, null, false, val).then((data) => {
          programs.value = data;
          doneFN();
        });
      }

      function fetchCampuses(val, doneFN, abortFN) {
        regionStore.GetCampuses(1, 50, null, false, val).then((data) => {
          campuses.value = data;
          doneFN();
        });
      }

    onMounted(() => {
      if(user.value){
        if(user.value.schools.length) {
          step.value = 4;
        }
        else{
          step.value = 3;
        }
      }
      else if (verification_code.value) {
        step.value = 2;
        nextStep();
      }
    });

    return {
      step: step,
      email,
      email_error,
      course_error,
      org,
      program,
      campus,
      verification_error,
      user,
      userStore,
      options,
      programs,
      campuses,
      loading,
      verification_code,
      course_code,
      regex,
      first_name,
      last_name,
      password,
      confirm_password,
      router,
      nextStep,
      fetchOrganizations,
      fetchPrograms,
      fetchCampuses,
      stepper
    }
  }
}
</script>

<style scoped>
</style>
