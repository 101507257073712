<template>
    <q-drawer v-model="leftDrawerOpen" side="left" elevated :width="200">
      <q-list>
        <q-item clickable to="/student" :active="$route.path=='/student'" active-class="bg-primary text-white">
          <q-item-section>
            Home
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container class="bg-grey-4">
      <q-page class="q-pa-lg">
        <router-view />
      </q-page>
    </q-page-container>
</template>

<script>
import {useRouter} from "vue-router";
import {ref, inject} from "vue";
import {useUserStore} from "../stores/user";
import {storeToRefs} from "pinia";

export default {
  name: "StudentLayout",
  setup () {
    const leftDrawerOpen = false;

    return {
      leftDrawerOpen,
      toggleLeftDrawer () {
        leftDrawerOpen.value = !leftDrawerOpen.value
      },
    }
  },
}
</script>

<style scoped>

</style>
