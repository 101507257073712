<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" :maximized="$q.screen.lt.md">
      <q-card style="width: 768px; max-width: 100%">
        <q-form @submit.prevent="onOKClick">
          <q-card-section class="scroll col">
            <q-table
                title="Users"
                class="q-pa-md"
                style="height: calc(100vh - 150px);"
                :columns="columns" :rows="users" row-key="uid"
                  separator="vertical" :loading="loading" :filter="filter"
                v-model:pagination="pagination"
                @request="onRequest"
                :grid="$q.screen.xs"
                :selected-rows-label="getSelectedString"
                selection="multiple"
                v-model:selected="selected"
                flat
                :rows-per-page-options="[10,20,50,100]"
                >
              <template v-slot:top-left>
                <div>
                  <div class="text-h5 text-primary q-mb-md">Select {{ role }}s</div>
                  <div>
                    <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                      <template v-slot:append>
                        <q-icon name="search" />
                      </template>
                    </q-input>
                  </div>
                </div>
              </template>
              <template v-slot:top-right>
                      <q-btn-toggle v-if="button_options.length > 0"
                        v-model="use_entity"
                        push
                        dense
                        toggle-color="primary"
                        :options="button_options"
                        class="q-mb-md q-mt-md"
                      >
                      </q-btn-toggle>
              </template>
              <template v-slot:body-cell-first_name="props">
                <q-td v-if="props.row.invite">
                  <q-input outlined dense v-model="props.row.first_name" label="First Name" bottom-slots>
                  </q-input>
                </q-td>
                <q-td v-else>
                  {{ props.row.first_name }}
                </q-td>
              </template>
              <template v-slot:body-cell-last_name="props">
                <q-td v-if="props.row.invite">
                  <q-input outlined dense v-model="props.row.last_name" label="Last Name" bottom-slots>
                  </q-input>
                </q-td>
                <q-td v-else>
                  {{ props.row.last_name }}
                </q-td>
              </template>
              <template v-slot:body-cell-email="props">
                <q-td v-if="props.row.invite">
                  <q-input outlined dense v-model="props.row.email" label="Email" :rules="[val => !!val || 'Field is required', isValidEmail]">
                    <template v-slot:append>
                      <q-icon name="email" />
                    </template>
                  </q-input>
                </q-td>
                <q-td v-else>
                  <span v-for="email in props.value">{{ email }}<br></span>
                </q-td>
              </template>
              <template v-slot:body-cell-roles="props">
                <q-td>
                  <q-badge vif="props.row.roles" v-for="role in props.row.roles" color="primary">{{ role }}</q-badge>
                  <q-badge v-for="role in calc_roles(props.row)" color="primary">{{ role }}</q-badge>
                </q-td>
              </template>
              <template v-slot:loading>
                  <q-inner-loading showing color="primary" />
              </template>
              <template v-slot:item="props">
                <q-card style="width: 100%" :class="{'q-my-sm': true, 'active_user' : selected.indexOf(props.row) >= 0 ? true : false}" @click="select_user(props.row)">
                  <q-card-section><UserIcon :user="props.row" color="primary"></UserIcon>&nbsp;{{ props.row.username}}</q-card-section>
                  <q-separator></q-separator>
                  <q-card-section>
                    <span class="text-bold">Email</span><br><span v-for="email in props.colsMap.email.field(props.row)">{{ email }}<br></span>
                    <span class="text-bold">Roles</span><br>
                      <q-badge class="q-ma-xs" v-for="role in props.row.roles" color="primary">{{ role }}</q-badge>
                      <q-badge class="q-ma-xs" v-for="role in calc_roles(props.row)" color="primary">{{ role }}</q-badge>
                  </q-card-section>
                </q-card>
              </template>
            </q-table>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn :label="`New ${role}`" @click="invite()"/>
            <q-btn color="secondary" label="OK" type="submit"/>
          </q-card-actions>
        </q-form>
        <q-btn class="absolute-top-right" icon="close" flat round dense @click="onCancelClick"></q-btn>
      </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, uid } from 'quasar'
import {onMounted, ref, watch} from 'vue'
import { useUserStore } from "../stores/user";
import UserIcon from "./UserIcon.vue";

export default {
  name: "UserSelector",
  props: ['organization_uid', 'campus', 'program', 'course_uid', 'role'],
  components: {UserIcon},

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],

  setup (props) {
    // REQUIRED; must be called inside of setup()
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome
    const userStore = useUserStore();
    const filter = ref('');
    const loading = ref(false);
    const users = ref([]);
    const use_entity = ref(false);
    const button_options = ref([]);
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'last_name',
        descending: false,
    })

    onMounted(() => {
      button_options.value = []
      if(userStore.check_roles(['Superuser', 'Support'])){
        button_options.value.push({
          label: 'All',
          value: null
        });
        use_entity.value = null
      }
      if(props.organization_uid){
        button_options.value.push({label: 'Organization', value: 'organization'});
        use_entity.value = 'organization';
      }
      if(props.program) {
        button_options.value.push({label: 'Program', value: 'program'});
        use_entity.value = 'program';
      }
      if(props.campus) {
        button_options.value.push({label: 'Campus', value: 'campus'});
        use_entity.value = 'campus';
      }
      //onRequest();

    });

    watch(use_entity, (val) => {
      pagination.value.page = 1
      users.value = []
      onRequest({pagination: pagination.value});
    });

    function onRequest(p) {
        loading.value = true

        const { page, rowsPerPage, sortBy, descending } = p.pagination
        userStore.GetUsers(use_entity.value ? props.organization_uid : null,
            use_entity.value === 'campus' ? (props.campus && props.campus.uid) : null,
            use_entity.value === 'program' ? (props.program && props.program.uid) : null,
            null,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter.value,
            props.role === 'Student' ? true : false,
        ).then(data => {

          pagination.value.rowsNumber = data.total
          users.value.splice(0, users.value.length, ...data.users);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

    const selected = ref([])
    return {
      columns: [
        {
          name: 'first_name',
          label: 'First Name',
          field: 'first_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'last_name',
          label: 'Last Name',
          field: 'last_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'email',
          label: 'Email',
          field: function(row) {
            let emails = [];
            for(let uam in row.uams){
              if(row.uams[uam]["system"] === 'email'){
                emails.push(row.uams[uam]["system_key"]);
              }
            }
            return emails;
          },
          align: 'left',
          sortable: true,
        },
      {
          name: 'roles',
          label: 'Roles',
          field: 'schools',
          align: 'left',
          sortable: true,
        },

      ],
      getSelectedString () {
        return selected.value.length === 0 ? '' : `${selected.value.length} record${selected.value.length > 1 ? 's' : ''} selected of ${users.value.length}`
      },
      onRequest,
      button_options,
      use_entity,
      users,
      filter,
      loading,
      pagination,
      selected,
      calc_roles(user){
        let school = null;
        if(user.schools) {
          school = user.schools.find(school => school.organization_uid === props.organization_uid);
        }
        let program = null;
        let course = null;
        let campus = null;
        if(school) {
          program = school.programs.find(program => program.program_uid === props.program_uid);
          campus = school.campuses.find(campus => campus.campus_uid === props.campus_uid);
          if(school.courses) {
            course = school.courses.find(course => course.uid === props.course_uid);
          }
        }
        if(props.course_uid) return course.roles;
        if(props.program_uid) return program.roles;
        if(props.campus_uid) return campus.roles;
        if(props.organization_uid && school) return school.roles;
        if(!props.organization_uid){
          const roles = [];
          for(let school in user.schools){
            roles.push(...user.schools[school].roles);
          }
          return roles;
        };
      },
      invite(){
        const item = {uid: 'new-' + uid(), invite: true, first_name: '', last_name: '', email: ''}
        users.value.splice(0, 0, item);
        selected.value.splice(0, 0, item);
        //invites.value.push({first_name: '', last_name: '', email: ''})
      },
      isValidEmail (val) {
        const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || 'Invalid email';
      },
      select_user(user){
        if(selected.value.includes(user)){
          selected.value.splice(selected.value.indexOf(user), 1);
        } else {
          selected.value.push(user);
        }
      },
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick () {
        for(let u of selected.value){
          if(!u.first_name && !u.last_name && !u.email){
            selected.value.splice(selected.value.indexOf(u), 1);
          }
        }

        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK(selected.value)
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel
    }
  }
}
</script>

<style scoped lang="scss">
.active_user {
  background-color: $accent;
  color: white;
}
</style>
