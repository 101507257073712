<script setup>
    import {useUserStore} from "@/stores/user";
    import {useRegionStore} from "@/stores/region";
    import {storeToRefs} from "pinia";
    import {onMounted, ref} from "vue";

    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore)
    const purchases = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'name',
        descending: false,
    })

    const columns = [
      {
        name: 'date',
        label: 'Date',
        field: 'date',
        align: 'left',
        sortable: true,
      },
      {
        name: 'actions',
        label: 'Actions',
      }
    ]

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

          regionStore.GetPurchases(
            org.value,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter).then(data => {

          pagination.value.rowsNumber = data.total
          purchases.value.splice(0, purchases.value.length, ...data.purchases);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

</script>

<template>
  <q-table
      class="q-pa-md"
      title="Purchases"
      style="height: calc(100vh - 120px);"
      :columns="columns" :rows="purchases" row-key="uid"
        separator="vertical" :loading="loading" :filter="filter"
      v-model:pagination="pagination"
      @request="onRequest"
      :grid="$q.screen.xs"
      >
    <template v-slot:top-left>
      <div>
        <div class="row text-h5 text-primary q-mb-md">Purchases</div>
        <div class="row">
          <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </div>
    </template>
    <template v-slot:top-right>
      <q-btn color="primary" label="New Purchase" :to="`/organization/${org.uid}/purchase_new`"></q-btn>
    </template>
        <template v-slot:body-cell-actions="props">
      <q-td>
        <q-btn class="q-ma-sm" rounded color="primary" @click="select_org(props.row)" size="sm">Purchase Details</q-btn>
      </q-td>
    </template>
    <template v-slot:loading>
        <q-inner-loading showing color="primary" />
    </template>
  </q-table>
</template>

<style scoped>

</style>