<template>
  <Versioned @selected="versioned = $event" readonly :version="version"></Versioned>
  <q-page-container class="bg-grey-4">
      <q-page v-if="flowsheet && active_field">
        <q-card style="min-height: calc(100vh - 120px);" class="bg-white q-ma-lg q-pa-md">
          <q-toolbar class="bg-white">
            <q-toolbar-title>
              {{ active_field.field_name }} Advanced Object Editor
            </q-toolbar-title>
            <div class="q-pa-sm" v-if="issaving">Saving Field...<q-linear-progress indeterminate></q-linear-progress></div>
            <div class="q-pa-sm text-warning" v-else-if="unsaved !== saved">Changes need Saving</div>
            <div class="q-pa-sm text-positive" v-else>Up to Date</div>
            <q-btn color="primary" @click="$router.back()">Back to Flowsheet</q-btn>
          </q-toolbar>
          <TipTap v-model="active_field.advanced_render" :edit="true" :lookup_type="active_field" :data="data" show_advanced></TipTap>

          <AdvancedForm
              :fields="active_field.fields"
              :data="data"
              :selected_field="advanced_field"
              @selected="activate_field"
              @update:fields="active_field.fields = $event"
          ></AdvancedForm>
          <q-card-actions>
            <q-btn @click="add_field" icon="add" label="Add Field"></q-btn>
          </q-card-actions>
        </q-card>
      </q-page>
  </q-page-container>
  <FieldDrawer :flowsheet="flowsheet" v-model="advanced_field" :version="version" @remove="remove(active_field.fields.findIndex(f => f.uid === advanced_field.uid))"></FieldDrawer>
  <q-inner-loading
    :showing="loading"
    label="Please wait..."
    label-class="text-secondary"
    label-style="font-size: 1.1em"
  />
</template>

<script>
import draggable from 'vuedraggable'
import {storeToRefs} from "pinia";
import {useFlowsheetWriter} from "../../stores/flowsheet";
import {onMounted, ref} from "vue";
import NumericField from "./NumericField.vue";
import MultilineText from "./MultilineText.vue";
import PatientDateTimeField from "../PatientDateTimeField.vue";
import { usePatientStore } from "../../stores/patient";
import { useUserStore } from "../../stores/user";
import Versioned from "../Versioned.vue";
import { epoch_offset_to_datetime, date_time_string, datetime_to_epoch_offset, date_string, time_string} from "../../util";
import SelectField from "./SelectField.vue";
import TextField from "./TextField.vue";
import AdvancedForm from "./AdvancedForm.vue";
import TipTap from "../TipTap.vue";
import {generate} from "xksuid";
import FieldDrawer from "../FieldDrawer.vue";

export default {
  name: "AdvancedEdit",
  props: ['flowsheet_uid', 'version', 'field_uid', 'lookup_type', 'from', 'action_uid'],
  components: {
    FieldDrawer,
    AdvancedForm,
    NumericField,
    MultilineText,
    PatientDateTimeField,
    SelectField,
    TextField,
    Versioned,
    draggable,
    TipTap
  },
  setup (props) {
    const patientStore = usePatientStore();
    const userStore = useUserStore();
    const {flowsheetStore, issaving, unsaved, saved, loading } = useFlowsheetWriter();
    const { flowsheet, active_field } = storeToRefs(flowsheetStore);
    const advanced_field = ref(null);
    const data = ref({})
    if(active_field.value && active_field.value.fields===undefined) {
      active_field.value.fields = []
    }

    function set_active_field() {
          if(props.field_uid) {
            active_field.value = flowsheet.value.fields.find(f => f.uid === props.field_uid);
          }
          else {
            active_field.value = flowsheet.value.lookup_data[props.lookup_type];
          }
          if(props.action_uid){
            active_field.value = active_field.value.actions.find(f => f.uid === props.action_uid);
          }
    }

    onMounted(() => {
      if (!flowsheet.value || flowsheet.value.uid !== props.flowsheet_uid) {
        loading.value = true;
        flowsheetStore.GetFlowsheet(props.flowsheet_uid, props.version, props.from).then(f => {
          loading.value = false;
          set_active_field();
        });
      }
      else {
        set_active_field();
      }
    });

    return {
      issaving, unsaved, saved, loading,
      patientStore,
      data,
      flowsheet,
      advanced_field,
      active_field,
      show: ref(true)
    }
  },
  methods: {
    activate_field(field) { // called when a field is selected
      console.log("activate_field", field)
      this.advanced_field = field;
    },
    add_field(){
      const field =  {
        uid: generate(),
        field_name: '',
        data_type: 'Text',
        field_units: [],
        field_options: [],
        help: ''
      }
      this.active_field.fields.push(field);
      this.advanced_field = field;
      this.active_field_index = this.fields.length - 1;

    },
    add_unit(){
      this.advanced_field.field_units.push('');
    },
    add_option(){
      this.advanced_field.field_options.push('');
    },
    remove(index) {
      this.active_field.fields.splice(index, 1);
      this.active_field_index = null;
      this.advanced_field = null;
      this.field_settings = false;
      this.flowsheet_settings = true;
    },

  }
}
</script>

<style lang="scss">
  /*.flowsheet-table tbody tr:nth-child(even) td:first-child{
    background-color: #ffffff;
  }*/

  .active-row {
    background-color: $positive !important;
  }

</style>
