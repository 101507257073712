<template>
  <node-view-wrapper as="span">
    <span v-if="field_config && !data.lookup.data[field_config.uid] && editor.isEditable">&lt;&lt;{{ field_config.field_name }}&gt;&gt;</span>
    <component v-else-if="field_config" :edit="false" outlined dense :is="lookup(field_config.data_type)" v-model="data.lookup.data[field_config.uid]" :field="field_config"></component>
    <span v-else class="text-negative">??Deleted Field - {{ node.attrs.field }}??</span>
  </node-view-wrapper>
</template>

<script>
import {ref, inject, onMounted, computed} from 'vue';
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { lookup } from "../../composables/patient_data";

export default {
  name: "LookupNode",
  components: {NodeViewWrapper},
  props: nodeViewProps,
  setup(props){
    const lookup_type = inject('lookup_type')
    const data = inject('data')

    const field_config = computed(() => {
      if(!lookup_type.value) return null;
      return lookup_type.value.lookup_fields.find(f => f.uid === props.node.attrs.field)
    })

    return {
      lookup,
      data,
      lookup_type,
      field_config,
    }
  }
}
</script>

<style scoped>

</style>
