<template>
  <div>Features</div><br>
  <q-btn color="primary" to="/demo">Free Chart Demo</q-btn>
</template>

<script>
export default {
  name: "Features"
}
</script>

<style scoped>

</style>
