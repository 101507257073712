<template>
  <q-card class="q-ma-lg">
    <q-card-section>

      <div class="row q-pa-md">
        <div class="col-6 text-h5">
          Visit History
        </div>
      </div>
        <q-table
            :style="$q.screen.gt.md ? 'height: calc(100vh - 400px);' : ''"
            :columns="columns" :rows="visits" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            :grid="$q.screen.xs"
            :pagination="{rowsPerPage: 0, sortBy: 'start', descending: true}"
            hide-pagination
            >
              <template v-if="!loading" v-slot:no-data="{ icon, message, filter }">
                <div class="full-width row flex-center q-gutter-sm">
                  <div class="block">
                    <p>Get started by clicking the <span class="text-bold">New Visit</span> button at the top.</p>
                    <p>The Visit Type you select will change the flowsheets and forms you see on the left sidebar.</p>
                    <p>For more information, click here for our <a href="https://help.chartflow.io/articles/patient-chart" target="_blank">Patient Chart Guide</a></p>
                  </div>
                </div>
              </template>
              <template v-slot:body-cell-viewing="props">
                <q-td auto-width>
                  <q-icon size="md" color="accent" v-if="viewing_visit && props.row.uid === viewing_visit.uid" name="check_circle"></q-icon>
                </q-td>
              </template>
              <template v-slot:body-cell-start="props">
                <q-td>
                  {{ date_time_string(patient, props.row.start_timestamp) }}
                </q-td>
              </template>
              <template v-slot:body-cell-end="props">
                <q-td>
                  {{ date_time_string(patient, props.row.end_timestamp) }}
                </q-td>
              </template>
              <template v-slot:body-cell-actions="props">
                <q-td>
                  <q-btn v-if="!viewing_visit || props.row.uid !== viewing_visit.uid" class="q-ma-sm" rounded color="primary" @click="visit(props.row)" size="sm">View Visit</q-btn>
                </q-td>
              </template>
              <template v-slot:loading>
                  <q-inner-loading showing color="primary" />
              </template>
              <template v-slot:item="props">
                <q-card style="width: 100%" class="q-ma-md">
                  <q-card-section>
                    <span class="text-bold">Start</span><br>
                    {{ date_time_string(patient, props.row.start_timestamp) }}<br>
                    <span class="text-bold">End</span><br>
                    {{ date_time_string(patient, props.row.end_timestamp) }}<br>
                    <span class="text-bold">Visit Type</span><br>
                    {{ props.row.visit_type.chart_name }}<br>
                    <span class="text-bold">Reason for Visit</span><br>
                    {{ props.row.reason_for_visit }}
                  </q-card-section>
                  <q-card-actions>
                   <q-btn v-if="!viewing_visit || props.row.uid !== viewing_visit.uid" class="q-ma-sm" rounded color="primary" @click="visit(props.row)" size="sm">View Visit</q-btn>
                  </q-card-actions>
                </q-card>
              </template>
        </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {usePatientStore} from "../../stores/patient";
import {storeToRefs} from "pinia";
import {date_time_string} from "../../util";
import {Notify} from "quasar";

export default {
  name: "VisitHistory",
  setup() {
    const patientStore = usePatientStore();
    const visits = ref([]);
    const loading = ref(false);
    const {patient, viewing_visit} = storeToRefs(patientStore);
    const filter = ref('');

    onMounted(() => {
      loading.value = true;
      patientStore.GetVisits().then(res => {
        visits.value = res.visits;
        loading.value = false;
      });
    });

    watch(patient, (newVal, oldVal) => {
      if(newVal.uid !== oldVal.uid){
        loading.value = true;
        patientStore.GetVisits().then(res => {
          visits.value = res.visits;
          loading.value = false;
        });
      }
    });

    return {
      columns: [
        {
          name: 'viewing',
          label: '',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
        {
          name: 'start',
          label: 'Start',
          field: 'start_timestamp',
          align: 'left',
          sortable: true,
        },
        {
          name: 'end',
          label: 'End',
          field: 'end_timestamp',
          align: 'left',
          sortable: true,
        },
        {
          name: 'visit_type',
          label: 'Visit Type',
          field: 'visit_type',
          format: (value) => {
            return value.chart_name;
          },
          sort: (a, b) => a.chart_name < b.chart_name ? -1 : 1,
          align: 'left',
          sortable: true,
        },
        {
          name: 'reason_for_visit',
          label: 'Reason for Visit',
          field: 'reason_for_visit',
          align: 'left',
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
        },

      ],
      visits,
      loading,
      patient,
      filter,
      viewing_visit,
      date_time_string,
      visit(v){
        Notify.create({
                      color: 'info',
                      message: `Patient ${patient.value.name} Visit set to ${v.visit_type.chart_name} on ${date_time_string(patient.value, v.start_timestamp)}`,
                    })
        viewing_visit.value = v;
      }
    }
  }
}
</script>

<style scoped>

</style>