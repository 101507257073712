<script setup>
import FileUpload from "vue-upload-component";
import {useUserStore} from "@/stores/user";
import {ref} from "vue";
import { useDialogPluginComponent } from 'quasar'

const userStore = useUserStore()
const image = ref(null)
const upload_dialog = ref(null)
const files = ref([])
const loading = ref(false)

function inputFile(newFile, oldFile, prevent) {
  if (newFile && !oldFile) {
    loading.value = true
    let key = newFile.name
    userStore.GetUploadUrl(key, newFile.type)
        .then(response => {
          upload_dialog.value.update(newFile.id, {
            newFile,
            type: newFile.type,
            size: newFile.size,
            active: true,
            putAction: response.url,
            download_url: response.download_url
          });
        });
  }
  if (!newFile && oldFile) {
  }
  if (newFile && oldFile && newFile.success !== oldFile.success) {
    loading.value = false
    console.log('success', newFile.success, newFile)
    image.value = newFile.download_url
  }
}
function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*...*/ }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome

// this is part of our example (so not required)
function onOKClick () {
  // on OK, it is REQUIRED to
  // call onDialogOK (with optional payload)
  onDialogOK({
    image: image.value
  })
  // or with payload: onDialogOK({ ... })
  // ...and it will also hide the dialog automatically
}

</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" full-width>
      <q-card style="min-width: 300px">
        <q-card-section style="max-height: 75vh" class="scroll">
          <file-upload
            class="q-ma-md cursor-pointer"
            extensions="gif,jpg,jpeg,png,webp"
            accept="image/png,image/gif,image/jpeg,image/webp"
            name="avatar"
            post-action="/upload/post"
            drop="body"
            v-model="files"
            input-id="file_dialog"
            @input-filter="inputFilter"
            @input-file="inputFile"
            ref="upload_dialog">
              <div v-if="!image">
                <p>Drag and drop image here or click to upload image</p>
                <q-btn color="primary">Upload</q-btn>
              </div>
          </file-upload>
          <img v-if="image" :src="image" class="cursor-pointer" style="max-width: 100%; height: auto; max-height: 80vh"/>

        </q-card-section>
        <q-card-actions>
          <q-btn color="secondary" @click="onOKClick">Save</q-btn>
          <q-btn @click="onDialogCancel">Cancel</q-btn>
        </q-card-actions>
        <q-inner-loading :showing="loading" />
    </q-card>
  </q-dialog>
</template>

<style scoped>

</style>