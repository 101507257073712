import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import Component from '../components/flowsheets/AdvancedNode.vue'

export default Node.create({
  // configuration …
  name: 'AdvancedRender',

  group: 'inline',
  inline: true,
  allowGapCursor: true,
  atom: true,

  addAttributes() {
    return {
      field: {
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'advancedrender',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['advancedrender', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  },
})
