<template>
  <div>
    <q-card class="text-center q-pa-lg">
      <q-form>
        <h4 class="text-primary">Request Password Reset</h4>
        <q-input label="Email" v-model="email" outlined></q-input>
        <q-btn color="secondary" class="q-ma-md" type="submit" @click.prevent="sendEmail">Send Email</q-btn>
      </q-form>
        <q-inner-loading
        :showing="loading"
        label="Please wait..."
        label-class="text-secondary"
        label-style="font-size: 1.1em"
      />
    </q-card>
  </div>
</template>

<script>
import {useUserStore} from "../stores/user";
import {ref} from "vue";

export default {
  name: "RequestPasswordReset",
  setup(){
    return {
      email: ref(''),
      userStore: useUserStore(),
      loading: ref(false),
    }
  },
  methods: {
    sendEmail() {
      this.loading = true;
      this.userStore.CreateOTP(this.email, '/new_password').then(() => {
        this.loading = false;
        this.$router.push(`/verification?email=${encodeURIComponent(this.email)}&redirect_to=${encodeURIComponent('/new_password')}`);
      });
    }
  },
}
</script>

<style scoped>

</style>
