<template>
    <q-fab class="absolute" style="right: 10px; top: -10px;" v-if="!editing && (new_mode || edit_mode!==null)" color="primary" flat icon="close" @click="new_mode=false; edit_mode=null;"></q-fab>
    <div class="row q-pa-md">
      <div class="col-6 text-h5">
        {{ flowsheet.flowsheet_name }}
      </div>
      <div v-if="data" class="col-3">
      </div>
      <div class="col-3 text-right">
        <q-btn v-if="!new_mode && !edit_mode && !readonly" color="primary" icon="add" @click="new_entry()">New Entry</q-btn>
      </div>
    </div>

    <q-form @submit="save">
    <q-markup-table separator="vertical" class="flowsheet-table" ref="table" wrap-cells>
      <thead>
        <tr>
          <th style="max-width: 200px;"></th>
          <template v-for="d in data.times">
            <th v-if="edit_mode === d" style="min-width: 300px; max-width: 500px;">
                <PatientDateTimeField :error="u_error" error-message="Date Time entries must be unique" dense bgColor="grey-4" class="q-ma-xs" :patient="patient" v-model="d.timestamp"></PatientDateTimeField>
            </th>
            <th v-else>
                <q-avatar size="36px" color="secondary">
                  <img v-if="d.user_embed.picture" :src="d.user_embed.picture"/>
                  <span v-else>{{ d.user_embed.initials }}</span>
                  <q-tooltip class="bg-secondary text-white">
                    {{ d.user_embed.username }}
                  </q-tooltip>
                </q-avatar>
                <div class="q-ml-sm vertical-middle inline-block text-center">
                  {{ date_string(patient, d.timestamp) }}<br><span v-if="!['template', 'scene'].includes(patient.patient_type)">{{ time_string(patient, d.timestamp) }}</span>

                </div>
                <q-btn v-if="!new_mode && !edit_mode && !readonly && can_edit(d.user_embed.uid) && !d.read_only" color="grey-4" round size="sm" class="q-ma-sm" @click="edit(d)">
                  <q-icon class="text-primary" name="edit"></q-icon>
                </q-btn>
                <q-btn v-if="['template', 'scene'].includes(patient.patient_type) && !new_mode && !edit_mode && !readonly && can_edit(d.user_embed.uid) && !d.read_only" color="negative" round size="sm" class="q-ma-sm" @click="delete_data(d)">
                  <q-icon class="text-white" name="close"></q-icon>
                </q-btn>
            </th>
          </template>
          <th v-if="new_mode && newcolumn" ref="new_ele">
            <PatientDateTimeField :error="u_error" error-message="Date Time entries must be unique" dense bgColor="grey-4" :patient="patient" v-model="newcolumn.timestamp"></PatientDateTimeField>
          </th>
        </tr>
      </thead>
      <draggable v-model="flowsheet.fields" item-key="uid" group="fields" :disabled="!editing" tag="tbody" @sort="handlefields($event)">
        <template #item="{element}">
          <tr
              v-if="element.data_type === 'expansion'"
              class="bg-accent text-white text-bold"
              :class="{ 'active-row': active_field ? element.uid === active_field.uid : false}"
              @click="$emit('selected', element); element.visible = !element.visible; update_element_visibility(element)">
            <td :colspan=" 1 + data.times.length + (new_mode && newcolumn ? 1 : 0)" style="border-bottom: white 1px solid;">
              {{ element.field_name }} <q-icon :name="element.visible ? 'expand_less' : 'expand_more'"></q-icon>
            </td>
          </tr>
          <tr v-else-if="!['Advanced', 'Lookup Advanced'].includes(element.data_type)" :class="{ 'active-row': active_field ? element.uid === active_field.uid : false, 'hidden': element.visible === false ? true : false}" @click="$emit('selected', element)">
            <td style="max-width: 200px;">{{ element.field_name }}</td>
            <td v-for="d in data.times" :key="d.timestamp">
              <component :edit="edit_mode === d" outlined dense :is="lookup(element.data_type)" v-model="d[element.uid]" :patient="patient" :field="element"></component>
              <PatientHelp :element="element"></PatientHelp>
            </td>
            <td v-if="new_mode && newcolumn" style="min-width: 300px;">
              <component :edit="true" outlined dense :is="lookup(element.data_type)" v-model="newcolumn[element.uid]" :patient="patient" :field="element"></component>
              <PatientHelp :element="element"></PatientHelp>
            </td>
          </tr>
          <template v-else>
            <tr data-draggable="true" :class="{ 'active-row': active_field ? element.uid === active_field.uid : false, 'hidden': element.visible === false ? true : false}" @click="$emit('selected', element)">
              <td style="max-width: 200px;">
                <span class="text-bold">{{ element.field_name }}&nbsp;</span>
                <q-btn v-if="new_mode || edit_mode !== null" icon="add" round size="sm" color="accent" @click="start_advanced_add(element)"></q-btn>
              </td>
              <td v-for="d in data.times"></td>
              <td v-if="new_mode" style="min-width: 300px;">
              </td>
            </tr>
            <template v-for="entry in get_entries(element)">
              <tr v-for="(obj, field_id) in data[entry]">
                <td style="max-width: 200px;">
                  <template v-if="element.data_type==='Lookup Advanced'">
                    <TipTap v-if="flowsheet.lookup_data[obj.lookup.lookup_type]" v-model="flowsheet.lookup_data[obj.lookup.lookup_type].advanced_render" :edit="false" :lookup_type="flowsheet.lookup_data[obj.lookup.lookup_type]" :data="obj"></TipTap>
                    <span v-else class="text-negative">Error Finding Lookup to Render</span>
                  </template>
                  <template v-else>
                    <TipTap v-if="element.advanced_render" v-model="element.advanced_render" :lookup_type="element" :edit="false" :data="obj"></TipTap>
                    <template v-else v-for="field in get_fields(element, obj)">
                      <span class="text-bold">{{field.field_name}}:&nbsp;</span>
                      <component :edit="false" outlined dense :is="lookup(field.data_type)" v-model="obj[field.uid]" :patient="patient" :field="field"></component>
                      <br>
                    </template>
                  </template>
                  <q-btn v-if="new_mode || edit_mode && can_edit(obj.user_embed.uid) && !obj.read_only" color="grey-4" round size="sm" class="q-ma-sm" @click="open_advanced(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, obj, field_id)">
                    <q-icon class="text-primary" name="edit"></q-icon>
                  </q-btn>
                  <q-btn v-if="['template', 'scene'].includes(patient.patient_type) && (new_mode || edit_mode) && can_edit(obj.user_embed.uid) && !obj.read_only" color="negative" round size="sm" class="q-ma-sm" @click="delete_advanced(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, field_id, obj)">
                    <q-icon class="text-white" name="close"></q-icon>
                  </q-btn>
                </td>
                <td v-for="d in data.times">
                  <template
                    v-if="d[entry] && d[entry][field_id]"
                    v-for="(action_data, action_uid) in d[entry][field_id]"
                  >
                    <q-chip
                        v-if="get_action(element, obj, action_uid)"
                        :removable="edit_mode === d"
                        :clickable="get_action(element, obj, action_uid).fields.length > 0 || ![null, undefined].includes(get_action(element, obj, action_uid).flowsheet)"
                        @remove="delete_action(d, element, field_id, action_uid, obj)"
                        @click="select_action(d, element, field_id, get_action(element, obj, action_uid), obj, readonly || edit_mode !== d || obj.read_only)"
                    >
                      <!--<TipTap v-if="get_action(element, obj, action_uid).advanced_render" v-model="get_action(element, obj, action_uid).advanced_render" :lookup_type="get_action(element, obj, action_uid)" :edit="false" :data="d[element.uid][field_id][action_uid]"></TipTap>-->
                      <q-icon v-if="get_action(element, obj, action_uid).fields.length > 0 || get_action(element, obj, action_uid).flowsheet" name="comment" class="q-pr-sm"></q-icon>
                      <span>{{get_action(element, obj, action_uid).action_name}}</span>
                    </q-chip>
                  </template>
                  <q-btn-dropdown v-else-if="edit_mode === d" label="Action" color="accent">
                    <q-list dense>
                      <q-item clickable v-close-popup v-for="action in get_actions(element, obj)" @click="select_action(d, element, field_id, action, obj)">
                        <q-item-section>
                          <q-item-label>
                            {{action.action_name}}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </td>
                <td v-if="new_mode" style="min-width: 300px;">
                  <q-chip
                      clickable
                      removable
                      v-if="newcolumn[entry] && newcolumn[entry][field_id]"
                      v-for="(action_data, action_uid) in newcolumn[entry][field_id]"
                      @remove="delete_action(newcolumn, element, field_id, action_uid, obj)"
                      @click="select_action(newcolumn, element, field_id, get_action(element, obj, action_uid), obj, false)"
                  >
                    <!--<TipTap v-if="get_action(element, obj, action_uid).advanced_render" v-model="get_action(element, obj, action_uid).advanced_render" :lookup_type="get_action(element, obj, action_uid)" :edit="false" :data="newcolumn[element.uid][field_id][action_uid]"></TipTap>-->
                    <q-icon v-if="get_action(element, obj, action_uid).fields.length > 0" name="comment" class="q-pr-sm"></q-icon>
                    <span>{{get_action(element, obj, action_uid).action_name}}</span>
                  </q-chip>
                  <q-btn-dropdown v-else label="Action" color="accent">
                    <q-list dense>
                      <q-item clickable v-close-popup v-for="action in get_actions(element, obj)" @click="select_action(newcolumn, element, field_id, action, obj)">
                        <q-item-section>
                          <q-item-label>
                            {{action.action_name}}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                  <PatientHelp :element="element"></PatientHelp>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </draggable>
    </q-markup-table>
    <q-page-sticky class="q-ma-md" style="z-index: 2">
      <q-fab v-if="!editing && (new_mode || edit_mode!==null)" color="secondary" label="Save" icon="save" type="submit"></q-fab>
    </q-page-sticky>
    </q-form>
    <q-dialog v-model="confirm_delete" persistent>
      <q-card>
      <q-card-section>
        Are you sure you want to delete the date: {{ date_time_string(patient, delete_holder.timestamp) }}
      </q-card-section>
      <q-card-actions>
        <q-btn class="q-ma-sm" @click="confirm_delete=false">Cancel</q-btn>
        <q-btn class="q-ma-sm" color="negative" @click="delete_confirmed()">Delete</q-btn>
      </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import NumericField from "./NumericField.vue";
import PatientDateTimeField from "../PatientDateTimeField.vue";
import { usePatientData } from "../../composables/patient_data";
import {ref, toRefs, toRef, reactive, onMounted, watchEffect, nextTick, provide} from "vue";
import { useUserStore } from "../../stores/user";
import { epoch_offset_to_datetime, date_time_string, datetime_to_epoch_offset, date_string, time_string} from "../../util";
import AdvancedForm from "./AdvancedForm.vue";
import TipTap from "../TipTap.vue";
import PatientHelp from "./PatientHelp.vue";

export default {
  name: "Grid",
  emits: ["selected"],
  props: ["flowsheet", "editing", "active_field", "data", "new_mode"],
  components: {
    PatientHelp,
    TipTap,
    AdvancedForm,
    PatientDateTimeField,
    draggable
  },
  setup(props) {
    const {
        patient,
        newcolumn,
        advanced_obj,
        advanced_element,
        new_ele,
        new_mode,
        edit_mode,
        readonly,
        patientStore,
        userStore,
        viewing_visit,
        start_advanced_add,
        new_entry,
        save,
        edit,
        select_action,
        lookup,
        can_edit,
        get_fields,
        get_actions,
        u_error,
        delete_data,
        confirm_delete,
        delete_holder,
        delete_confirmed,
        open_advanced,
        delete_advanced,
        delete_action,
        get_action,
        get_entries,
        reset_data
    } = usePatientData(props);

    provide('flowsheet', toRef(props, 'flowsheet'));
    provide('patient', patient);

    watchEffect(() => {
      for(let fieldIndex in props.flowsheet.fields) {
        let field = props.flowsheet.fields[fieldIndex];
        if(field.data_type === "expansion") {
          field.visible = false;
          nextTick(() => {
            update_element_visibility(field);
          })
        }
      }
    });

    const update_element_visibility = function(element){
      let marking = false;
      for(let i=0; i<props.flowsheet.fields.length; i++){
        if(props.flowsheet.fields[i].uid === element.uid){
          marking = true;
          continue;
        }
        if(marking===true){
          if(props.flowsheet.fields[i].data_type === 'expansion'){
            marking = false;
            break;
          }
          props.flowsheet.fields[i].visible = element.visible;
        }
      }

    }

    return {
      handlefields(evt){
        console.log(evt);
        console.log(props.flowsheet.fields[evt.newIndex])
        if(props.flowsheet.fields[evt.newIndex].entity_type==='grouping'){
          update_element_visibility(props.flowsheet.fields[evt.newIndex])
        }
      },
      update_element_visibility,
      u_error,
      patient,
      new_entry,
      new_ele,
      date_string,
      time_string,
      date_time_string,
      new_mode,
      edit_mode,
      patientStore,
      start_advanced_add,
      userStore,
      newcolumn,
      advanced_obj,
      advanced_element,
      save,
      edit,
      select_action,
      lookup,
      can_edit,
      get_fields,
      get_actions,
      get_action,
      delete_data,
      confirm_delete,
      delete_holder,
      delete_confirmed,
      readonly,
      delete_advanced,
      delete_action,
      open_advanced,
      get_entries,
      reset_data
    }
  },
  watch: {
    "flowsheet" : function(){
      this.new_mode = false;
      this.edit_mode = null;
    }
  }
}
</script>

<style lang="scss">
  /*.flowsheet-table tbody tr:nth-child(even) td:first-child{
    background-color: #ffffff;
  }*/

  .active-row {
    background-color: $positive !important;
  }

  .flowsheet-table tr td:first-child{
    position: sticky;
    z-index: 1;
    left: 0;
  }

  .flowsheet-table tr th:first-child{
    position: sticky;
    z-index: 2;
    left: 0;
  }

  th .q-field--error .q-field__bottom{
    color: #ee929c !important;
  }
</style>
