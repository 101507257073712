<template>
    <q-card>
      <q-card-section>
        <q-table
            title="Activities"
            style="height: calc(100vh - 140px);"
            :columns="columns" :rows="activities" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            v-model:pagination="pagination"
            @request="onRequest"
            :grid="$q.screen.xs"
            flat
            >
          <template v-slot:top-left>
            <div>
              <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense @click="router.back()"/>
              <div class="row text-h5 text-primary q-mb-md">Activities</div>
              <div class="row">
                <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>
          </template>
          <template v-slot:body-cell-patients="props">
            <q-td>
              <q-chip size="sm" color="secondary" class="text-white" v-for="patient in props.row.template_patients">{{ patient.name }}</q-chip>
            </q-td>
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td>
              <q-btn class="q-ma-sm" rounded color="accent" :to="`/student/course/${props.row.course_uid}/activity/${props.row.uid}`" size="sm">View Activity</q-btn>
            </q-td>
          </template>
          <template v-slot:item="props">
            <q-card style="width: 100%" class="q-ma-md">
              <q-card-section>
                <div class="text-h6">{{ props.row.activity_name }}</div>
                <div class="text-caption" v-if="props.row.activity_end_date">Due: {{ date.formatDate(props.row.activity_end_date, 'D MMM YYYY HH:mm') }}</div>
              </q-card-section>
              <q-card-section>
                <q-chip size="sm" color="secondary" class="text-white" v-for="patient in props.row.template_patients">{{ patient.name }}</q-chip>
              </q-card-section>
              <q-card-actions>
                <q-btn class="q-ma-sm" rounded color="accent" :to="`/student/course/${props.row.course_uid}/activity/${props.row.uid}`" size="sm">View Activity</q-btn>
              </q-card-actions>
            </q-card>
          </template>
          <template v-slot:loading>
              <q-inner-loading showing color="primary" />
          </template>
        </q-table>
      </q-card-section>
    </q-card>
    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />

</template>

<script>
import { useRouter} from "vue-router";
import {useUserStore} from "../../stores/user";
import {useRegionStore} from "../../stores/region";
import {storeToRefs} from "pinia";
import {onMounted, ref} from "vue";
import { date } from 'quasar';

export default {
  name: "StudentActivites",
  props: ['course_uid'],
  setup(props){
    const router = useRouter();
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore)
    const activities = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'activity_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest() {
        const { page, rowsPerPage, sortBy, descending } = pagination
        loading.value = true

        regionStore.GetActivities(
            props.course_uid,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter.value).then(data => {
          if(typeof  data === 'string'){
            loading.value = false
            return
          }
          pagination.value.rowsNumber = data.total
          activities.value.splice(0, activities.value.length, ...data.activities);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

    return {
      columns: [
        {
          name: 'activity_name',
          label: 'Activity Name',
          field: 'activity_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'patients',
          label: 'Patients',
          field: 'template_patients',
          align: 'left',
          sortable: false,
        },
        {
          name: 'due_date',
          label: 'Due Date',
          field: 'activity_end_date',
          align: 'left',
          sortable: true,
          format: (val, row) => date.formatDate(val, 'DD MMM YYYY HH:mm' ),
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      org,
      activities,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      onRequest,
      router
    }
  }
}
</script>

<style scoped>

</style>
