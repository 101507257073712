<template>
  <div>
    <q-form>
      <q-card class="text-center q-pa-lg">
        <h4 class="text-primary">Password Reset</h4>
        <p>Enter your new password below</p>
        <q-input class="q-ma-md" label="Password" v-model="password" outlined type="password"></q-input>
        <q-input class="q-ma-md" label="Confirm Password" v-model="confirm_password" outlined type="password"
          :rules="[ val => val === password || `Passwords do not match`]"
        ></q-input>
        <q-btn color="secondary" type="submit" @click.prevent="onSubmit">OK</q-btn>
        <q-inner-loading
        :showing="loading"
        label="Please wait..."
        label-class="text-secondary"
        label-style="font-size: 1.1em"
      />
      </q-card>
    </q-form>
  </div>

</template>

<script>
import {useUserStore} from "../stores/user";
import {ref} from "vue";

export default {
  name: "NewPassword",
  props: ['emit', 'user_uid'],
  setup(){
    return{ userStore: useUserStore(), loading: ref(false), password: ref(""), confirm_password: ref("") }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.userStore.NewPassword(this.password, this.confirm_password, this.user_uid).then(() => {
        this.loading = false;
        if(this.emit !==undefined) {
          this.$emit('close');
        }
        else {
          this.$router.push(this.userStore.default_route);
        }
      }).catch(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
