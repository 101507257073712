<template>
  <q-table
      class="q-pa-md"
      title="Courses"
      style="height: calc(100vh - 120px);"
      :columns="columns" :rows="courses" row-key="uid"
      :visible-columns="visibleColumns"
        separator="vertical" :loading="loading" :filter="filter"
      v-model:pagination="pagination"
      @request="onRequest"
      :grid="$q.screen.xs"
      ref="table"
      >
    <template v-if="!loading" v-slot:no-data="{ icon, message, filter }">
      <div class="full-width row flex-center q-gutter-sm">
        <div class="block">
          <p>Click <span class="text-bold">+New Course</span> to create a course for your Students.</p>
          <p>For more information, click here for our <a href="https://help.chartflow.io/articles/courses" target="_blank">Courses Guide</a></p>
        </div>
      </div>
    </template>
    <template v-slot:top-left>
      <div>
        <div class="row text-h5 text-primary q-mb-md">Courses</div>
        <div class="row">
          <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <q-checkbox v-model="show_old" @update:modelValue="table.requestServerInteraction()">Show Completed Courses</q-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:top-right>
      <q-btn-dropdown color="primary" label="Add Course" class="q-mr-md">
        <q-list>
          <q-item clickable v-close-popup @click="new_course">
            <q-item-section>
              <q-item-label>New Course</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="add_course">
            <q-item-section>
              <q-item-label>Course From Template</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </template>
    <template v-slot:body-cell-course_name="props">
      <q-td>
        <span class=" text-bold text-primary">{{ props.row.course_short_name }}:</span><br>
        <span>{{ props.value }}</span>
      </q-td>
    </template>
    <template v-slot:body-cell-campus="props">
      <q-td>
        <span v-if="props.row.campus_embed" class="text-bold text-primary">{{ props.row.campus_embed.campus_name }}</span><br>
        <span v-if="props.row.campus_embed">{{ props.row.campus_embed.city }}, {{ props.row.campus_embed.state }} {{ props.row.campus_embed.country }}</span>
      </q-td>
    </template>
    <template v-slot:body-cell-program="props">
      <q-td>
        <span v-if="props.row.program_embed">{{ props.row.program_embed.program_name }}</span>
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props">
      <q-td>
        <q-btn class="q-ma-sm" rounded color="primary" @click="select_course(props.row)" size="sm">Manage Course</q-btn>
      </q-td>
    </template>
    <template v-slot:loading>
        <q-inner-loading showing color="primary" />
    </template>
    <template v-slot:item="props">
      <q-card style="width: 100%" class="q-ma-md">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">{{ props.row.course_short_name }}</div>
          <div >{{ props.row.course_name }}</div>
          <div class="text-subtitle2"><span class="text-bold">Course Code: </span>{{ props.row.course_code }}</div>
        </q-card-section>
        <q-card-section>
          <div class="row" v-if="props.row.campus_embed">
            <div class="col">
              <div class="text-bold">Campus</div>
              <div class="text-subtitle2">{{ props.row.campus_embed.campus_name }}</div>
              <div class="text-subtitle2">{{ props.row.campus_embed.city }}, {{ props.row.campus_embed.state }} {{ props.row.campus_embed.country }}</div>
            </div>
          </div>
          <div class="row" v-if="props.row.program_embed">
            <div class="col">
              <div class="text-bold">Program</div>
              <div class="text-subtitle2">{{ props.row.program_embed.program_name }}</div>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col">
              <div class="text-bold">End Date</div>
              <div class="text-subtitle2">{{ date.formatDate(props.row.end_date, 'MM/DD/YYYY') }}</div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions>
          <q-btn class="q-ma-sm" rounded color="primary" @click="select_course(props.row)" size="sm">Manage Course</q-btn>
        </q-card-actions>
      </q-card>
    </template>
  </q-table>

</template>

<script>
import { ref, reactive, onMounted, nextTick, watch } from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore } from "../stores/region";
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import {date, useQuasar} from 'quasar'
import TemplateCourseSelector from "@/components/TemplateCourseSelector.vue";

export default {
  name: "Courses",
  props: ['organization_uid', 'program_uid', 'campus_uid'],
  setup(props){
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { user } = storeToRefs(userStore);
    const { org, course } = storeToRefs(regionStore)
    const organization_uid = props.organization_uid || org.value.uid;
    const table = ref(null);
    const show_old = ref(false);
    const courses = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const router = useRouter();
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'course_name',
        descending: false,
    })

    const $q = useQuasar();

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(p) {
        const { page, rowsPerPage, sortBy, descending } = p.pagination
        const filter = p.filter
        loading.value = true

        regionStore.GetCourses(
            organization_uid,
            props.program_uid,
            props.campus_uid,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter,
            show_old.value
        ).then(data => {

          pagination.value.rowsNumber = data.total
          courses.value.splice(0, courses.value.length, ...data.courses);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

    const visibleColumns = ref(['course_name', 'end_date', 'course_code', 'actions']);
    if(!props.program_uid) visibleColumns.value.push('program');
    if(!props.campus_uid) visibleColumns.value.push('campus');

    return {
      columns: [
        {
          name: 'course_name',
          label: 'Course Name',
          field: 'course_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'campus',
          label: 'Campus',
          field: 'campus_embed',
          align: 'left',
          sortable: true,
        },
        {
          name: 'program',
          label: 'Program',
          field: 'program_embed',
          align: 'left',
          sortable: true,
        },
        {
          name: 'end_date',
          label: 'End Date',
          field: 'end_date',
          align: 'left',
          sortable: true,
          format: (val, row) => date.formatDate(val, 'DD MMM YYYY HH:mm' )
        },
        {
          name: 'course_code',
          label: 'Course Code',
          field: 'course_code',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      table,
      show_old,
      visibleColumns,
      org,
      courses,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      onRequest,
      date,
      add_course() {
          $q.dialog({
            component: TemplateCourseSelector,

            // props forwarded to your custom component
            componentProps: {

              // ...more..props...
            }
          }).onOk((sel_course) => {
            console.log('OK');
            console.log(sel_course);
            loading.value = true;
            regionStore.AddCourse(sel_course).then(data => {
              loading.value = false;
              if(data.course){
                courses.value.push(data.course);
              }
              course.value = data.course
              if(data.user){
                user.value = data.user;
              }
              router.push(`/course/${data.course.uid}?added=true`)
            })

          }).onCancel(() => {
            console.log('Cancel')
          }).onDismiss(() => {
            console.log('Called on OK or Cancel')
          })
      }

    }
  },
  methods:{
    select_course(course){
      this.$router.push(`/course/${course.uid}`)
    },
    new_course(){
      this.$router.push({path: '/course_new',
        query: {
          organization_uid: this.organization_uid,
          program_uid: this.program_uid,
          campus_uid: this.campus_uid,
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
