import {defineStore, acceptHMRUpdate, storeToRefs} from 'pinia'
import axios from "axios";
import {useRegionStore} from "./region";
import {intercept, error_intercept} from "./restapi";
import {debounce, Notify} from "quasar";
import {ref, watch} from "vue";
import { useRouter } from "vue-router";

axios.defaults.withCredentials = true

const default_api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
default_api.interceptors.response.use(intercept, error_intercept);

export const useFlowsheetStore = defineStore('flowsheet', {
    state: () => {
        return {
            flowsheet: null,
            active_field: null,
            flowsheets: null,
            load: false
        }
    },
    actions: {
        async api(){
            try{
                const regionStore = useRegionStore();
                return await regionStore.api();
            }
            catch(e){
                if(e.message === "No org set") {
                    return default_api;
                }
                throw e;
            }
        },
        async SaveFlowsheet(flowsheet){
            this.flowsheet = flowsheet
            const api = await this.api();
            const response = await api.post(`/flowsheet`, {flowsheet: flowsheet})
            this.$patch({load: true})
            //this.$patch({flowsheet: response.data.flowsheet, load: true})

            return response.data.flowsheet
        },
        async SaveField(field){
            const api = await this.api();
            const response = await api.post(`/field`, {field: field})
            return response.data;
        },
        async GetFlowsheet(id, version, from){
            const api = await this.api();
            const response = await api.get(`/flowsheet/${id}`, {params: {version: version, from: from}})
            this.$patch({flowsheet: response.data.flowsheet, load: true})
        },
        async DeleteFlowsheet(flowsheet, version){
            const api = await this.api();
            const response = await api.delete(`/flowsheet/${flowsheet.uid}`, {params: {version: version}})
            Notify.create({
              color: 'info',
              message: `Flowsheet ${flowsheet.flowsheet_name} deleted successfully`,
              icon: 'delete'
            })

        },

        async GetFlowsheets(page, rowsPerPage, sortBy, descending, filter, version) {
            const api = await this.api();
            const response = await api.get(`/flowsheets`, {params:
                    {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, version: version}});
            if (response.status === 200) {
                return response.data;
            }
        },
        async GetAvailableFields(page, rowsPerPage, sortBy, descending, filter, version) {
            const api = await this.api();
            const response = await api.get(`/fields`, {params:
                    {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, version}});
            if (response.status === 200) {
                return response.data;
            }
        },
        async GetCharts(page, rowsPerPage, sortBy, descending, filter, course_uid, activity_uid, version) {
            const api = await this.api();
            const response = await api.get(`/charts`, {params:
                    {query: filter,
                     page: page,
                     rowsPerPage: rowsPerPage,
                     sortBy: sortBy,
                     descending: descending,
                     course_uid: course_uid,
                     activity_uid: activity_uid,
                     version: version
                    }});
            if (response.status === 200) {
                return response.data;
            }
        },
        async GetAllCharts(page, rowsPerPage, sortBy, descending, filter, version) {
            const api = await this.api();
            const response = await api.get(`/all_charts`, {params:
                    {query: filter,
                     page: page,
                     rowsPerPage: rowsPerPage,
                     sortBy: sortBy,
                     descending: descending,
                     version: version
                    }});
            if (response.status === 200) {
                return response.data;
            }
        },
        async GetLookupTypes(page, rowsPerPage, sortBy, descending, filter, version, lookup_types) {
            const api = await this.api();
            const response = await api.get(`/lookups`, {params:
                    {query: filter,
                     page: page,
                     rowsPerPage: rowsPerPage,
                     sortBy: sortBy,
                     descending: descending,
                     version: version,
                     lookup_types: lookup_types
                    }});
            if (response.status === 200) {
                return response.data;
            }
        },
        async GetLookupTypesForVersion(page, rowsPerPage, sortBy, descending, filter, version, lookup_types) {
            const api = await this.api();
            const response = await api.get(`/lookups/version`, {params:
                    {query: filter,
                     page: page,
                     rowsPerPage: rowsPerPage,
                     sortBy: sortBy,
                     descending: descending,
                     version: version,
                     lookup_types: lookup_types
                    }});
            if (response.status === 200) {
                return response.data;
            }
        },
        async GetLookupType(version, flowsheet_uid, lookup_type_uid,from) {
            const api = await this.api();
            const response = await api.get(`/lookup_type/${version}/${flowsheet_uid}/${lookup_type_uid}`, {params: {from: from}});
            if (response.status === 200) {
                return response.data;
            }
        },
        async SaveLookupType(lookup_type) {
            const api = await this.api();
            const response = await api.post(`/lookup_type`, {lookup_type: lookup_type});
            return response.data;
        },
        async GetLookups(lookup_type, page, rowsPerPage, sortBy, descending, filter, course_uid, activity_uid, version) {
            const api = await this.api();
            const response = await api.get(`/lookups/${lookup_type}`, {params:
                    {query: filter,
                     page: page,
                     rowsPerPage: rowsPerPage,
                     sortBy: sortBy,
                     descending: descending,
                     course_uid: course_uid,
                     activity_uid: activity_uid,
                     version: version
                    }});
            if (response.status === 200) {
                return response.data;
            }
        },
        async SaveLookup(lookup) {
            const api = await this.api();
            const response = await api.post(`/lookup`, {lookup: lookup});
            return response.data;
        },
        async DeleteLookup(lookup) {
            const api = await this.api();
            const response = await api.delete(`/lookup/${lookup.lookup_type}/${lookup.uid}`);
            return response.data;
        },
        async GetVersions(page, rowsPerPage, sortBy, descending, filter) {
            const api = await this.api();
            const response = await api.get(`/versions`, {params:
                    {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});
            if (response.status === 200) {
                return response.data;
            }
        },
        async SaveVersion(version){
            const api = await this.api();
            const response = await api.post(`/version`, {version: version})
            return response.data;
        },
        async DeleteVersion(version){
            const api = await this.api();
            const response = await api.delete(`/version/${version.uid}`)
            Notify.create({
              color: 'info',
              message: `Version ${version.version_name} deleted successfully`,
              icon: 'delete'
            })

        },
        async SaveChart(chart){
            const api = await this.api();
            const response = await api.post(`/chart`, {chart: chart})
            Notify.create({
              color: 'positive',
              message: `Saved Chart successfully`,
              icon: 'save'
            })


            return response.data;
        },
        async GetVersion(id){
            const api = await this.api();
            const response = await api.get(`/version/${id}`)
            return response.data;
        },
        async GetChart(id, version, from){
            const api = await this.api();
            const response = await api.get(`/chart/${id}`, {params: {version: version, from: from}})
            return response.data;
        },
        async DeleteChart(chart, version){
            const api = await this.api();
            const response = await api.delete(`/chart/${chart.uid}`, {params: {version: version}})
            Notify.create({
              color: 'info',
              message: `Visit Type ${chart.chart_name} deleted successfully`,
              icon: 'delete'
            })

        },

        async PublishVersion(version){
            const api = await this.api();
            const response = await api.post(`/version/${version}/publish`)
            Notify.create({
              color: 'positive',
              message: `Published Version successfully`,
              icon: 'save'
            })


            return response.data;
        },
        async SaveData(data){
            const api = await this.api();
            const response = await api.post(`/patient/${data.patient_id}`, {data: data})
            return response.data;
        }
    }
})

export function useFlowsheetWriter(){
    const router = useRouter();
    const flowsheetStore = useFlowsheetStore();
    const {flowsheet} = storeToRefs(flowsheetStore);
    const issaving = ref(false);
    const loading = ref(false);
    const saved = ref(0);
    const unsaved = ref(0);

    function save_flowsheet() {
      saved.value = unsaved.value;
      issaving.value = true;
      flowsheetStore.SaveFlowsheet(flowsheet.value).then(f => {
        if(flowsheet.value.uid !== f.uid){
          flowsheet.value.uid = f.uid;
          router.replace(`/flowsheet_builder/${flowsheet.value.version}/${flowsheet.value.uid}`);
        }
        issaving.value = false;
        if(unsaved.value > saved.value) {
          save_flowsheet();
        }
      });
    }
    save_flowsheet = debounce(save_flowsheet, 1000);

    watch(flowsheet, (newValue, oldValue) => {
      // fires on nested property mutations
      // Note: `newValue` will be equal to `oldValue` here
      // because they both point to the same object!
        console.log('Flowsheet Watch Fired')
        if (flowsheet.value) {
          if (!loading.value) {
            unsaved.value++;
            if (!issaving.value) {
              save_flowsheet();
            }
          }
        }
    },         {
      deep: true
    });

    return {
        loading,
        flowsheetStore,
        save_flowsheet,
        issaving,
        saved,
        unsaved
    }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFlowsheetStore, import.meta.hot))
}
