<template>
        <Versioned @selected="versioned = $event"></versioned>
        <q-table
            class="q-pa-md"
            title="Flowsheets"
            style="height: calc(100vh - 120px);"
            :columns="columns" :rows="flowsheets" row-key="uid"
              separator="vertical" :loading="loading" :filter="filter"
            v-model:pagination="pagination"
            @request="onRequest"
            :grid="$q.screen.xs"
            >
          <template v-slot:header="props">
              <q-tr :props="props">
                <q-th auto-width />
                <q-th
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                >
                  {{ col.label }}
                </q-th>
              </q-tr>
            </template>
          <template v-slot:body="props">
            <q-tr :style="props.rowIndex % 2 ? 'background-color: white;' : 'background-color: #eeeeee'" :props="props">
              <q-td auto-width>
                <q-btn v-if="props.row.versions.length > 1" size="sm" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'" />
              </q-td>
              <template v-for="col in props.cols" :key="col.name">
                <q-td v-if="col.name === 'actions'" auto-width>
                  <q-btn v-if="versioned && versioned.uid === props.row.version && versioned.uid!=='published'" class="q-ma-sm" rounded color="primary" @click="edit_flowsheet(props.row)" size="sm">Edit Flowsheet</q-btn>
                  <q-btn v-else-if="versioned && versioned.uid !== 'published'" class="q-ma-sm" rounded color="primary" @click="copyedit_flowsheet(props.row)" size="sm">Copy and Edit</q-btn>
                </q-td>
                <q-td v-else-if="col.name === 'flowsheet_name'">
                  <span>
                    {{ col.value }}
                    <q-tooltip>
                      {{ props.row.uid }}
                    </q-tooltip>
                  </span>
                </q-td>
                <q-td v-else>
                  {{ col.value }}
                </q-td>
              </template>
            </q-tr>
            <template v-for="row in props.row.versions">
              <q-tr v-if="row.version !== props.row.version" v-show="props.expand" :props="props">
                <q-td colspan="4">
                </q-td>
                <q-td>
                  {{ row.version_name}}
                </q-td>
                <q-td></q-td>
                <q-td auto-width>
                  <q-btn v-if="versioned && versioned.uid === row.version && versioned.uid!=='published'" class="q-ma-sm" rounded color="primary" @click="edit_flowsheet(row)" size="sm">Edit Flowsheet</q-btn>
                  <q-btn v-else-if="versioned && versioned.uid !== 'published' && props.row.version !== versioned.uid" class="q-ma-sm" rounded color="primary" @click="copyedit_flowsheet(row)" size="sm">Copy and Edit</q-btn>
                </q-td>
              </q-tr>
            </template>
          </template>
          <template v-slot:top-left>
            <div>
              <div class="row text-h5 text-primary q-mb-md">Flowsheets</div>
              <div class="row">
                <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>
          </template>
          <template v-slot:top-right>
            <q-btn :disable="!versioned || versioned.uid==='published'" color="primary" icon="add" label="New Flowsheet" @click="new_flowsheet">
            </q-btn>
          </template>
          <template v-slot:body-cell-actions="props">
          </template>
          <template v-slot:loading>
              <q-inner-loading showing color="primary" />
          </template>
        </q-table>
</template>

<script>
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";
import {useFlowsheetStore} from "../stores/flowsheet";
import {storeToRefs} from "pinia";
import {onMounted, ref, watch} from "vue";
import Versioned from "../components/Versioned.vue";

export default {
  name: "Flowsheets",
  components: {
    Versioned
  },
  setup(){
    const versioned = ref(null);
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const flowsheetStore = useFlowsheetStore();
    const { org } = storeToRefs(regionStore)
    const flowsheets = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'flowsheet_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    watch(() => versioned.value, () => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    })

    function onRequest(props) {
        if(!versioned.value) return
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        flowsheetStore.GetFlowsheets(
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter,
            versioned.value.uid
        ).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            flowsheets.value.splice(0, flowsheets.value.length, ...data.flowsheets);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

    return {
      columns: [
        {
          name: 'flowsheet_name',
          label: 'Flowsheet Name',
          field: 'flowsheet_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'flowsheet_context',
          label: 'Flowsheet Context',
          field: 'flowsheet_context',
          align: 'left',
          sortable: true,
        },
        {
          name: 'flowsheet_type',
          label: 'Layout Type',
          field: 'flowsheet_type',
          align: 'left',
          sortable: true,
        },
        {
          name: 'version',
          label: 'Version',
          field: 'version_name',
          align: 'left',
          sortable: false,
        },
        {
          name: 'published_version',
          label: 'Published Version',
          field: 'published_version',
          align: 'left',
          sortable: false,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      versioned,
      org,
      flowsheets,
      flowsheetStore,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      onRequest,
    }
  },
  methods:{
    edit_flowsheet(flowsheet){
      this.flowsheetStore.$reset();
      this.$router.push(`/flowsheet_builder/${flowsheet.version}/${flowsheet.uid}`);
    },
    copyedit_flowsheet(flowsheet){
      this.flowsheetStore.$reset();
      this.$router.push(`/flowsheet_builder/${this.versioned.uid}/${flowsheet.uid}?from=${flowsheet.version}`);
    },
    new_flowsheet(){
      this.flowsheetStore.$reset();
      this.$router.push(`/new_flowsheet?version=${this.versioned.uid}`);
    },
  }
}
</script>

