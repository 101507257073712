<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card style="min-width: 300px">
      <q-card-section class="text-center">
          <q-btn class="absolute-top-right" icon="close" flat round dense @click="onCancelClick()"></q-btn>
        <span class="text-h5">Select a patient</span>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="row q-mb-md">
          <q-btn-toggle class="block" v-model="filtering" :options="filter_options"/>
        </div>
        <q-input outlined dense debounce="600" v-model="filter" placeholder="Search">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <PatientBox
            class="q-mt-md"
            v-for="patient in templates"
            :key="patient.uid"
            :patient="patient"
            preview
            add
            @add="onPatientSelect(patient)">
        </PatientBox>
      </q-card-section>
      <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
      </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, uid } from 'quasar'
import {onMounted, ref, watch} from 'vue'
import { useUserStore } from "../stores/user";
import { usePatientStore } from "../stores/patient";
import PatientBox from "./PatientBox.vue";
import {storeToRefs} from "pinia";
import {useRegionStore} from "../stores/region";

export default {
  name: "UserSelector",
  components: {PatientBox},
  props: ['organization_uid', 'campus', 'program', 'course_uid', 'role'],

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],

  setup (props) {
    // REQUIRED; must be called inside of setup()
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const filter = ref('');
    const page = ref(1);
    const rowsPerPage = ref(10);
    const loading = ref(false);
    const users = ref([]);
    const use_organization = ref(false);
    const { org } = storeToRefs(regionStore)
    const patientStore = usePatientStore()
    const templates = ref([]);
    const filtering = ref('all');
    const filter_options = ref( [])
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
      filter_options.value = [
        {label: 'All', value: 'all'},
        {label: 'System', value: 'system'},
      ]
      if(org.value){
        filter_options.value.push({label: 'Organization', value: 'organization'})
      }
      if(org.value && userStore.programs_for_organization(org.value.uid).length > 0){
        filter_options.value.push({label: 'Program', value: 'program'})
      }
      filter_options.value.push({label: 'My Patients', value: 'mine'})

    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        patientStore.GetTemplatePatients(
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter,
            filtering.value).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            templates.value.splice(0, templates.value.length, ...data.templates);

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

    watch(filtering, () => {
      onRequest({
        pagination: pagination.value,
        filter: filter.value
      })
    })

    watch(filter, (newVal) => {
      onRequest({
        pagination: pagination.value,
        filter: newVal
      })
    })

    return {
      onPatientSelect(patient) {
        onDialogOK(patient)
      },
      onRequest,
      templates,
      use_organization,
      users,
      filter,
      filtering,
      filter_options,
      loading,
      pagination,
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick () {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK()
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel
    }
  }
}
</script>

<style scoped>

</style>
