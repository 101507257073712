import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from "axios";
import {intercept, error_intercept} from "./restapi";
import {Notify} from "quasar";
import router from "../router";

axios.defaults.withCredentials = true

const default_api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
default_api.interceptors.response.use(intercept, error_intercept);

export const useRegionStore = defineStore('region', {
    state: () => {
        return {
            org: null,
            api_instance: null,
            api_region: null,
            session_promise: null,
            org_promise: null,
            activity: null,
            course: null
        }
    },
    actions: {
        async session(){
            try {
                const response = await default_api.get('/user_session');
                console.log(response.data.session)
                if (response.status === 200) {
                    if (response.data.session.organization) {
                        this.org = response.data.session.organization;
                    }
                    return {
                        user: response.data.session.user,
                        organization: response.data.session.organization
                    }
                }
            }
            catch(error) {
                console.log(error);
            }
        },
        async api(){
            const s = await this.session_promise;
            const o = await this.org_promise
            if(!this.org) {
                router.push('/signup');
            }
            if(!this.api_instance || this.org.region !== this.api_region) {
                this.api_region = this.org.region;
                this.api_instance = axios.create({
                    baseURL: `https://api.${this.org.region}.${import.meta.env.VITE_STAGE}.chartflow.io`,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.api_instance.interceptors.response.use(intercept, error_intercept);
            }
            return this.api_instance;
        },
        async GetOrg(uid) {
            const response = await default_api.get(`/organization/${uid}`);
            if (response.status === 200) {
                return response.data.organization;
            }
        },
        async GetOrganization(uid){
            this.org_promise = this.GetOrg(uid);
            this.org = await this.org_promise;
        },
        async UpdateOrganization(org){
            this.org = org
            const api = await this.api();
            const response = await api.post(`/organization`, {organization: org})
            this.org = response.data.organization
            Notify.create({
              color: 'positive',
              message: `Organization ${org.name} updated successfully`,
              icon: 'save'
            })

        },
        async GetPurchases(org){
            const response = await default_api.get(`/organization/${org.uid}/purchases`);
            if (response.status === 200) {
                return response.data;
            }
        },
        async MakePurchase(org, purchase){
            const api = await this.api();
            const response = await api.post(`/organization/${org.uid}/purchase`, {purchase: purchase})
            Notify.create({
              color: 'info',
              message: `Purchase for ${org.name} `,
              icon: 'shopping_cart'
            })
        },
        async DeleteOrganization(org){
            const api = await this.api();
            const response = await api.delete(`/organization/${org.uid}`)
            Notify.create({
              color: 'info',
              message: `Organization ${org.name} deleted successfully`,
              icon: 'delete'
            })

        },
        async GetOrgLoginOptions(subdomain){
            const response = await default_api.get(`/organization/${subdomain}/login_options`);
            if (response.status === 200) {
                return response.data;
            }
        },
        async ParseSAML(metaddata_url){
            const response = await default_api.post(`/parse_saml_metadata`, {metadata_url: metaddata_url});
            if (response.status === 200) {
                return response.data;
            }
        },
        async UpdateProgram(program){
            const api = await this.api();
            const response = await api.post(`/organization/${this.org.uid}/program`, {program: program})
            Notify.create({
              color: 'positive',
              message: `Program ${response.data.program.program_name} updated successfully`,
              icon: 'save'
            })
            return response.data.program;
        },
        async DeleteProgram(program){
            const api = await this.api();
            const response = await api.delete(`/organization/${this.org.uid}/program/${program.uid}`)
            Notify.create({
              color: 'info',
              message: `Program ${program.program_name} deleted successfully`,
              icon: 'delete'
            })

        },
        async UpdateCampus(campus){
            const api = await this.api();
            const response = await api.post(`/organization/${this.org.uid}/campus`, {campus: campus})
            Notify.create({
              color: 'positive',
              message: `Campus ${response.data.campus.campus_name} updated successfully`,
              icon: 'save'
            })
            return response.data.campus;
        },
        async DeleteCampus(campus){
            const api = await this.api();
            const response = await api.delete(`/organization/${this.org.uid}/campus/${campus.uid}`)
            Notify.create({
              color: 'info',
              message: `Campus ${campus.campus_name} deleted successfully`,
              icon: 'delete'
            })

        },
        async GetProgram(program_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/organization/${this.org.uid}/program/${program_uid}`);
                if (response.status === 200) {
                    return response.data.program;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetCampus(campus_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/organization/${this.org.uid}/campus/${campus_uid}`);
                if (response.status === 200) {
                    return response.data.campus;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetCampuses(page, rowsPerPage, sortBy, descending, filter) {
            try {
                const api = await this.api();
                const response = await api.get(`/organization/${this.org.uid}/campuses`, {params:
                        {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});
                if (response.status === 200) {
                    this.org.campuses = response.data.campuses;
                    return response.data.campuses;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetPrograms(page, rowsPerPage, sortBy, descending, filter) {
            try {
                const api = await this.api();
                const response = await api.get(`/organization/${this.org.uid}/programs`, {params:
                        {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});
                if (response.status === 200) {
                    this.org.programs = response.data.programs;
                    return response.data.programs;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetCourses(organization, program_uid, campus_uid, page, rowsPerPage, sortBy, descending, filter, show_old) {
            try{
                const api = await this.api();
                const response = await api.get(`/courses`, {params:
                        {
                            organization_uid: organization,
                            campus_uid: campus_uid,
                            program_uid: program_uid,
                            query: filter,
                            page: page,
                            rowsPerPage: rowsPerPage,
                            sortBy: sortBy,
                            descending: descending,
                            show_old: show_old
                        }});
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async get_product_prices_for_user(){
            const api = await this.api();
            const response= await api.get('/user/product_prices');
            if(response.status === 200) {
                return response.data;
            }
        },

        async GetTemplateCourses(page, rowsPerPage, sortBy, descending, filter, filtering) {
            try{
                const api = await this.api();
                const response = await api.get(`/template_courses`, {params:
                        {
                            query: filter,
                            page: page,
                            rowsPerPage: rowsPerPage,
                            sortBy: sortBy,
                            descending: descending,
                            filtering: filtering
                        }});
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetActivities(course, page, rowsPerPage, sortBy, descending, filter) {
            try {
                const api = await this.api();
                const response = await api.get(`/activities`, {params:
                        {course_uid: course, query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetTemplateActivities(page, rowsPerPage, sortBy, descending, filter, filtering, course_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/template_activities`, {params:
                        {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, filtering: filtering, course_uid: course_uid}});
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async UpdateCourse(course){
            const api = await this.api();
            const response = await api.post(`/course`, {course: course});
            Notify.create({
              color: 'positive',
              message: `Course ${response.data.course.course_name} updated successfully`,
              icon: 'save'
            })

            return response.data;
        },
        async DeleteCourse(course){
            const api = await this.api();
            const response = await api.delete(`/course/${course.uid}`)
            Notify.create({
              color: 'info',
              message: `Course ${course.course_name} deleted successfully`,
              icon: 'delete'
            })

        },
        async UpdateActivity(activity, msg){
            const api = await this.api();
            const response = await api.post(`/activity`, {activity: activity})
            Notify.create({
              color: 'positive',
              message: msg ? msg: `Activity ${response.data.activity.activity_name} updated successfully`,
              icon: 'save'
            })
            return response.data.activity;
        },
        async DeleteActivity(activity){
            const api = await this.api();
            const response = await api.delete(`/course/${activity.course_uid}/activity/${activity.uid}`)
            Notify.create({
              color: 'info',
              message: `Activity ${activity.activity_name} deleted successfully`,
              icon: 'delete'
            })

        },
        async GetCourse(course_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/course/${course_uid}`);
                if (response.status === 200) {
                    return response.data.course;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetActivity(course_uid, activity_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/course/${course_uid}/activity/${activity_uid}`);
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async CopyActivity(activity_uid){
            const api = await this.api();
            const response = await api.post(`/copy_template_activity`, {activity_uid: activity_uid})
            Notify.create({
              color: 'positive',
              message: `Activity ${response.data.activity.activity_name} copied successfully`,
              icon: 'save'
            })
            return response.data.activity;
        },
        async CopyCourse(course_uid){
            const api = await this.api();
            const response = await api.post(`/copy_template_course`, {course_uid: course_uid})
            Notify.create({
              color: 'positive',
              message: `Course ${response.data.course.course_name} copied successfully`,
              icon: 'save'
            })
            return response.data.course;
        },
        async MakeTemplateActivity(activity, course_uid){
            const api = await this.api();
            const response = await api.post(`/make_template_activity/${course_uid}/${activity.uid}`)
            Notify.create({
              color: 'positive',
              message: `Activity ${response.data.activity.activity_name} made into template successfully`,
              icon: 'save'
            })
            return response.data.activity;
        },
        async MakeTemplateCourse(course){
            const api = await this.api();
            const response = await api.post(`/make_template_course/${course.uid}`)
            Notify.create({
              color: 'positive',
              message: `Course ${response.data.course.course_name} made into template successfully`,
              icon: 'save'
            })
            return response.data.course;
        },
        async AddCourse(course){
            const api = await this.api();
            const response = await api.post(`/add_template_course/${course.uid}`)
            Notify.create({
              color: 'positive',
              message: `Course ${response.data.course.course_name} added successfully`,
              icon: 'save'
            })
            return response.data;
        },
        async GetStudentActivity(course_uid, activity_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/course/${course_uid}/student_activity/${activity_uid}`);
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetStudentSubmissions(course_uid, activity_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/course/${course_uid}/activity/${activity_uid}/submissions`);
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetSimulationData(course_uid, activity_uid) {
            try {
                const api = await this.api();
                const response = await api.get(`/course/${course_uid}/activity/${activity_uid}/simulation_data`);
                if (response.status === 200) {
                    return response.data;
                }
            }
            catch (error) {
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
                throw error;
            }
        },
        async GetStudentDashboardActivities(){
            const api = await this.api();
            const response = await api.get(`/student_dashboard_activities`);

            return response.data;
        },
        async AddOrInviteUsers(users, role, entity){
            let api;
            if(entity.entity_type === 'organization'){
                api = default_api;
            }
            else{
                api = await this.api();
            }
            try{
                const response = await api.post('/add_invite_users', {users: users, role: role, entity: entity});
                if(response.status === 200) {
                    Notify.create({
                      color: 'positive',
                      message: `Added/Invited ${response.data.users.length} users successfully`,
                      icon: 'save'
                    })

                    return response.data.users;
                }
            }
            catch(error){
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
            }
        },
        async RemoveUser(user_uid, entity){
            let api;
            if(entity.entity_type === 'organization'){
                api = default_api;
            }
            else{
                api = await this.api();
            }
            try{
                const response = await api.post('/remove_user', {user_uid: user_uid, entity: entity});
                if(response.status === 200) {
                    Notify.create({
                      color: 'positive',
                      message: `Removed user from ${entity.name} successfully`,
                      icon: 'save'
                    })

                    return response.data
                }
            }
            catch(error){
                if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
            }
        },
        async GetRecycleBin(page, rowsPerPage, sortBy, descending, filter){
            const api = await this.api();
            const response = await api.get('/recycle_bin', {params:
                        {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});

            return response.data;
        },
        async Undelete(obj){
            const api = await this.api();
            const response = await api.post('/undelete', {obj: obj});

            Notify.create({
              color: 'positive',
              message: `Undelete successful`,
            })
            return response.data;
        },
        async GetPolicies(entity_type, entity_uid, page, rowsPerPage, sortBy, descending, filter){
            const api = await this.api();
            const response = await api.get(`/policies/${entity_type}/${entity_uid}`, {params:
                        {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});

            return response.data;
        },
        async GetProducts() {
            const api = await this.api();
            const response = await api.get(`/products`);

            return response.data;
        },
        async GetPrices(product_id, length) {
            const api = await this.api();
            const response = await api.get(`/prices/${product_id}`,
                {params: {length: length}});

            return response.data;
        },
        async GetPolicy(entity_type, entity_uid, policy_uid){
            const api = await this.api();
            const response = await api.get(`/policy/${entity_type}/${entity_uid}`);

            return response.data.policy;
        },
        CheckPolicy(course, level){
            let values = [];
            if(course.campus_embed && course.campus_embed.policy){
                if(level === 'simulation' && course.campus_embed.policy.product === 'basic'){
                    values.push(false);
                }
                else if(level === 'advanced' && ['basic', 'simulation'].includes(course.campus_embed.policy.product)){
                    values.push(false);
                }
                else{
                    values.push(true);
                }
            }
            else if(course.program_embed && course.program_embed.policy){
                if(level === 'simulation' && course.program_embed.policy.product === 'basic'){
                    values.push(false);
                }
                else if(level === 'advanced' && ['basic', 'simulation'].includes(course.program_embed.policy.product)){
                    values.push(false);
                }
                else{
                    values.push(true);
                }
            }
            if(values.forEach(v => v === false)){
                return false;
            }
            if(this.org.policy){
                if(level === 'simulation' && this.org.policy.product === 'basic'){
                    return false;
                }
                else if(level === 'advanced' && ['basic', 'simulation'].includes(this.org.policy.product)){
                    return false;
                }
            }
            return true
        },
        async StartSimulation(activity, sim_start_time, sim_name){
            const api = await this.api();
            const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/start`,
                {sim_start_time: sim_start_time, timezone_offset: new Date().getTimezoneOffset(), sim_name});

            Notify.create({
              color: 'positive',
              message: `Simulation Started`,
            })
            return response.data;
        },
        async ResetSimulation(activity, simulation_run_uid){
            const api = await this.api();
            const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/reset`,
                {});

            Notify.create({
              color: 'info',
              message: `Simulation Reset`,
            })
            return response.data;
        },
        async PauseSimulation(activity, simulation_run_uid){
            const api = await this.api();
            const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/pause`,
                {})

            Notify.create({
              color: 'info',
              message: `Simulation Paused`,
            })
            return response.data;
        },
        async ResumeSimulation(activity, simulation_run_uid){
            const api = await this.api();
            const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/resume`,
                {})

            Notify.create({
              color: 'positive',
              message: `Simulation Resumed`,
            })
            return response.data;
        },
        async TimeWarpSimulation(activity, simulation_run_uid, time_amount){
            const api = await this.api();
            const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/time_warp`,
                {time_amount: time_amount})

            Notify.create({
              color: 'info',
              message: `Simulation Time Warped`,
            })
            return response.data;
        },
        async GetSimulationRuns(course_uid, activity_uid){
            const api = await this.api();
            const response = await api.get(`/simulation/course/${course_uid}/activity/${activity_uid}/simulation_runs`);

            return response.data;
        },
        async GetSimulationUsers(course_uid, activity_uid, simulation_run_uid){
            const api = await this.api();
            const response = await api.get(`/simulation/course/${course_uid}/activity/${activity_uid}/${simulation_run_uid}/users`);

            return response.data;
        }
    }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRegionStore, import.meta.hot))
}
