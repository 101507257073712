<template>
  <div>
    <q-card class="bg-secondary text-white">
      <q-list>
        <q-item avatar>
          <q-item-section avatar>
            <q-avatar class="bg-primary">
              {{ user.initials }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              {{ user.username }}
            </q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn outline color="negative" rounded @click="$emit('remove')">Remove</q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "CompactUserCard",
  props: ["user"],
  setup(props, { attrs }) {
  }
}
</script>

<style scoped>

</style>
