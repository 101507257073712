<template>
  <span v-if="!edit">{{ modelValue }}</span>
  <q-input v-else class="col-4" outlined :disable="readonly" :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
    <template v-slot:after>
          <q-btn round dense flat icon="document_scanner" @click="gen"/>
    </template>
  </q-input>
</template>

<script>
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min).toString().padStart(6, '0'); // The maximum is exclusive and the minimum is inclusive
}

export default {
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'readonly'],
  name: "BarcodeField",
  setup(props, context){
    return {
      gen(){
        context.emit('update:modelValue', getRandomInt(0, 1000000));
      }
    }
  }
}
</script>

<style scoped>

</style>
