<template>
    <q-menu no-focus v-if="element.help" anchor="center right" self="center left">
      <q-card>
        <q-card-section class="bg-secondary text-h6 text-white q-pa-sm">
          Helpful Information
        </q-card-section>
        <q-card-section>
          <div v-html="element.help"></div>
        </q-card-section>
      </q-card>
    </q-menu>
</template>

<script>
export default {
  name: "PatientHelp",
  props: ["element"],
}
</script>

<style scoped>

</style>