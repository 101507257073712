<template>
  <q-card style="min-height: calc(100vh - 260px); padding-bottom: 60px;" class="bg-white q-ma-lg q-pa-md">
    <component :is="clookup(flowsheet)" v-if="flowsheet" :flowsheet="flowsheet" :editing="false" :data="data" ref="inner_form"></component>
    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  </q-card>
</template>

<script>
import { usePatientStore } from "../../stores/patient";
import { useFlowsheetStore } from "../../stores/flowsheet";
import {storeToRefs} from "pinia";
import {inject, onMounted, ref, toRef} from "vue";
import Grid from "../../components/flowsheets/Grid.vue";
import TimeGrid from "../../components/flowsheets/TimeGrid.vue";
import Form from "../../components/flowsheets/Form.vue";
import Table from "../../components/flowsheets/Table.vue";
import Summary from "../../components/flowsheets/Summary.vue";
import FormTable from "@/components/flowsheets/FormTable.vue";

export default {
  name: "PatientFlowsheet",
  components: {
    Grid
  },
  props: ["patient_uid", "flowsheet_uid", 'version_uid'],
  setup(props) {
    const versioned = inject("versioned");
    const loading = ref(false);
    const data = ref(null);
    const patientStore = usePatientStore();
    //Never make this use the flowsheet store, as it will cause a loop.
    const flowsheet = ref(null);
    const {default_flowsheet} = storeToRefs(patientStore);
    const inner_form = ref(null);
    const patient_uid = toRef(props, "patient_uid");
    const flowsheet_uid = toRef(props, "flowsheet_uid");
    const version_uid = toRef(props, "version_uid");

    onMounted(() => {
      data.value = null;
    });

    return {
      default_flowsheet,
      versioned,
      patientStore,
      loading,
      flowsheet,
      data,
      inner_form,
      patient_uid,
      flowsheet_uid,
      version_uid,
    };
  },
  watch:{
    default_flowsheet: {
      handler(newVal, oldVal){
        console.log("Default Flowsheet Changed");
        if(newVal !== oldVal && newVal && !this.flowsheet_uid){
          let route = {...this.$route};
          this.$router.replace({
            path: `/patient/${this.patient_uid}/flowsheet/${newVal}`,
            query: {
              ...route.query,
            }
          });
        }
      },
      immediate: true
    },
    flowsheet_uid: {
      handler(newVal, oldVal) {
        console.log("Flowsheet UID Changed")
        if (newVal !== oldVal && this.versioned && newVal) {
          this.loading = true;
          this.patientStore.GetPatientDataForFlowsheet(this.patient_uid, newVal, this.versioned.uid, this.version_uid).then((output) => {
            this.flowsheet = output.flowsheet;
            this.data = output.patient_data;
            if(this.inner_form && this.inner_form.reset_data) this.inner_form.reset_data(this.data);
            this.loading = false;
          });
        }
      },
      immediate: true
    },
    patient_uid: {
      handler(newVal, oldVal) {
        console.log("Patient UID Changed: this.flowsheet_uid: ", this.flowsheet_uid, " this.versioned: ", this.versioned, " this.version_uid: ", this.version_uid)
        if (newVal !== oldVal && this.versioned && newVal && this.flowsheet_uid) {
          this.loading = true;
          this.patientStore.GetPatientDataForFlowsheet(newVal, this.flowsheet_uid, this.versioned.uid, this.version_uid).then((output) => {
            this.flowsheet = output.flowsheet;
            this.data = output.patient_data;
            if(this.inner_form && this.inner_form.reset_data) this.inner_form.reset_data(this.data);
            this.loading = false;
          });
        }
        else if (this.default_flowsheet){
          console.log("Set to default flowsheet: ", this.default_flowsheet)
          let route = {...this.$route};
          this.$router.replace({
            path: `/patient/${this.patient_uid}/flowsheet/${this.default_flowsheet}`,
            query: {
              ...route.query,
            }
          });
        }
      },
      immediate: true
    },
    versioned(newVal, oldVal) {
      if (newVal !== oldVal && newVal && this.flowsheet_uid) {
        this.loading = true;
        this.patientStore.GetPatientDataForFlowsheet(this.patient_uid, this.flowsheet_uid, newVal.uid, this.version_uid).then((output) => {
          this.flowsheet = output.flowsheet;
          this.data = output.patient_data;
          if(this.inner_form && this.inner_form.reset_data) this.inner_form.reset_data(this.data);
          this.loading = false;
        });
      }
    }
  },
  methods:{
    clookup(component) {
      if(component.flowsheet_type === 'Grid') return Grid;
      if(component.flowsheet_type === 'Time Grid') return TimeGrid;
      if(component.flowsheet_type === 'Form') return Form;
      if(component.flowsheet_type === 'Table') return Table;
      if(component.flowsheet_type === 'Summary') return Summary;
      if(component.flowsheet_type === 'Form Table') return FormTable;
    },

  }
}
</script>

<style scoped>

</style>
