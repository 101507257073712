<template>
  <div style="min-height: 400px;">
    <div v-if="policy">
      <div class="row justify-center" v-if="license_products">
          <q-btn-toggle
            :options="license_products"
            v-model="policy.product"
          >
            <template v-for="product in license_products" v-slot:[product.slot]>
              <div>
                <div class="row text-h5">
                  {{ product.name }}
                </div>
                <div class="row">
                  {{ product.description }}
                </div>
              </div>
            </template>
          </q-btn-toggle>
      </div>
      <div class="row">
        <q-checkbox v-model="policy.student_pay" label="Enable Payment Page"></q-checkbox>
      </div>
      <div class="row" v-if="policy.student_pay">
        <div class="q-ml-xl col-12">
          <q-checkbox v-model="policy.codes" label="Enable Payment Codes"></q-checkbox>
        </div>
        <div class="q-ml-xl col-12">
          <q-checkbox v-model="policy.stripe" label="Enable Stripe Payments"></q-checkbox>
        </div>
        <div class="q-ml-xl col-12">
          <q-checkbox v-model="policy.bookstore" label="Enable Bookstore Payments"></q-checkbox>
        </div>
        <div class="q-ml-xl col-12">Students May Pay for the following lengths of time:</div>
        <div class="q-ml-xl col-12">
        <q-list>
          <template v-for="price in license_prices">
            <q-item v-if="price.default_price">
              <q-item-section avatar>
                <q-checkbox v-model="policy.student_pay_prices" :val="price.default_price.id"></q-checkbox>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ price.name }} - ${{ display_price(price.default_price.unit_amount) }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
        </div>
      </div>
      <div class="row">
        <q-checkbox v-model="policy.metered" label="Allow Metered Billing"></q-checkbox>
      </div>
      <div class="row">
      </div>
      <div class="row">
        <q-select outlined label="Coupon" v-model="policy.coupon" :options="coupons" style="min-width: 250px;"></q-select>
      </div>
      <div>
        <q-btn class="q-ma-md" color="secondary" @click="save">Save</q-btn>
        <q-btn class="q-ma-md" @click="new_purchase=true">Get a Quote</q-btn>
        <q-btn class="q-ma-md" @click="new_purchase=true">Purchase Licenses</q-btn>
      </div>
    </div>
    <q-inner-loading
      :showing="license_product_loading || loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  </div>
  <q-dialog v-model="new_purchase">
    <q-card>
      <q-card-section class="text-h5 text-center q-pt-lg">
        <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense @click="new_purchase=false"/>
        Purchase Licenses
      </q-card-section>
      <q-card-section>
        <q-input outlined label="Quantity"></q-input>
      <q-list>
        <template v-for="price in license_prices">
          <q-item v-if="price.default_price">
            <q-item-section avatar>
              <q-radio v-model="policy.price" :val="price.default_price.id"></q-radio>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ price.name }} - ${{ display_price(price.default_price.unit_amount) }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
      </q-card-section>
      <q-card-actions>
        <q-btn color="positive" label="Purchase"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {useRegionStore} from "../stores/region";

export default {
  name: "LicensePolicy",
  props: ["entity_type", "entity_uid", "entity"],
  setup(props) {
    const regionStore = useRegionStore();
    const policy = ref(null);
    const tab = ref("details");
    const license_products = ref([]);
    const add_on_products = ref([]);
    const license_prices = ref([]);
    const license_product_loading = ref(false);
    const license_price_loading = ref(false);
    const policies = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const coupons = ref([]);
    const new_purchase = ref(false);
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'name',
        descending: false,
    })

    onMounted(() => {
      license_product_loading.value = true;
      policy.value = props.entity.policy;
      if(!policy.value) {
        policy.value = {
          entity_type: props.entity_type,
          entity_uid: props.entity_uid,
          addons: [],
          product: 'legacy',
          student_pay: false,
          metered: false,
          bookstore: false,
          codes: false,
          stripe: false,
          student_pay_prices: [],
        }
      }
      regionStore.GetProducts().then((products) => {
        license_products.value = products.filter((p) => (p.metadata.product_type === "license" && p.metadata.master ===  'true'));
        for (let product of license_products.value) {
            product.value = product.metadata.product_category
            product.slot = product.metadata.product_category;
            product.prices = products.filter((p) => (p.metadata.product_type === "license" && !p.metadata.master && p.metadata.product_category === product.metadata.product_category));
        }
        license_products.value.sort((a, b) => a.metadata.order - b.metadata.order);
        add_on_products.value = products.filter((p) => p.metadata.product_type === "add_on");

        if(policy.value.product){
            get_prices(policy.value.product);
        }
        license_product_loading.value = false;
      });
    });

    function get_prices(product){
        license_prices.value = license_products.value.find((lp) => lp.metadata.product_category === product).prices;
        license_prices.value.sort((a, b) => a.metadata.order - b.metadata.order);
    }

    watch(() => (policy.value && policy.value.product), (p) => {
      if(p && license_products.value.length > 0){
        get_prices(p);
      }
    });

    return {
      display_price(price) {
          return price / 100.0;
      },
      policies,
      coupons,
      loading,
      filter,
      pagination,
      tab,
      policy,
      license_products,
      add_on_products,
      license_prices,
      license_product_loading,
      license_price_loading,
      new_purchase,
      get_addon_prices(addon){
        addon.loading = true;
        regionStore.GetPrices(addon.id, policy.value.price.metadata.length).then((prices) => {
            addon.prices = prices;
            addon.loading = false;
        });
      },
      calc_price() {
          let price = 0;
          if(policy.value.price) {
              price = policy.value.price.unit_amount;
              for (let addon of policy.value.addons) {
                  if(addon.price) {
                      price += addon.price.unit_amount;
                  }
              }
          }
          return price / 100.0;
      },
      save() {
        props.entity.policy = policy.value;
        loading.value = true;
        if(props.entity_type==='organization'){
          regionStore.UpdateOrganization(props.entity).then((org)=>{
            loading.value = false;
          });
        }
        else if(props.entity_type==='campus'){
          regionStore.UpdateCampus(props.entity).then((campus)=>{
            loading.value = false;
          });
        }
        else if(props.entity_type==='program'){
          regionStore.UpdateProgram(props.entity).then((program)=>{
            loading.value = false;
          });
        }
      },
    }
  }
}
</script>

<style scoped>

</style>