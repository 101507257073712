<template>
  <div>
    <q-card class="text-center q-pa-lg">
      <q-form>
        <h4 class="text-primary">Verification Email Sent</h4>
        <p class="text-primary text-bold">We just sent an email to {{ email }}</p>
        <p>Please use the link to login or enter the code below</p>
        <q-input class="q-ma-md" label="Login Code" v-model="verification_code" outlined></q-input>
        <q-btn color="secondary" type="submit" @click.prevent="onSubmit">OK</q-btn>
      </q-form>
      <q-inner-loading
        :showing="loading"
        label="Please wait..."
        label-class="text-secondary"
        label-style="font-size: 1.1em"
      />
    </q-card>
  </div>
</template>

<script>
import {useUserStore} from "../stores/user";
import {storeToRefs} from "pinia";
import {ref} from "vue";
import { useRouter, useRoute } from 'vue-router'

export default {
  name: "Verification",
  setup(props, { attrs, slots, emit, expose }){
    const router = useRouter();
    const route = useRoute()
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    const verification_code = ref(attrs.verify || '')
    const email = ref( attrs.email || '' );
    const loading = ref(false);

    return {
      loading,
      verification_code,
      email,
      user,
      onSubmit(){
        loading.value = true;
        userStore.VerifyOTP(email.value, verification_code.value).then(() => {
          loading.value = false;
          if(route.query.redirect_to) {
            router.push(route.query.redirect_to)
          }
          else{
            router.push(userStore.default_route)
          }
        })

      }
    }
  }
}
</script>

<style scoped>

</style>
