<template>
  <teleport to="#toolbar_container">
    <q-select v-if="currentVersion" outlined :readonly="readonly" label="version" :options="versions" v-model="currentVersion" option-label="version_name" @update:model-value="update_version($event)" class="col">
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps">{{ scope.opt.version_name }}</q-item>
      </template>
    </q-select>
  </teleport>
</template>

<script>
import {onMounted, ref} from 'vue'
import {useFlowsheetStore} from "../stores/flowsheet";

export default {
  name: "Versioned",
  props: ['readonly', 'version', 'published'],
  emits: ['selected'],
  setup(props, { attrs, slots, emit }) {
    const versions = ref([]);
    const currentVersion = ref(null);
    const flowsheetStore = useFlowsheetStore();

    function set_version(){
      const store_version = localStorage.getItem('version');
      let use_version = props.version;
      if(!use_version){
        use_version = store_version;
      }
      if(!use_version){
        use_version = 'published';
      }
      for (let i = 0; i < versions.value.length; i++) {
        if (versions.value[i].uid === use_version) {
          currentVersion.value = versions.value[i];
          break;
        }
      }
      if(!currentVersion.value){
        currentVersion.value = versions.value[0];
      }
      emit('selected', currentVersion.value);
    }

    onMounted(() => {
      if(!versions.value.length) {
        const pub = {version_name: 'Published', uid: 'published'};
        versions.value.push(pub);
        flowsheetStore.GetVersions().then(v => {
          versions.value.push(...v.versions);
          set_version();
        });
      }
      else{
        set_version()
      }
    });

    return {
      versions,
      currentVersion
    }
  },
  methods: {
    update_version(event) {
      this.$emit('selected', event);
      localStorage.setItem('version', event.uid)
    }
  }
}
</script>

<style scoped>

</style>
