<template>
  <Versioned @selected="versioned = $event" readonly :version="version"></Versioned>
  <q-card v-if="chart">
    <q-card-section>
        <q-btn class="absolute-top-right" flat color="primary" round icon="close" to="/charts"></q-btn>

        <q-input class="q-mt-lg" :readonly="chart.uid==='patient'" outlined label="Visit Type Name" v-model="chart.chart_name"></q-input>
    </q-card-section>
    <q-card-section>
      <div class="row q-col-gutter-xl">
        <div class="col">
            <draggable v-model="fixed_flowsheets" item-key="uid" :group="{name: 'chart', pull: 'clone', put: false}">
              <template #item="{element}">
                <q-item clickable>
                  <q-item-section>
                    <q-item-label>
                      {{ element.flowsheet_name }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </draggable>
            <h5 class="q-ma-md">Available Flowsheets</h5>
            <draggable v-model="flowsheets" item-key="uid" :group="{name: 'chart', put: false}">
              <template #item="{element}">
                <q-item clickable :inset-level=".5">
                  <q-item-section>
                    <q-item-label>
                      {{ element.flowsheet_name }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ element.version_name}} - {{ element.flowsheet_type }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </draggable>
            <q-btn label="Save" color="secondary" @click="save()"></q-btn>
            <q-btn class="q-ma-sm" color="negative" @click="confirm_delete=true">Delete</q-btn>
        </div>
        <div class="col">
            <h5 class="q-ma-md">Chart Flowsheets</h5>
            <q-card square style="width: 350px; min-height: 50px;">
            <draggable v-model="chart.flowsheets" item-key="uid" group="chart" style="min-height: 50px;">
              <template #item="{element}">
                <draggable v-model="element.flowsheets" tag="q-expansion-item" item-key="uid" :group="{name: 'chart'}"
                  v-if="element.flowsheet_type==='Submenu'"
                  default-opened
                >
                  <template v-slot:header>
                    <q-item>
                      <q-item-section>
                        <q-input label="Submenu Name" v-model="element.flowsheet_name" outlined></q-input>
                      </q-item-section>
                      <q-item-section side>
                        <q-btn round color="negative" icon="close" @click="remove(element)"></q-btn>
                      </q-item-section>
                    </q-item>
                  </template>

                    <template #item="{element}">
                      <q-item :inset-level=".5" clickable>
                        <q-item-section>
                          <q-item-label>
                            {{ element.flowsheet_name }}
                          </q-item-label>
                          <q-item-label caption>
                            {{ element.version_name}} - {{ element.flowsheet_type }}
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-btn round color="negative" icon="close" @click="remove(element)"></q-btn>
                        </q-item-section>
                      </q-item>
                    </template>
                  </draggable>
                <q-item clickable v-else>
                  <q-item-section avatar>
                    <q-radio v-model="chart.default_flowsheet" :val="element.uid" label="Default" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>
                      {{ element.flowsheet_name }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-btn round color="negative" icon="close" @click="remove(element)"></q-btn>
                  </q-item-section>
                </q-item>
              </template>
            </draggable>
            </q-card>
        </div>
      </div>
    </q-card-section>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the Visit Type: {{chart.chart_name }}?</span><br>
          <span>This will delete the Visit Type for this Version</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="flowsheetStore.DeleteChart(chart, version); $router.replace(`/charts`)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
  <q-inner-loading
    :showing="loading"
    label="Please wait..."
    label-class="text-secondary"
    label-style="font-size: 1.1em"
  />
</template>

<script>
import { useFlowsheetStore } from "../stores/flowsheet";
import {onMounted, ref} from "vue";
import Versioned from "../components/Versioned.vue";
import draggable from 'vuedraggable'
import { useRouter } from "vue-router";

export default {
  name: "Chart",
  props: ["version", "chart_uid", "from"],
  components: {
    Versioned, draggable
  },
  setup(props) {
    const router = useRouter();
    const flowsheetStore = useFlowsheetStore();
    const loading = ref(false);
    const chart = ref(null);
    const flowsheets = ref([]);
    const confirm_delete = ref(false);

    function get_flowsheets(page){
      flowsheetStore.GetFlowsheets(page, null, null, null, null, props.version).then(f => {
        flowsheets.value.push(...f.flowsheets);
        if(props.chart_uid === "patient"){
            flowsheets.value.push(...f.flowsheets.filter(ff => ff.flowsheet_context === "Patient"));
        }
        if(f.flowsheets.length === 50){
          get_flowsheets(page+1);
        }
      });
    }

    onMounted(() => {
      if(props.chart_uid === "new"){
        chart.value = {
          version: props.version,
          chart_name: '',
          flowsheets: [],
          default_flowsheet: null,
        }
      }
      else {
        loading.value = true;
        flowsheetStore.GetChart(props.chart_uid, props.version, props.from).then(f => {
          chart.value = f.chart;
          if(props.from){
            router.replace(`/chart/${chart.value.uid}/${chart.value.version}`);
          }
          loading.value = false;
        });
      }
      get_flowsheets(1);
    });

    return {
      fixed_flowsheets: [
        {
          flowsheet_type: 'Submenu',
          flowsheet_name: 'Submenu',
          flowsheets: [],
        },
        {
          flowsheet_type: 'Details',
          flowsheet_name: 'Patient Details',
          flowsheet_context: 'Patient',
          uid: 'patient_details'
        },
        {
          flowsheet_type: 'History',
          flowsheet_name: 'Visit History',
          flowsheet_context: 'Patient',
          uid: 'visit_history'
        }
      ],
      confirm_delete,
      flowsheetStore,
      flowsheets,
      loading,
      chart,
      save(){
        loading.value = true;
        flowsheetStore.SaveChart(chart.value).then((data)=>{
          chart.value = data.chart;
          if(props.chart_uid === "new"){
            router.replace(`/chart/${chart.value.uid}/${chart.value.version}`);
          }
          loading.value = false;
        });
      }
    }
  },
  methods: {
    remove(element){
      for(let ele of this.chart.flowsheets){
        if(ele.flowsheets && element.flowsheet_type !== 'Submenu' && ele.flowsheet_type === 'Submenu'){
          const inside = ele.flowsheets.find(f => f.uid === element.uid)
          if(inside) {
            ele.flowsheets = ele.flowsheets.filter(f => f.uid !== element.uid);
            this.flowsheets.push(inside);
          }
        }
        else if (ele.uid === element.uid){
          this.chart.flowsheets = this.chart.flowsheets.filter(f => f.uid !== element.uid);
          this.flowsheets.push(ele);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
