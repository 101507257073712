<template>
  <Versioned @selected="versioned = $event" readonly :version="version"></Versioned>
  <q-drawer v-if="flowsheet && flowsheet.flowsheet_type !== 'Summary'" side="left" v-model="show">
    <q-list>
      <q-item>
        <draggable v-model="expansion_panel" item-key="expansion_type" :sort="false" :group="{name: 'fields', pull: 'clone', put: false}" :clone="new_panel">
          <template #item="{element}">
            <q-card class="q-ma-md">
              <q-card-section>
                Expansion Panel
              </q-card-section>
            </q-card>
          </template>
        </draggable>
      </q-item>
      <q-item class="q-pa-md bg-primary text-white text-h5">
        <q-item-section>
          <q-item-label>Fields</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn color="secondary" icon="add" @click="new_field">New</q-btn>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
        </q-item-section>
      </q-item>
      <draggable v-model="available_fields" item-key="uid" :sort="false" :group="{name: 'fields', put: false}">
        <template #item="{element}">
          <q-card class="q-ma-md">
            <q-card-section>
              {{ element.field_name }}
              <div class="text-body2">{{ element.entity_type=='advanced_field' ? 'Advanced Field' : element.data_type }}</div>
              <div class="text-body2">{{ element.uid }}</div>
            </q-card-section>
          </q-card>
        </template>
        <template #footer>
          <div v-intersection="intersection">More...</div>
        </template>
      </draggable>
    </q-list>
    <q-inner-loading
      :showing="fields_loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  </q-drawer>
  <FlowsheetList v-else-if="flowsheet" :flowsheet_context="flowsheet.flowsheet_context"></FlowsheetList>
  <q-page-container class="bg-grey-4">
      <q-page v-if="flowsheet">
        <q-card style="min-height: calc(100vh - 120px);" class="bg-white q-ma-lg q-pa-md">
          <q-toolbar class="bg-white">
            <q-toolbar-title>
              Flowsheet Builder
            </q-toolbar-title>
            <div class="q-pa-sm" v-if="issaving">Saving Flowsheet...<q-linear-progress indeterminate></q-linear-progress></div>
            <div class="q-pa-sm text-warning" v-else-if="unsaved !== saved">Changes need Saving</div>
            <div class="q-pa-sm text-positive" v-else>Up to Date</div>
            <q-btn flat color="primary" round icon="settings" @click="active_field_index=null; active_field=null; active_flowsheet=null;"></q-btn>
            <q-btn flat color="primary" round icon="close" to="/flowsheets"></q-btn>
          </q-toolbar>
          <Component :is="clookup(flowsheet)" :flowsheet="flowsheet" :editing="true" @selected="activate_field" :active_field="active_field" :active_flowsheet="active_flowsheet" :data="sample_data" :new_mode="true"></Component>
        </q-card>
      </q-page>

  </q-page-container>
  <FieldDrawer v-if="active_field" v-model="active_field" :flowsheet="flowsheet" :version="version" @remove="remove(active_field_index)"></FieldDrawer>
  <q-drawer v-else-if="active_flowsheet" side="right" v-model="summary_settings" v-if="flowsheet">
      <div class="q-pa-md bg-primary text-white text-center text-h5">Flowsheet in Summary</div>
      <div class="q-ma-md">{{ active_flowsheet.flowsheet_name }}</div>
      <q-btn class="q-ma-md" color="negative" @click="confirm_remove_flowsheet=true">Remove</q-btn>
  </q-drawer>
  <q-drawer v-else side="right" v-model="show" v-if="flowsheet">
      <div class="q-pa-md bg-primary text-white text-center text-h5">Flowsheet Settings</div>
      <q-input class="q-ma-md" v-model="flowsheet.flowsheet_name" outlined label="Flowsheet Name"></q-input>
      <q-select class="q-ma-md" v-model="flowsheet.flowsheet_type" outlined label="Flowsheet Type" :options="['Grid', 'Time Grid', 'Form', 'Table', 'Form Table', 'Summary']"></q-select>
      <q-select class="q-ma-md" v-model="flowsheet.flowsheet_context" outlined label="Flowsheet Context" :options="['Patient', 'Visit', 'Student']"></q-select>
      <q-btn class="q-ma-sm" color="negative" @click="confirm_delete=true">Delete</q-btn>
  </q-drawer>
  <q-dialog v-model="confirm_delete">
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure you want to delete the flowsheet: {{flowsheet.flowsheet_name }}?</span><br>
        <span>This will delete this version of the flowsheet</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn label="Delete" color="negative" v-close-popup @click="flowsheetStore.DeleteFlowsheet(flowsheet, version); $router.replace(`/flowsheets`)" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirm_remove_flowsheet">
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure you want to remove the flowsheet: {{active_flowsheet.flowsheet_name }} from the Summary Flowsheet?</span><br>
        <span>This will remove this flowsheet from the current summary Flowsheet</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn label="Delete" color="negative" v-close-popup @click="remove_flowsheet(active_flowsheet, active_flowsheet_index)"/>
      </q-card-actions>
    </q-card>
  </q-dialog>

    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
</template>

<script>
import Grid from '../components/flowsheets/Grid.vue'
import TimeGrid from '../components/flowsheets/TimeGrid.vue'
import Form from '../components/flowsheets/Form.vue'
import Table from '../components/flowsheets/Table.vue'
import Summary from "../components/flowsheets/Summary.vue";
import {onMounted, ref, provide} from "vue";
import DateTimeField from "../components/DateTimeField.vue";
import { useFlowsheetStore, useFlowsheetWriter } from "../stores/flowsheet";
import { useRouter } from "vue-router";
import {storeToRefs} from "pinia";
import {debounce, useQuasar} from 'quasar'
import draggable from 'vuedraggable'
import Versioned from "../components/Versioned.vue";
import { usePatientStore } from "../stores/patient";
import { generate } from "xksuid"
import FlowsheetList from "../components/flowsheets/FlowsheetList.vue";
import FieldDrawer from "../components/FieldDrawer.vue";
import FormTable from "@/components/flowsheets/FormTable.vue";

export default {
  name: "FlowsheetBuilder",
  props: ['version', 'flowsheet_uid', 'from'],
  components: {
    FieldDrawer,
    FlowsheetList,
    Versioned,
    Grid,
    DateTimeField,
    draggable
  },
  setup(props) {
    const router = useRouter();
    const has_more = ref(true);
    const page = ref(1);
    const versioned = ref(null);
    provide("versioned", versioned);
    const { loading, issaving, saved, unsaved, flowsheetStore } = useFlowsheetWriter();
    const patientStore = usePatientStore();
    const { patient, viewing_visit } = storeToRefs(patientStore);
    const available_fields = ref([]);
    const { flowsheet, active_field } = storeToRefs(flowsheetStore);
    const fields_loading = ref(false);
    const active_field_index = ref(null);
    const active_flowsheet_index = ref(null);
    const active_flowsheet = ref(null);
    const confirm_delete = ref(false);
    const confirm_remove_flowsheet = ref(false);
    const sample_data = ref({times:[]});
    const lookup_types = ref([]);
    const expansion_panel = [{
        expansion_type: 'panel',
        uid: generate(),
        field_name: '',
        data_type: 'expansion',
        field_units: [],
        field_options: [],
        list_type: '',
        help: '',
        visible: true
    }]

    const $q = useQuasar()

    onMounted(() => {
      flowsheetStore.GetLookupTypesForVersion(null, null, null, null, null, props.version).then(data => {
        lookup_types.value = data.lookup_types;
      });

      patient.value = {
          uid: "277Wh92MHQ0jJgUC81d3Q1KYsUx",
          patient_type: "template",
          name: "Jane Doe",
          legal_name: "",
          date_of_birth: -23652000,
          date_of_birth_override: null,
          age: "45 Years",
          users: [],
          system_patient: null,
          program_type: [],
          organization_uid: null,
          program_uid: null,
          campus_uid: null,
          course_uid: null,
          activity_uid: null,
          scenes: [],
          template_patient_id: "26AoqWLGILnGnCOAEdehqIm6ZR6",
          epoch_datetime: "2022-03-30T15:22:00.284760",
          fixed_start_time: null,
          patient_clock_offset: null,
          patient_picture: null,
          mrn: 800971,
          legal_sex: "Female",
          gender_identity: "",
          sex_at_birth: "",
          preferred_name:"Jane Doe",
          patient_description: "<div style=\"text-align: left;\">Here are some more details</div><div style=\"text-align: left;\">It is all about the patient baby</div>"
      }

      if(props.flowsheet_uid === "new"){
        flowsheet.value = {
          version: props.version,
          flowsheet_name: '',
          flowsheet_type: 'Grid',
          flowsheet_context: 'Patient',
          fields: [],
          lookup_data: {}
        }
      }
      else if (!flowsheet.value || flowsheet.value.uid !== props.flowsheet_uid) {
        loading.value = true;
        flowsheetStore.GetFlowsheet(props.flowsheet_uid, props.version, props.from).then(f => {
          if(props.from){
            router.replace(`/flowsheet_builder/${props.version}/${props.flowsheet_uid}`)
          }
          loading.value = false;
          if(props.from){
            router.replace(`/flowsheet_builder/${props.version}/${props.flowsheet_uid}`)
          }
        });
      }
      /*if(available_fields.value === null){
        fields_loading.value = true;
        flowsheetStore.GetAvailableFields().then(f => {
          available_fields.value = f.fields;
          fields_loading.value = false;
        });
      }*/
    });


    return {
      confirm_delete,
      confirm_remove_flowsheet,
      expansion_panel,
      has_more,
      page,
      flowsheetStore,
      versioned,
      sample_data,
      active_field,
      active_flowsheet,
      version: props.version,
      issaving,
      unsaved,
      saved,
      flowsheet,
      loading,
      fields_loading,
      active_field_index,
      active_flowsheet_index,
      show: true,
      available_fields,
      filter: ref(''),
      flowsheet_settings: ref(true),
      field_settings: ref(false),
      summary_settings: ref(false),
      lookup_types,
    }
  },
  watch: {
    "filter": {
      handler(val, oldVal){
        this.has_more = true;
        this.page = 1;
        this.fields_loading = true;
        this.flowsheetStore.GetAvailableFields(this.page, null, null, null, this.filter, this.version).then(f => {
          if(this.page===1) this.available_fields = [];
          this.available_fields.splice(this.available_fields.length, 0, ...f.fields);
          this.fields_loading = false;
          this.page++;
          if(f.fields.length === 0) this.has_more = false;
        });
      },
    },
    "flowsheet.fields":{
      handler(val, oldVal){
        if(val && oldVal && val.length > oldVal.length){
          for(let field of val){
            if(!oldVal.includes(field)){
              console.log("added field");
              console.log(field);
              if(field.lookup_type && field.lookup_type.length > 0){
                for(let lookup_type_uid of field.lookup_type){
                  let lookup_type = this.lookup_types.find(l => l.uid === lookup_type_uid)
                  this.flowsheet.lookup_data[lookup_type_uid] = lookup_type;
                  if(!this.flowsheet.lookup_data[lookup_type_uid].using) this.flowsheet.lookup_data[lookup_type_uid].using = [];
                  this.flowsheet.lookup_data[lookup_type_uid].using.push(field.uid);
                }
              }
            }
          }
        }
      },
      deep: true
    },
    "flowsheet.flowsheet.context":{
      handler(val, oldVal){
        if(val === "Visit"){
          this.viewing_visit = {
            'uid': "ABC123",
            'patient_uid': this.patient.uid,
            'visit_type': null,
            'start_timestamp': 0,
            'end_timestamp': 0,
            'reason_for_visit': 'Flowsheet Builder Visit'
          }
        }
        else{
          this.viewing_visit = null;
        }
      },
      immediate: true
    },
  },
  methods:{
    clookup(component) {
      if(component.flowsheet_type === 'Grid') return Grid;
      if(component.flowsheet_type === 'Form') return Form;
      if(component.flowsheet_type === 'Table') return Table;
      if(component.flowsheet_type === 'Summary') return Summary;
      if(component.flowsheet_type === 'Time Grid') return TimeGrid;
      if(component.flowsheet_type === 'Form Table') return FormTable;
    },
    activate_field(field) {
      console.log("activating field")
      console.log(field)
      if(field.entity_type === 'flowsheet')
      {
        this.field_settings = false;
        this.flowsheet_settings = false;
        this.summary_settings = true
        for(let i = 0; i < this.flowsheet.flowsheets.length; i++){
          if(this.flowsheet.flowsheets[i].uid === field.uid){
            this.active_flowsheet_index = i;
            this.active_flowsheet = this.flowsheet.flowsheets[i];
            break;
          }
        }
        return;
      }
      this.summary_settings = false;
      this.fieldisloading = true;
      this.field_settings = true;
      this.flowsheet_settings = false;
      for(let i = 0; i < this.flowsheet.fields.length; i++){
        if(this.flowsheet.fields[i].uid === field.uid){
          this.active_field_index = i;
          this.active_field = this.flowsheet.fields[i];
          break;
        }
      }
      this.$nextTick(() => {
        this.fieldisloading = false;
      });
    },
    new_field() {
      this.fieldisloading = true;
      const field =  {
        uid: generate(),
        field_name: '',
        data_type: 'Text',
        field_units: [],
        field_options: [],
        list_type: '',
        help: '',
        allow_other: false,
      }
      this.flowsheet.fields.push(field);
      this.active_field = field;
      this.active_field_index = this.flowsheet.fields.length - 1;
      this.field_settings = true;
      this.flowsheet_settings = false;
      this.$nextTick(() => {
        this.fieldisloading = false;
      });
    },
    remove(index) {
      let field = this.flowsheet.fields[index];
      if(field.lookup_type && field.lookup_type.length > 0){
        for(let lookup_type_uid of field.lookup_type){
          if(lookup_type_uid in this.flowsheet.lookup_data) {
            let lookup_type = this.flowsheet.lookup_data[lookup_type_uid]
            if (lookup_type) {
              const index = this.flowsheet.lookup_data[lookup_type_uid].using.indexOf(this.active_field.uid)
              if (index > -1) {
                this.flowsheet.lookup_data[lookup_type_uid].using.splice(index, 1);
                if (this.flowsheet.lookup_data[lookup_type_uid].using.length === 0) {
                  delete this.flowsheet.lookup_data[lookup_type_uid];
                }
              }
            }
          }
        }
      }

      this.flowsheet.fields.splice(index, 1);
      this.active_field_index = null;
      this.active_field = null;
      this.field_settings = false;
      this.flowsheet_settings = true;
    },
    remove_flowsheet(index){
      this.flowsheet.flowsheets.splice(index, 1);
      this.active_flowsheet_index = null;
      this.active_flowsheet = null;
      this.summary_settings = false;
      this.flowsheet_settings = true;
    },
    intersection(entry){
      if(entry.isIntersecting && this.has_more) {
        this.fields_loading = true;
        this.flowsheetStore.GetAvailableFields(this.page, null, null, null, this.filter, this.version).then(f => {
          if(this.page===1) this.available_fields = [];
          this.available_fields.splice(this.available_fields.length, 0, ...f.fields);
          this.fields_loading = false;
          this.page++;
          if(f.fields.length === 0) this.has_more = false;
        });
      }
    },
    new_panel(){
      return {
        expansion_type: 'panel',
        uid: generate(),
        field_name: '',
        data_type: 'expansion',
        field_units: [],
        field_options: [],
        list_type: '',
        help: '',
        visible: true
      }
    }
  }
}
</script>

<style lang="scss">
</style>
