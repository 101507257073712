<template>
  <q-card v-if="policy" class="text-left">
    <q-card-section class="text-h5 text-primary">
      <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/${entity_type}/${entity_uid}`"/>

    </q-card-section>
    <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
        >
          <q-tab name="details" label="Details" />
          <q-tab name="licenses" label="Licenses" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details">
        <q-form @submit.prevent="save">
          <div class="q-pa-md">
            <q-btn-toggle
              v-model="policy.policy_type"
              no-caps
              rounded
              unelevated
              toggle-color="primary"
              color="grey-4"
              text-color="primary"
              glossy
              :options="[
                {label: 'Student Pay', value: 'student_pay'},
                {label: 'School Pay', value: 'organization_pay'},
              ]"
            />
          </div>
          <div class="q-pa-md" v-if="policy.policy_type==='organization_pay'">
            <q-btn-toggle
              v-model="policy.school_pay_type"
              no-caps
              rounded
              unelevated
              toggle-color="primary"
              color="grey-4"
              text-color="primary"
              glossy
              :options="[
                {label: 'Payment Code', value: 'payment_code'},
                {label: 'License Pool', value: 'license_pool'},
              ]"
            />
          </div>
          <div class="q-pa-md" v-if="policy.policy_type==='organization_pay' && policy.school_pay_type==='license_pool'">
            <q-btn-toggle
              v-model="policy.license_pool_type"
              no-caps
              rounded
              unelevated
              toggle-color="primary"
              color="grey-4"
              text-color="primary"
              glossy
              :options="[
                {label: 'Pre Purchase', value: 'pre_purchase'},
                {label: 'Metered', value: 'metered'},
              ]"
            />
          </div>
          <q-btn class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
          <q-btn class="q-ma-md" color="secondary" type="submit">Save</q-btn>
        </q-form>
        <q-inner-loading
          :showing="loading"
          label="Please wait..."
          label-class="text-secondary"
          label-style="font-size: 1.1em"
        />
      </q-tab-panel>
      <q-tab-panel name="licenses">
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRegionStore} from "../stores/region";

export default {
  name: "Policy",
  props: ["is_new", "entity_type", "entity_uid", "policy_uid"],
  setup(props) {
    const regionStore = useRegionStore();
    const policy = ref(null);
    const tab = ref("details");
    const license_products = ref([]);
    const add_on_products = ref([]);
    const license_prices = ref([]);
    const license_product_loading = ref(false);
    const license_price_loading = ref(false);
    const loading = ref(false);

    onMounted(() => {
      if (props.is_new) {
        policy.value = {
            entity_type : props.entity_type,
            entity_uid : props.entity_uid,
            addons: []
        }
      } else {
        //regionStore.GetOrganization(props.organization_uid);
        loading.value = true;
        regionStore.GetPolicy(props.entity_type, props.entity_uid, props.policy_uid).then((p) => {
          loading.value = false;
          policy.value = p;
        });
      }
      license_product_loading.value = true;
      regionStore.GetProducts().then((products) => {
        license_products.value = products.filter((p) => p.metadata.product_type === "license");
        add_on_products.value = products.filter((p) => p.metadata.product_type === "add_on");
        license_product_loading.value = false;
      });
    });

    return {
      loading,
      tab,
      policy,
      license_products,
      add_on_products,
      license_prices,
      license_product_loading,
      license_price_loading,
      get_prices(){
        license_price_loading.value = true;
        regionStore.GetPrices(policy.value.product.id).then((prices) => {
            license_prices.value = prices;
            license_price_loading.value = false;
        });
      },
      get_addon_prices(addon){
        addon.loading = true;
        regionStore.GetPrices(addon.id, policy.value.price.metadata.length).then((prices) => {
            addon.prices = prices;
            addon.loading = false;
        });
      },
      calc_price() {
          let price = 0;
          if(policy.value.price) {
              price = policy.value.price.unit_amount;
              for (let addon of policy.value.addons) {
                  if(addon.price) {
                      price += addon.price.unit_amount;
                  }
              }
          }
          return price / 100.0;
      },
      save() {
          regionStore.SavePolicy(policy.value);
      },
    }
  }
}
</script>

<style scoped>

</style>