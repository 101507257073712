<template>
    <template v-if="!edit">
      <router-link v-if="patient" :to="`/patient/${patient.uid}/flowsheet/${modelValue.uid}`">{{ modelValue.flowsheet_name }}</router-link>
      <span v-else>{{ modelValue.flowsheet_name }} Link</span>
    </template>
    <template v-else>
      <q-select
          outlined
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
          :options="options"
          use-input
          @filter="filterFn"
          @filter-abort="abortFilterFn"
      >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              {{ scope.opt.flowsheet_name }}<br>
              {{ scope.opt.flowsheet_context }} - {{ scope.opt.flowsheet_type }}
            </q-item>
          </template>
          <template v-slot:selected-item="scope">
              {{ scope.opt.flowsheet_name }}<br>
              {{ scope.opt.flowsheet_context }} - {{ scope.opt.flowsheet_type }}
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
      </q-select>
    </template>
</template>

<script>
import {onMounted, ref, inject, reactive} from "vue";
import {useFlowsheetStore} from "../../stores/flowsheet";
import {useQuasar} from "quasar";

export default {
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'outlined', 'dense'],
  name: "FlowsheetField",
  setup(props, context){
    const options = ref([]);
    const flowsheetStore = useFlowsheetStore();
    const flowsheet = inject('flowsheet');
    const versioned = inject('versioned');
    const patient = inject('patient');

    const $q = useQuasar()

    onMounted(()=> {
      if(props.edit) {
        flowsheetStore.GetFlowsheets(1, 50, null, false, null, versioned.value.uid).then(data => {
          options.value = data.flowsheets
        })
      }
    });

    return {
      patient,
      flowsheet,
      options,
      filterFn (val, update, abort) {
        if (val.length < 2) {
          update();
          return
        }

        flowsheetStore.GetFlowsheets(1, 50, null, false, val, versioned.value.uid).then(data => {
          update(() => {
            options.value = data.flowsheets
          })
        })
      },

      abortFilterFn () {
        // console.log('delayed filter aborted')
      },

    }
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
