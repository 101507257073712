import {date} from "quasar";

export function intersection(a, b) {
    if(a === undefined || b === undefined) return [];
    if(a === null || b === null) return [];
    return a.filter(x => b.includes(x));
}

export function epoch_offset_to_datetime(patient, offset, use_now) {
      let epoch_datetime = new Date(patient.epoch_datetime)
      //let epoch_datetime = date.extractDate(patient.epoch_datetime, 'YYYY-MM-DD[T]HH:mm:ss.SSS');
      if(patient.patient_type==='template' && use_now===true){
         epoch_datetime = new Date()
      }
      return date.addToDate(epoch_datetime, {minutes: offset})
    }
export function datetime_to_epoch_offset(patient, datetime) {
      let epoch_datetime = new Date(patient.epoch_datetime)
      //const epoch_datetime = date.extractDate(patient.epoch_datetime, 'YYYY-MM-DD[T]HH:mm:ss.SSS');
      return date.getDateDiff(datetime, epoch_datetime, 'minutes')
    }
export function datetimedmy_to_epoch_offset(patient, datetime) {
      let epoch_datetime = new Date(patient.epoch_datetime)
      //const epoch_datetime = date.extractDate(patient.epoch_datetime, 'YYYY-MM-DD[T]HH:mm:ss.SSS');
      const datetime_obj = date.extractDate(datetime, 'D MMM YYYY HH:mm');
      return date.getDateDiff(datetime_obj, epoch_datetime, 'minutes')
    }

export function render_offset(offset) {
    let marker = "before start"
    if(offset > 0){
        marker = "after start"
    }
    const o = Math.abs(offset);
    let years = o/(60 * 24 * 365);
    if(years >= 1 && o%(60 * 24 * 365)===0) return `${years} years ${marker}`;
    let months = o/(60 * 24 * 30);
    if(months >= 1 && o%(60 * 24 * 30)===0) return `${months} months ${marker}`;
    let days = o/(60 * 24);
    if(days >= 1 && o%(60 * 24)===0) return `${days} days ${marker}`;
    let hours = o/60;
    if(hours >= 1 && o%60===0) return `${hours} hours ${marker}`;
    return `${o} minutes ${marker}`;

}

export function date_string(patient, offset, use_now) {
      if(offset === undefined || offset === null) {
        return '';
      }
      if(use_now!==true && ["template", "scene"].includes(patient.patient_type)){
          return render_offset(offset);
      }
      return date.formatDate(epoch_offset_to_datetime(patient, offset, use_now), 'DD MMM YYYY')
    }
export function time_string(patient, offset) {
      if(offset === undefined || offset === null) {
        return '';
      }
      return date.formatDate(epoch_offset_to_datetime(patient, offset), 'HH:mm')
    }
export function date_time_string(patient, offset) {
      if(offset === undefined || offset === null) {
        return '';
      }
      if(["template", "scene"].includes(patient.patient_type)){
          return (render_offset(offset));
      }
      return date.formatDate(epoch_offset_to_datetime(patient, offset), 'DD MMM YYYY HH:mm')
    }

export function calc_fields(value){
      let time_number = null;
      let timeframe = null;
      if(value === null || value === undefined || value === ''){
        time_number = '';
        timeframe = 'minutes';
        return {time_number, timeframe};
      }
      let years = -value/(60 * 24 * 365);
      if(years >= 1 && value % (60 * 24 * 365) === 0){
        time_number = years;
        timeframe = 'years';
        return {time_number, timeframe};
      }
      let months = -value/(60 * 24 * 30);
      if(months >= 1 && value % (60 * 24 * 30) === 0){
        time_number = months;
        timeframe = 'months';
        return {time_number, timeframe};
      }
      let weeks = -value/(60 * 24 * 7);
      if (weeks >= 1 && value % (60 * 24 * 7) === 0){
        time_number = weeks;
        timeframe = 'weeks';
        return {time_number, timeframe};
      }
      let days = -value/(60 * 24);
      if (days >= 1 && value % (60 * 24) === 0){
        time_number = days;
        timeframe = 'days';
        return {time_number, timeframe};
      }
      let hours = -value/60;
      if (hours >= 1 && value % 60 === 0){
        time_number = hours;
        timeframe = 'hours';
        return {time_number, timeframe};
      }
      time_number = -value;
      timeframe = 'minutes';
      return {time_number, timeframe};
    }

export let frequencies = {
    'Immediately (STAT)': null,
    'Continuous': null,
    'Every 15 Minutes': 15,
    'Every 30 Minutes': 30,
    'Every Hour (QH)': 60,
    'Every 2 Hours (Q2H)': 60 * 2,
    'Every 3 Hours (Q3H)': 60 * 3,
    'Every 4 Hours (Q4H)': 60 * 4,
    'Every 6 Hours (Q6H)': 60 * 6,
    'Every 8 Hours (Q8H)': 60 * 8,
    'Every 12 Hours (Q12H)': 60 * 12,
    'Twice Daily (BID)': 60 * 12,
    'Three Times Daily (TID)': 60 * 8,
    'Daily': 60 * 24,
    'Every Other Day': 60 * 24 * 2,
    'Once': null,
    'Weekly': 60 * 24 * 7,
    'Monthly': 60 * 24 * 30,
    'Quarterly': 60 * 24 * 30 * 3,
    'Yearly': 60 * 24 * 365,
}