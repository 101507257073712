<template>
    <q-page-container class="bg-grey-4">
      <q-page class="row justify-center items-center">
        <router-view />
      </q-page>
    </q-page-container>
</template>

<script>
export default {
  name: "Default"
}
</script>

<style scoped>

</style>
