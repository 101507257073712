//https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec
//https://axios-http.com/docs/interceptors

import { useRegionStore } from "./region";
import { useUserStore } from "./user";
import router from "../router";

export function intercept(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }
export function error_intercept(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const regionStore = useRegionStore();
    const userStore = useUserStore();
    // all the other error responses
    switch(error.response.status) {
        case 401: // authentication error, logout the user
            console.log("Error Inteceptor: 401 error");
            if(window.location.pathname === '/login') {
                return Promise.reject(error);
            }
            localStorage.removeItem('logged_in');
            regionStore.org = null;
            userStore.user = null;
            userStore.loggedIn = false;
            console.log(router.currentRoute.value);
            console.log(router.currentRoute.value.fullPath);
            router.push('/login?redirect_to=' + router.currentRoute.value.fullPath);
            break;
        default:
            return Promise.reject(error);
    }
  }
