const PatientLayout = () => import('../layouts/PatientLayout.vue');
const PatientFlowsheet = () => import('../views/patient/PatientFlowsheet.vue');
const PatientDetails = () => import('../views/patient/PatientDetails.vue');
const EventDetails = () => import('../views/patient/EventDetails.vue');
const VisitDetails = () => import('../views/patient/VisitDetails.vue');
const VisitHistory = () => import('../views/patient/VisitHistory.vue');
const PatientBarcodes = () => import('../views/patient/PatientBarcodes.vue');
export const patient_routes = [
    {
      path: '/patient/:patient_uid',
      name: 'patient_home',
      component: PatientLayout,
      props: function (route) {
        return {
          preview: route.query.preview,
          patient_uid: route.params.patient_uid,
          grading: route.query.grading,
          simulation: route.query.simulation,
          visit_uid: route.query.visit_uid,
          version_uid: route.query.version_uid,
          activity_uid: route.query.activity_uid,
          course_uid: route.query.course_uid,
        }
      },
      children: [
        {
          path: '',
          name: 'patient_home',
          component: PatientFlowsheet,
          props: function (route) {
            return {
              preview: route.query.preview,
              patient_uid: route.params.patient_uid,
              grading: route.query.grading,
              simulation: route.query.simulation,
              visit_uid: route.query.visit_uid,
              version_uid: route.query.version_uid,
              activity_uid: route.query.activity_uid,
              course_uid: route.query.course_uid,
            }
          },
        },
        {
          path: 'barcodes',
          name: 'patient_barcodes',
          component: PatientBarcodes,
          props: true
        },
        {
          path: 'flowsheet/patient_details',
          name: 'patient_details',
          component: PatientDetails
        },
        {
          path: 'visit_history',
          name: 'visit_history2',
          component: VisitHistory
        },
        {
          path: 'flowsheet/visit_history',
          name: 'visit_history',
          component: VisitHistory,
          props: function (route) {
            return {
              preview: route.query.preview,
              flowsheet_uid: route.params.flowsheet_uid,
              patient_uid: route.params.patient_uid,
              grading: route.query.grading,
              simulation: route.query.simulation,
              visit_uid: route.query.visit_uid,
              version_uid: route.query.version_uid,
              activity_uid: route.query.activity_uid,
              course_uid: route.query.course_uid,
            }
          },
        },
        {
          path: 'visit/:visit_uid',
          name: 'edit_visit',
          component: VisitDetails,
          props: true
        },
        {
          path: 'visit',
          name: 'new_visit',
          component: VisitDetails,
          props: function (route) {
            return {
              preview: route.query.preview,
              patient_uid: route.params.patient_uid,
              visit_uid: route.query.visit_uid,
              grading: route.query.grading,
              simulation: route.query.simulation,
              activity_uid: route.query.activity_uid,
              course_uid: route.query.course_uid,
              is_new: true,
            }
          }
        },
        {
          path: 'flowsheet/:flowsheet_uid',
          name: 'patient_flowsheet',
          component: PatientFlowsheet,
          props: function (route) {
            return {
              preview: route.query.preview,
              flowsheet_uid: route.params.flowsheet_uid,
              patient_uid: route.params.patient_uid,
              grading: route.query.grading,
              simulation: route.query.simulation,
              visit_uid: route.query.visit_uid,
              version_uid: route.query.version_uid,
              activity_uid: route.query.activity_uid,
              course_uid: route.query.course_uid,
            }
          },
        },
        {
          path: 'new_event',
          name: 'new_event',
          component: EventDetails,
          props: true
        },
        {
          path: 'event',
          name: 'edit_event',
          component: EventDetails,
          props: true
        }
      ]
    }
]
