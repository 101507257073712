<template>
  <template v-if="field.list_type!=='Multiple'">
    <template v-if="!edit">
        <span>{{ modelValue }}</span>
    </template>
    <template v-else>
      <q-select
          outlined
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
          :options="options"
          use-input
          @filter="filterFn"
          @filter-abort="abortFilterFn"
      >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <TipTap v-model="flowsheet.lookup_data[scope.opt.lookup_type].lookup_render" :edit="false" :lookup_type="flowsheet.lookup_data[scope.opt.lookup_type]" :data="{lookup: {data: scope.opt.data}}"></TipTap>
            </q-item>
            <!--<span v-for="(v, k) in opt.data">{{ k }}: {{ v }}<br></span>-->
          </template>
          <template v-slot:selected-item="scope">
              <TipTap v-model="flowsheet.lookup_data[scope.opt.lookup_type].lookup_render" :edit="false" :lookup_type="flowsheet.lookup_data[scope.opt.lookup_type]" :data="{lookup: {data: scope.opt.data}}"></TipTap>
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
      </q-select>
    </template>
  </template>
  <template v-else>
    <q-btn v-if="edit" icon="add" round size="sm" color="accent" @click="add_lookup"></q-btn>
    <template v-for="(obj, index) in modelValue">
      <div class="row">
        <div class="col">
          <TipTap v-model="flowsheet.lookup_data[obj.lookup.lookup_type].advanced_render" :edit="false" :lookup_type="flowsheet.lookup_data[obj.lookup.lookup_type]" :data="obj"></TipTap>
        </div>
        <div class="col col-shrink">
          <q-btn v-if="edit && flowsheet.lookup_data[obj.lookup.lookup_type].fields && flowsheet.lookup_data[obj.lookup.lookup_type].fields.length > 0" color="grey-4" round size="sm" class="q-ma-sm" @click="open_lookup(index)">
            <q-icon class="text-primary" name="edit"></q-icon>
          </q-btn>
          <q-btn v-if="edit" color="negative" round size="sm" class="q-ma-sm" @click="delete_lookup(index)">
            <q-icon class="text-white" name="close"></q-icon>
          </q-btn>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import {onMounted, ref, inject, reactive} from "vue";
import {useFlowsheetStore} from "../../stores/flowsheet";
import TipTap from "../TipTap.vue";
import {storeToRefs} from "pinia";
import { usePatientData } from "../../composables/patient_data";
import LookupSearch from "@/components/LookupSearch.vue";
import {generate} from "xksuid";
import {useQuasar} from "quasar";
import AdvancedDialog from "@/components/flowsheets/AdvancedDialog.vue";

export default {
  components: {TipTap},
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'outlined', 'dense', 'patient'],
  name: "LookupField",
  setup(props, context){
    const options = ref([]);
    const flowsheetStore = useFlowsheetStore();
    const flowsheet = inject('flowsheet');

    const $q = useQuasar()

    onMounted(()=> {
      flowsheetStore.GetLookups(props.field.lookup_type, 1, 50, null, false, null).then(data => {
        options.value = data.lookups
      })
    });

    function open_advanced(lookup_data, advanced_obj, index){
      $q.dialog({
        component: AdvancedDialog,

        // props forwarded to your custom component
        componentProps: {
            data_items: [{
                active_field: lookup_data,
                data: advanced_obj,
                //parent: props.flowsheet.lookup_data[obj.lookup.lookup_type],
                //parent_data: obj,
                //advanced_key: advanced_key,
                //field_id: field_id,
            }],
            flowsheet: flowsheet,
          // ...more..props...
        }
      }).onOk(() => {
        console.log(advanced_obj);
        console.log(props.modelValue);
        if(!props.modelValue){
          context.emit('update:modelValue', [advanced_obj])
        }
        else{
          props.modelValue[index] = advanced_obj;
          context.emit('update:modelValue', props.modelValue)
        }
        //context.emit('update:modelValue', props.modelValue)
      }).onCancel(() => {
        console.log('Cancel')
      }).onDismiss(() => {
        console.log('Called on OK or Cancel')
      })
    }

    return {
      flowsheet,
      options,
      filterFn (val, update, abort) {
        if (val.length < 2) {
          update();
          return
        }

        flowsheetStore.GetLookups(props.field.lookup_type,1, 50, null, false, val).then(data => {
          update(() => {
            options.value = data.lookups
          })
        })
      },

      abortFilterFn () {
        // console.log('delayed filter aborted')
      },

      add_lookup(){
          let obj = reactive({})
          $q.dialog({
              component: LookupSearch,
              componentProps: {
                field: props.field,
                flowsheet: flowsheet.value,
              }
          }).onOk((lookup) => {
              console.log(lookup)
              obj['lookup'] = lookup;
              const field_id = generate();
              let lookup_data = flowsheet.value.lookup_data[lookup.lookup_type];
              if(lookup_data.fields && lookup_data.fields.length > 0){
                for(let field of lookup_data.fields) {
                  obj[field.uid] = reactive(null);
                }
                open_advanced(lookup_data, obj, props.modelValue ? props.modelValue.length : 0);
              }
            //obj['field_id'] = field_id;
            else {
              if (!props.modelValue) {
                context.emit('update:modelValue', [obj])
              } else {
                context.emit('update:modelValue', [...props.modelValue, obj])
              }
            }
          }).onCancel(() => {

          }).onDismiss(() => {

          })
      },
      open_lookup(index){
          let obj = props.modelValue[index]
          open_advanced(flowsheet.value.lookup_data[obj.lookup.lookup_type], obj);
      },
      delete_lookup(index){
        context.emit('update:modelValue', props.modelValue.filter((obj, i) => i !== index))
      }
    }
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
