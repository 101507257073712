<template>
  <teleport to="#toolbar_container">
    <div class="col">
      <q-btn icon="document_scanner" flat @click="barcode_dialog=true;"></q-btn>
    </div>
  </teleport>
  <q-dialog v-model="barcode_dialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">Scan Barcode</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
      </q-card-section>
      <q-btn class="absolute-top-right" icon="close" flat round dense v-close-popup></q-btn>
    </q-card>
  </q-dialog>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import { ref } from "vue";

export default {
  name: "BarcodeScanner",
  emits: ["barcode"],
  components: {
    StreamBarcodeReader,
  },
  setup(props ,{ emit }) {
    const barcode_dialog = ref(false);
    let code = "";
    let reading = false;

    const allowedKeys = "`~1234567890-=qwertyuiop[]asdfghjkl;'zxcvbnm,./!@#$%^&*()_+QWERTYUIOP{}ASDFGHJKL:\"ZXCVBNM<>?";

    function isKeyAllowed(key) {
        return allowedKeys.includes(key);
    }

    document.addEventListener('keydown', e => {
        //console.log("Key pressed", isKeyAllowed(e.key), e.key);
      //usually scanners throw an 'Enter' key at the end of read
        if(isKeyAllowed(e.key)) code += e.key;
        if(e.key === 'Enter' && reading){
          console.log("Enter pressed");
          e.preventDefault();
          e.stopPropagation();
          return false;
        }

        //run a timeout of 200ms at the first read and clear everything
        if(!reading) {
            reading = true;
            setTimeout(() => {
                if(code.length >= 6) {
                    //console.log('Reading finished', code);
                    emit('barcode', code);
                }
                code = "";
                reading = false;
            }, 200);  //200 works fine for me but you can adjust it
        }
    }, {capture: true});

    document.addEventListener('click', e => {
        //console.log("Click pressed", e);
        if(reading){
          console.log("Click pressed");
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
    }, {capture: true});

    const onDecode = (result) => {
      console.log(result);
      emit("barcode", result);
      barcode_dialog.value = false;
    };

    const onLoaded = (result) => {
      console.log(result);
    };

    return {
      onDecode,
      onLoaded,
      barcode_dialog,
    };
  },
}
</script>

<style scoped>

</style>