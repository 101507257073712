<template>
  <template v-if="!edit">
    <div v-if="field.list_type==='Multiple' || field.list_type==='Checkbox'">
      <q-chip v-for="item in modelValue">{{ item }}</q-chip>
    </div>
    <span v-else>{{ modelValue }}</span>
  </template>
  <template v-else>
    <q-select v-if="field.list_type==='Single'" :disable="readonly" outlined :options="options" map-options emit-value v-model="data" clearable></q-select>
    <q-select v-else-if="field.list_type==='Multiple'" :disable="readonly" outlined multiple use-chips emit-value :options="options" v-model="data"></q-select>
    <q-option-group v-else :disable="readonly" :options="options" type="checkbox" v-model="data"></q-option-group>
    <q-input v-if="data && field.allow_other && ( data ==='Other' || (field.list_type === 'Multiple' && data.includes('Other')) || field.list_type==='Checkbox' && data.includes('Other'))" :disable="readonly" outlined dense v-model="other_value"></q-input>
  </template>
</template>

<script>
import {onMounted, ref, toRefs, watch} from "vue";

export default {
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'outlined', 'dense', 'patient', 'readonly'],
  name: "SelectField",
  setup(props, context){
    const {field} = toRefs(props);
    const data = ref(null);
    const other_value = ref(null);
    if ((props.modelValue === undefined || props.modelValue === null) && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
      data.value = [];
    }

    onMounted(() => {
      if ((props.modelValue === undefined || props.modelValue === null) && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
        data.value = [];
      }
      else {
        data.value = props.modelValue;
      }
      if ((props.modelValue === undefined || props.modelValue === null) && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
        context.emit('update:modelValue', null)
      }
      if (typeof props.modelValue === 'string' && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
        context.emit('update:modelValue', [props.modelValue])
      }
      if(field.value.allow_other){
        if(field.value.list_type === 'Multiple' || props.field.list_type === 'Checkbox'){
          let extras = [];
          for(d of data.value){
            if(!field.value.field_options.includes(d)){
              extras.push(d);
              data.value = data.value.filter(item => item !== d);
            }
          }
          if(extras.length > 0) {
            other_value.value = ', '.join(extras);
            data.value.push('Other');
          }
          else{
            other_value.value = null;
          }
        }
        else{
          if(data.value!== null && data.value!==undefined && !field.value.field_options.includes(data.value)){
            other_value.value = data.value;
            data.value = 'Other';
          }
        }
      }
    })

    watch([data, other_value], (newVal) => {
      if(newVal[0] === null || newVal[0] === undefined || newVal[0].length === 0) {
        context.emit('update:modelValue', null)
      }
      else {
        let out = newVal[0];
        if(out === 'Other'){
          out = newVal[1];
        }
        else if(out.length && out.includes('Other')){
          out = out.filter((f)=>f!=='Other');
          out.push(newVal[1]);
        }
        context.emit('update:modelValue', out)
      }
    })

    return {
      field,
      data,
      other_value
    }
  },
  computed: {
    options: function options() {
      let op = this.field.field_options.map(function (option) {
        return {
          label: option,
          value: option
        };
      });
      if(this.field.allow_other) {
        op.push({
          label: 'Other',
          value: 'Other'
        });
      }
      return op;
    }
  }
}
</script>

<style scoped>

</style>
