<template>
  <div>
    <q-card class="text-center q-pa-lg">
      <q-btn class="absolute-top-right" icon="close" flat round dense @click="router.back()"/>
      <h4 class="text-primary">Instructor Demo Registration</h4>
      <p class="text-h6 text-secondary">👉 Students: Click the Link Below to Create a New Account 👈</p>
      <q-btn flat color="secondary" to="/signup" class="text-bold">Student Account Registration</q-btn>
      <br>
      <br>
      <q-stepper v-model="step"
          :vertical="$q.screen.lt.sm"
          ref="stepper"
          color="primary"
          animated
      >
        <q-step :name="1" title="Enter Your Email Address" :done="step > 1" :error="email_error?true:false">
          <p class="text-red text-bold">Do not enter your email if you are a Student <br> <br> This page is for Instructors & Educators only</p>
          <q-input label="Email" outlined v-model="email" :error-message="email_error" :error="email_error?true:false"></q-input>
        </q-step>
        <q-step :name="2" title="Verify your Email Address" :done="step > 2">
          <p>An E-mail has been sent to the address: <span class="text-bold">{{ email }}</span> please enter the code below or click the link in the email</p>
          <q-input label="Verification Code" outlined v-model="verification_code"
                   :error-message="verification_error" :error="verification_error?true:false"
                   max-length="6" min-length="6" type="tel" pattern="[0-9]*"
                   :rules="[ val => val.length <= 6 || `Please use 6 digits, current length: ${val.length}`]"></q-input>
        </q-step>
        <q-step :name="3" title="Enter your personal details" :done="step > 3">
          <input type="text" name="username" :value="email" class="hidden" autocomplete="username">
          <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-md-6 text-left">
              <q-input class="q-pa-sm" label="First Name" v-model="first_name" outlined></q-input>
            </div>
            <div class="col-xs-12 col-md-6 text-left">
              <q-input class="q-pa-sm" label="Last Name" v-model="last_name" outlined></q-input>
            </div>
          </div>
          <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-md-6 text-left">
              <q-input class="q-pa-sm" label="School/Organization" v-model="organization" outlined></q-input>
            </div>
            <div class="col-xs-12 col-md-6 text-left">
              <q-input class="q-pa-sm" label="Title" outlined v-model="title"></q-input>
            </div>
          </div>
          <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-md-6 text-left">
              <q-input class="q-pa-sm" label="Password" v-model="password" outlined type="password"></q-input>
            </div>
            <div class="col-xs-12 col-md-6 text-left">
              <q-input class="q-pa-sm" label="Confirm Password" v-model="confirm_password" outlined type="password"
                :rules="[ val => val === password || `Passwords do not match`]"
              ></q-input>
            </div>
          </div>
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                class="q-pa-sm"
                label="How are you planning to utilize an educational EHR?"
                v-model="how_use"
                autogrow
                outlined
              />
            </div>
          </div>
        </q-step>
        <template v-slot:navigation>
          <q-stepper-navigation>
            <q-btn @click="nextStep" color="primary" :label="step === 3 ? 'Create Demo' : 'Continue'" />
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </q-card>
  </div>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
</template>

<script>
import {onMounted, ref} from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from "../stores/user";
import {useRouter} from "vue-router";

export default {
  name: "DemoAccountView",
  props: ['verify', 'email'],
  setup(props, { attrs, slots, emit, expose }){
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const router = useRouter();
    const verification_code = ref('')
    const step = ref( 1 );
    const email = ref( '' );
    const email_error = ref('');
    const verification_error = ref('');
    const loading = ref(false);
    const stepper = ref(null);
    const first_name = ref('');
    const last_name = ref('');
    const organization = ref('');
    const title = ref('');
    const password = ref('');
    const confirm_password = ref('');
    const how_use = ref('');
    const program = ref('')

    function nextStep() {
      if (step.value === 1) {
        loading.value = true;
        userStore.CheckUser(email.value, null, true).then((success) => {
          if (success === true) {
            email_error.value = "";
            stepper.value.next();
          } else {
            email_error.value = success;
          }
          loading.value = false;
        });
      }
      if (step.value === 2) {
        loading.value = true;
        userStore.VerifyUser(email.value, verification_code.value).then((success) => {
          if (success === true) {
            verification_error.value = "";
            stepper.value.next();
          } else {
            verification_code.value = "";
            verification_error.value = success;
          }
          loading.value = false;
        });
      }
      if (step.value === 3) {
        const u = {
          uid: user.value.uid,
          email: email.value,
          first_name: first_name.value,
          last_name: last_name.value,
          username: first_name.value + ' ' + last_name.value,
          password: password.value,
        }
        const new_demo = {
          organization_name: organization.value,
          title: title.value,
          how_use: how_use.value
        }
        loading.value = true;
        userStore.UpdateUser(u, new_demo).then((success) => {
          router.push(userStore.default_route);
          loading.value = false;
        });
      }
    }

    onMounted(() => {
      if(props.verify) verification_code.value = props.verify;
      if(props.email) email.value = props.email;
      if (verification_code.value) {
        stepper.value.goTo(2);
        //step.value = 2;
        //nextStep();
      }
      if(user.value){
        stepper.value.goTo(3);
      }
    });

    return {
      loading,
      userStore,
      user,
      router: router,
      verification_code,
      first_name: first_name,
      last_name: last_name,
      email: email,
      organization: organization,
      title: title,
      how_use: how_use,
      program: program,
      password: password,
      confirm_password: confirm_password,
      step: step,
      email_error: email_error,
      verification_error: verification_error,
      stepper: stepper,
      nextStep
    }
  },
  methods: {

  },
}
</script>

<style scoped>

</style>
