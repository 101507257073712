<template>
  <template v-if="field.field_units.length==1 && field.field_units[0] === 'mmHg'">
    <span v-if="!edit && number">{{ number }}/{{ number2 }}</span>
    <div v-else-if="edit" class="row">
      <q-input :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number">
      <template v-slot:after>
        <span class="text-black">/</span>
      </template>
      </q-input>
      <q-input :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number2">
      </q-input>
    </div>
  </template>
  <template v-else>
    <template v-if="!edit && number">
      <span v-if="unit && unit.split(',').length > 1">{{ number }}<span>&nbsp;{{ unit.split(',')[0] }}</span> {{ number2 }}<span v-if="unit">&nbsp;{{ unit.split(',')[1] }}</span></span>
      <span v-else-if="unit && unit.split('/').length > 1">{{ number }}<span>&nbsp;{{ unit.split('/')[0] }}</span>/<span v-if="number2 && number2 !=='1'">{{ number2 }}&nbsp;</span>{{ unit.split('/')[1] }}</span>
      <span v-else>{{ number }}<span v-if="unit">&nbsp;{{ unit }}</span></span>
    </template>
    <div v-else-if="edit" class="row">
      <template v-if="unit && unit.split(',').length > 1">
        <q-input :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number">
          <template v-slot:after>
            <span class="text-black">{{ unit ? unit.split(',')[0] : '' }}</span>
          </template>
        </q-input>
        <q-input :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number2">
          <template v-slot:after>
            <span class="text-black">{{ unit ? unit.split(',')[1] : ''}}</span>
          </template>
        </q-input>
      </template>
      <template v-else-if="unit && unit.split('/').length > 1">
        <q-input :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number">
          <template v-slot:after>
            <span class="text-black">{{ unit ? unit.split('/')[0] : '' }}/</span>
          </template>
        </q-input>
        <q-input :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number2">
          <template v-slot:after>
            <span class="text-black">{{ unit ? unit.split('/')[1] : ''}}</span>
          </template>
        </q-input>
      </template>
      <q-input v-else :disable="readonly" dense class="col col-grow q-mr-sm" outlined :label="label" v-model="number">
        <template v-if="field.field_units.length==1" v-slot:after>
          <span class="text-black">{{ field.field_units[0] }}</span>
        </template>
      </q-input>
      <q-select :disable="readonly" v-if="field.field_units.length > 1" dense class="col" style="min-width: 40px; max-width: 125px;" outlined v-model="unit" label="unit" :options="field.field_units"></q-select>
    </div>
  </template>
</template>

<script>
import {onMounted, ref, watch} from 'vue'

export default {
  props: ['label', 'modelValue', 'field', 'edit', 'readonly', 'outlined', 'dense', 'patient'],
  emits: ['update:modelValue'],
  name: "NumericField",
  setup(props) {
    const number = ref(null)
    const unit = ref(null)
    const number2 = ref(null)
    onMounted(() => {
      number.value = props.modelValue ? props.modelValue.number : null
      unit.value = props.modelValue ? props.modelValue.unit : null
      number2.value = props.modelValue ? props.modelValue.number2 : null
      if (props.field.field_units.length === 1) {
        unit.value = props.field.field_units[0]
      }
    });
    watch(() => props.field.field_units, (newVal) => {
      if (newVal.length === 1) {
        unit.value = newVal[0]
      }
    });
    watch(() => props.modelValue, (newVal) => {
      if(newVal) {
        number.value = newVal.number
        unit.value = newVal.unit
        number2.value = newVal.number2
      }
      else {
        number.value = null
        unit.value = props.field.field_units.length === 1 ? props.field.field_units[0] : null
        number2.value = null
      }
    }, {immediate: true})

    return {
      number,
      unit,
      number2,
    }
  },
  watch: {
    number(newVal) {
     if((newVal !== null && newVal !== undefined && newVal !== '') || (this.number2 !== null && this.number2 !== undefined && this.number2 !== '')) {
       this.$emit('update:modelValue', {
         number: newVal,
         number2: this.number2,
         unit: this.unit
       })
     }
     else{
        this.$emit('update:modelValue', null)
     }
    },
    number2(newVal) {
     if((this.number !== null && this.number !== undefined && this.number !== '') || (newVal !== null && newVal !== undefined && newVal !== '')) {
        this.$emit('update:modelValue', {
          number: this.number,
          number2: newVal,
          unit: this.unit
        })
     }
     else{
        this.$emit('update:modelValue', null)
     }
    },
    unit(newVal) {
     if((this.number !== null && this.number !== undefined && this.number !== '') || (this.number2 !== null && this.number2 !== undefined && this.number2 !== '')) {
      this.$emit('update:modelValue', {
        number: this.number,
        number2: this.number2,
        unit: newVal
      })
     }
     else{
        this.$emit('update:modelValue', null)
     }
    },
  }
}
</script>

<style scoped lang="scss">
.dtfield{
  border: 1px solid #ccc;
}

.dtfield:hover{
  border-color: #000;
}

.dtfield:focus-within{
  border-color: $primary;
  border-width: 2px;
}

</style>
