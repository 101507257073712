<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
      <q-card>
        <q-card-section>
          <div class="q-mb-md">
            <div v-if="user_embed" class="q-mb-sm">
              <UserIcon :size="sm" :user="user_embed" color="primary" text_color="text-white"></UserIcon>
              {{ user_embed.username }}
            </div>
            <PatientDateTimeField v-if="show_timestamp" error-message="Date Time entries must be unique" :patient="patient" v-model="timestamp" :disable="readonly"></PatientDateTimeField>
          </div>
          <q-card v-for="item in data_items" class="q-mb-md">
            <q-card-section class="row">
              <div class="col" v-if="item.active_field.lookup_render || (item.parent && item.parent.advanced_render)">
                <div v-if="item.active_field.lookup_render">
                  <TipTap v-model="item.active_field.lookup_render" :edit="false" :lookup_type="item.active_field" :data="item.data"></TipTap>
                </div>
                <div v-else-if="item.parent">
                  <TipTap v-model="item.parent.advanced_render" :edit="false" :lookup_type="item.parent" :data="item.parent_data"></TipTap>
                </div>
              </div>
              <q-separator v-if="item.active_field.lookup_render || (item.parent && item.parent.advanced_render)" vertical style="margin-top: -16px; margin-bottom:-16px;" class="q-mr-md"></q-separator>
              <div class="col">
                <q-select v-if="show_action" label="Action" outlined :options="item.parent.actions" option-label="action_name" v-model="item.active_field" class="q-mb-md"></q-select>
                <AdvancedForm :fields="item.active_field.fields" :data="item.data" :readonly="readonly"></AdvancedForm>
              </div>
            </q-card-section>
          </q-card>
        </q-card-section>
        <q-card-actions>
          <q-btn color="secondary" @click="onOKClick()">OK</q-btn>
        </q-card-actions>
        <q-btn class="absolute-top-right" icon="close" flat round dense @click="onCancelClick()"></q-btn>
      </q-card>
  </q-dialog>
</template>

<script>
import AdvancedForm from "./AdvancedForm.vue";
import {useDialogPluginComponent} from "quasar";
import TipTap from "../TipTap.vue";
import {provide, toRef, ref, reactive} from "vue";
import PatientDateTimeField from "@/components/PatientDateTimeField.vue";
import UserIcon from "@/components/UserIcon.vue";

export default {
  name: "AdvancedDialog",
  methods: {reactive},
  components: {UserIcon, PatientDateTimeField, AdvancedForm, TipTap},
  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],
  props: ['readonly', 'flowsheet', 'show_action', 'show_timestamp', 'patient', 'timestamp', 'user_embed', 'data_items'],
  setup (props) {
    provide('flowsheet', toRef(props, 'flowsheet'));
    const timestamp = ref(props.timestamp);

    // REQUIRED; must be called inside of setup()
    const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome

    return {
      timestamp,
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK({timestamp: timestamp.value})
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel
    }
  }
}
</script>

<style scoped>

</style>
