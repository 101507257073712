<template>
  <file-upload
    :disabled="disable"
    class="q-ma-md cursor-pointer"
    extensions="gif,jpg,jpeg,png,webp"
    accept="image/png,image/gif,image/jpeg,image/webp"
    name="avatar"
    post-action="/upload/post"
    drop="body"
    v-model="files"
    @input-filter="inputFilter"
    @input-file="inputFile"
    ref="upload">
    <q-avatar  color="secondary" size="122px" class="cursor-pointer">
              <img v-if="modelValue" :src="modelValue" alt="avatar" class="cursor-pointer"/>
              <p v-else class="text-body2 text-white"><q-icon name="photo_camera"></q-icon><br>Upload<br>Profile Pic</p>
    </q-avatar>
  </file-upload>
  <q-dialog v-model="edit">
    <q-card>
      <vue-cropper
        ref="cropper"
        :src="files[0].url"
        alt="Source Image"
        :aspect-ratio="1 / 1"
      >
      </vue-cropper>
      <q-card-actions>
        <q-btn color="secondary" @click.prevent="editSave">Save</q-btn>
        <q-btn @click.prevent="$refs.upload.clear">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { ref } from 'vue'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { useUserStore } from "../stores/user";

export default {
  name: "ProfilePicUploader",
  props: ['modelValue', 'disable'],
  emits: ['update:modelValue'],
  components: {
    FileUpload, VueCropper
  },
  setup(props, { attrs, slots }) {
    const userStore = useUserStore()
    return {
      userStore,
      files: ref([]),
      edit: ref(false),
      cropper: ref(false)
    }
  },
  watch: {
    edit(value) {
      if (value) {

      }
    }
  },

  methods: {
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      let key = oldFile.name
      this.userStore.GetUploadUrl(key, file.type)
      .then(response => {
        this.$refs.upload.update(oldFile.id, {
          file,
          type: file.type,
          size: file.size,
          active: true,
          putAction: response.url,
          download_url: response.download_url
        });
      });

    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
      if (newFile && oldFile && newFile.success !== oldFile.success) {
          console.log('success', newFile.success, newFile)
          this.$emit('update:modelValue', newFile.download_url);
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
