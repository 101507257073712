<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card style="min-width: 300px">
      <q-card-section class="text-center">
          <q-btn class="absolute-top-right" icon="close" flat round dense @click="onCancelClick()"></q-btn>
        <span class="text-h5">Select {{ field.field_name }}</span>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <q-input outlined dense debounce="500" v-model="filter" placeholder="Search">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-list>
          <q-item clickable v-for="lookup in lookups" :key="lookup.uid" @click="onOKClick(lookup)">
            <TipTap v-model="flowsheet.lookup_data[lookup.lookup_type].lookup_render" :edit="false" :lookup_type="flowsheet.lookup_data[lookup.lookup_type]" :data="{lookup: {data: lookup.data}}"></TipTap>
          </q-item>
          <q-item v-intersection="more_lookups">

          </q-item>
        </q-list>
      </q-card-section>
      <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
      </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, uid } from 'quasar'
import {onMounted, provide, ref, toRef, watch} from 'vue'
import { useUserStore } from "../stores/user";
import { useFlowsheetStore } from "../stores/flowsheet";
import PatientBox from "./PatientBox.vue";
import {storeToRefs} from "pinia";
import TipTap from "./TipTap.vue";

export default {
  name: "LookupSearch",
  components: {PatientBox, TipTap},
  props: ['field', 'flowsheet'],

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],

  setup (props) {
    // REQUIRED; must be called inside of setup()
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome
    const userStore = useUserStore();
    const filter = ref('');
    const page = ref(1);
    const rowsPerPage = ref(10);
    const loading = ref(false);
    const flowsheetStore = useFlowsheetStore();
    const users = ref([]);
    const use_organization = ref(false);
    const lookups = ref([]);
    const pagination = ref({
        rowsNumber: 20,
        page: 0,
        rowsPerPage: 50,
        sortBy: null,
        descending: false,
    })

    provide('flowsheet', toRef(props, 'flowsheet'));

    onMounted(() => {
      /*onRequest({
        pagination: pagination.value,
        filter: undefined
      })*/
    });

    watch(filter, (newVal) => {
      onRequest({
        pagination: pagination.value,
        filter: newVal
      })
    })

    function onRequest(p) {
        const { page, rowsPerPage, sortBy, descending } = p.pagination
        const filter = p.filter
        loading.value = true

        flowsheetStore.GetLookups(
            props.field.lookup_type,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            if(page===1) {
              lookups.value = data.lookups
            }
            else {
              lookups.value = lookups.value.concat(data.lookups)
            }

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

    return {
      onRequest,
      lookups,
      use_organization,
      users,
      filter,
      loading,
      pagination,
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick (lookup) {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK(lookup)
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel,
      more_lookups(entry) {
        if (entry.isIntersecting && lookups.value.length < pagination.value.rowsNumber) {
          pagination.value.page += 1
          onRequest({
            pagination: pagination.value,
            filter: filter.value
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
