<template>
  <file-upload
    v-if="edit"
    multiple
    class="q-ma-md cursor-pointer"
    extensions="gif,jpg,jpeg,png,webp"
    accept="image/png,image/gif,image/jpeg,image/webp"
    name="avatar"
    post-action="/upload/post"
    drop="body"
    v-model="files"
    @input-filter="inputFilter"
    @input-file="inputFile"
    ref="upload">
    <q-btn>
      <p class="text-body2"><q-icon name="add_photo_alternate"></q-icon><br>Click to Upload<br>or drop file here</p>
    </q-btn>
  </file-upload>
    <div class="q-pa-md row items-start q-gutter-md">
      <template v-for="u in modelValue">
        <q-card v-if="!files.map(f => f.download_url).includes(u)" >
          <img :src="u" class="cursor-pointer" style="max-height: 300px; max-width: 100%" @click="selected_image=u; dialog=true">
          <q-card-actions v-if="edit">
            <q-btn round icon="close" color="negative" @click.prevent="remove_url(u)"></q-btn>
          </q-card-actions>
        </q-card>
      </template>
      <q-card v-for="f in files">
        <img :src="f.url" class="cursor-pointer" style="max-height: 300px; max-width: 100%" @click="selected_image=f.url; dialog=true">
        <q-card-actions>
          <q-btn round icon="close" color="negative" @click.prevent="remove_url(u)"></q-btn>
        </q-card-actions>
      </q-card>
    </div>
    <q-dialog
          v-model="dialog"
          :maximized="true"
          transition-show="slide-up"
          transition-hide="slide-down"
        >
        <img :src="selected_image" class="cursor-pointer" style="max-height: 100%; max-width: 100%" @click="dialog=false">
    </q-dialog>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { ref } from 'vue'
import VueCropper from "vue-cropperjs";
import {useUserStore} from "@/stores/user";
import { generate } from 'xksuid';

export default {
  name: "FileField",
  props: ['modelValue', 'edit', 'readonly', 'outlined', 'patient', 'dense', 'field'],
  emits: ['update:modelValue'],
  components: {
    FileUpload, VueCropper
  },
  setup(props, context) {
    const userStore = useUserStore()
    return {
      generate,
      userStore,
      files: ref([]),
      cropper: ref(false),
      dialog: ref(false),
      selected_image: ref(null),
      remove_url(url) {
        this.files.splice(this.files.findIndex(f => f.download_url === url), 1)
        context.emit('update:modelValue', props.modelValue.filter(u => u !== url))
      },
    }
  },

  methods: {
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      let key = oldFile.name
      this.userStore.GetUploadUrl(key, file.type)
      .then(response => {
        this.$refs.upload.update(oldFile.id, {
          file,
          type: file.type,
          size: file.size,
          active: true,
          putAction: response.url,
          download_url: response.download_url
        });
      });

    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        let key = newFile.name
        this.userStore.GetUploadUrl(key, newFile.type)
        .then(response => {
          this.$refs.upload.update(newFile.id, {
            newFile,
            type: newFile.type,
            size: newFile.size,
            active: true,
            putAction: response.url,
            download_url: response.download_url
          });
        });
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
      if (newFile && oldFile && newFile.success !== oldFile.success) {
          console.log('success', newFile.success, newFile)
          if(this.modelValue){
            this.$emit('update:modelValue', this.modelValue.concat(newFile.download_url));
          }
          else{
            this.$emit('update:modelValue', [newFile.download_url]);
          }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }
  }

}
</script>

<style scoped>

</style>