<template>
  <span v-if="!edit">{{ modelValue }}</span>
  <q-input v-else class="col-4" :disable="readonly" outlined mask="##:##" :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)"></q-input>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'readonly'],
  name: "TimeField"
}
</script>

<style scoped>

</style>
