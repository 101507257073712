import {defineStore, acceptHMRUpdate, storeToRefs} from 'pinia'
import axios from "axios";
import {useRegionStore} from "./region";
import {intercept, error_intercept} from "./restapi";
import {Notify} from "quasar";
import { date } from 'quasar'

axios.defaults.withCredentials = true

const default_api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
default_api.interceptors.response.use(intercept, error_intercept);

export const usePatientStore = defineStore('patient', {
    state: () => {
        return {
            patient: null,
            flowsheets: null,
            viewing_visit: null,
            default_flowsheet: null,
            activity: null,
            selected_sim: null,
            students: []
            }
    },
    actions: {
       patient_time(now){
          if(this.patient.activity && this.patient.activity.current_sim && this.patient.activity.current_sim.simulation_clock_offset !== null){
            const seconds = this.patient.activity.current_sim.simulation_clock_offset * 60;
            if(this.patient.activity.current_sim.status === 'running') {
              return date.addToDate(now, {seconds: seconds})
            }
            else {
              return date.addToDate(this.patient.activity.current_sim.paused_datetime, {seconds: seconds})
            }
          }

          if(this.patient.patient_clock_offset){
              return date.addToDate(now, {minutes: this.patient.patient_clock_offset})
          }
          return now;
       },
        async api(){
            try{
                const regionStore = useRegionStore();
                return await regionStore.api();
            }
            catch(e){
                if(e.message === "No org set") {
                    return default_api;
                }
                throw e;
            }
        },
        async GetFlowsheets(version, visit_type) {
            const api = await this.api();
            const response = await api.get(`/patient_flowsheets/${version}/${visit_type}`);
            if (response.status === 200) {
                if(response.data.chart){
                    this.default_flowsheet = response.data.chart.default_flowsheet;
                    this.flowsheets = response.data.chart.flowsheets;
                    if(!this.default_flowsheet){
                        this.default_flowsheet = this.flowsheets[0].uid;
                    }
                }
                else{
                    this.flowsheets = [];
                }
                return response.data;
            }
        },
        async GetPatientDataForFlowsheet(patient_uid, flowsheet_uid, version, backup_version){
            const api = await this.api();
            let url = `/patient/${patient_uid}/flowsheet/${flowsheet_uid}`;
            if(this.viewing_visit) {
                url = `/patient/${patient_uid}/flowsheet/${flowsheet_uid}/visit/${this.viewing_visit.uid}`;
            }
            const response = await api.get(url, {params: {version: version, backup_version: backup_version}});
            if (response.status === 200) {
                return response.data;
            }
        },
        async SaveData(data){
            const api = await this.api();
            const response = await api.post(`/patient/${this.patient.uid}/flowsheet/${data.flowsheet_uid}`, data);
            Notify.create({
              color: 'positive',
              message: `Saved Patient Data`,
              icon: 'save'
            })

            return response.data;
        },
        async GetTemplatePatients(page, rowsPerPage, sortBy, descending, filter, filtering) {
            const api = await this.api();
            const response = await api.get(`/template_patients`, {
                params:
                    {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, filtering: filtering}
            });
            if (response.status === 200) {
                this.flowsheets = response.data.flowsheets;
                return response.data;
            }
        },
        async SavePatient(data, update){
            const api = await this.api();
            const response = await api.post(`/patient`, {patient: data});
            if(update !==false) {
                this.patient = response.data.patient;
                this.patient.activity = response.data.activity;
                if (this.patient.visit) {
                    this.viewing_visit = this.patient.visit;
                } else {
                    this.viewing_visit = null;
                }
            }
            Notify.create({
              color: 'positive',
              message: `Saved Patient ${this.patient.name} successfully`,
              icon: 'save'
            })

            return response.data;
        },
        async DeletePatient(patient){
            const api = await this.api();
            const response = await api.delete(`/patient/${patient.uid}`)
            Notify.create({
              color: 'info',
              message: `Patient ${patient.name} deleted successfully`,
              icon: 'delete'
            })

        },
        SetPatient(patient){
            this.patient = patient;
            if(this.patient) {
                this.viewing_visit = patient.visit;
            }
            else{
                this.viewing_visit = null;
            }
        },
        async GetPatient(uid, force){
            if(!this.patient || this.patient.uid !== uid || force === true) {
                const api = await this.api();
                const response = await api.get(`/patient/${uid}`);
                if (response.status === 200) {
                    this.patient = response.data.patient;
                    this.patient.readonly = response.data.readonly;
                    this.patient.activity = response.data.activity;
                    this.patient.activity_student_patient = response.data.activity_student_patient;
                    if (this.patient.visit) {
                        this.viewing_visit = this.patient.visit;
                    } else {
                        this.viewing_visit = null;
                    }
                    return response.data;
                }
            }
        },
        async LaunchPatient(activity_uid, course_uid, patient){
            this.patient = null;
            if(patient.patient_type === 'template'){
                const api = await this.api();
                const response = await api.post(`/patient/instance/${patient.uid}`, {activity_uid: activity_uid, course_uid: course_uid, timezone_offset: new Date().getTimezoneOffset()});
                if (response.status === 200) {
                    this.patient = response.data.patient;
                    this.patient.activity = response.data.activity;
                    this.viewing_visit = this.patient.visit;
                    return `/patient/${response.data.patient.uid}`;
                }
            }
            else {
                return `/patient/${patient.uid}`;
            }
        },
        async copy_patient(patient_uid){
            this.patient = null;
                const api = await this.api();
                const response = await api.post(`/patient/copy_patient/${patient_uid}`, {});
                if (response.status === 200) {
                    this.patient = response.data.patient;
                    this.viewing_visit = this.patient.visit;
                    return response.data;
                }

        },
        async SaveVisit(visit){
            const api = await this.api();
            const response = await api.post(`/patient/${this.patient.uid}/visit`, {visit: visit});
            this.viewing_visit = response.data.visit;
            if((!this.patient.visit && !this.viewing_visit.end_timestamp) || (this.patient.visit && this.patient.visit.uid === this.viewing_visit.uid)) {
                this.patient.visit = response.data.visit;
            }
            if(this.patient.visit && this.patient.visit.uid === this.viewing_visit.uid && this.patient.visit.end_timestamp){
                this.patient.visit = null;
            }
            Notify.create({
              color: 'positive',
              message: `Saved Visit successfully`,
              icon: 'save'
            })

            return response.data;
        },
        async DeleteVisit(visit){
            const api = await this.api();
            const response = await api.delete(`/patient/${this.patient.uid}/visit/${visit.uid}`)
            if(this.viewing_visit && this.viewing_visit.uid === visit.uid){
                this.viewing_visit = null;
            }
            if(this.patient.visit && this.patient.visit.uid === visit.uid){
                this.patient.visit = null;
            }
            Notify.create({
              color: 'info',
              message: `Visit deleted successfully`,
              icon: 'delete'
            })

        },
        async EndVisit(end_timestamp){
            const api = await this.api();
            const response = await api.post(`/patient/${this.patient.uid}/visit/${this.viewing_visit.uid}/end`, {end_timestamp: end_timestamp});
            this.patient.visit = null;
            this.viewing_visit = null;
            Notify.create({
              color: 'positive',
              message: `Visit Ended successfully`,
              icon: 'save'
            })

            return response.data;
        },
        async GetVisit(visit_uid){
            const api = await this.api();
            const response = await api.get(`/patient/${this.patient.uid}/visit/${visit_uid}`);
            return response.data;
        },
        async GetVisits(){
            const api = await this.api();
            const response = await api.get(`/patient/${this.patient.uid}/visits`);
            return response.data;
        },
        async SaveGrade(asp){
            const api = await this.api();
            const response = await api.post(`/grade`, {asp: asp});
            return response.data;
        },
        async addScene(patient_uid, scene_uid){
            const api = await this.api();
            const response = await api.post(`/patient/${patient_uid}/add_scene/${scene_uid}`, {});
            Notify.create({
              color: 'positive',
              message: `Event added successfully`,
              icon: 'save'
            })
            return response.data;
        }
    },
});


if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePatientStore, import.meta.hot))
}

