<template>
  <q-toolbar v-if="edit && editor">
    <q-btn icon="format_bold" :text-color="editor.isActive('bold') ? 'secondary' : 'black'" @click="editor.chain().focus().toggleBold().run()"></q-btn>
    <q-btn icon="format_italic" :text-color="editor.isActive('italic') ? 'secondary' : 'black'" @click="editor.chain().focus().toggleItalic().run()"></q-btn>
    <q-btn icon="format_underlined" :text-color="editor.isActive('underline') ? 'secondary' : 'black'" @click="editor.chain().focus().toggleUnderline().run()"></q-btn>
    <q-btn icon="strikethrough_s" :text-color="editor.isActive('strike') ? 'secondary' : 'black'" @click="editor.chain().focus().toggleStrike().run()"></q-btn>
    <q-btn-dropdown v-if="show_lookup_type!==undefined" label="Lookup Fields">
      <q-list>
        <q-item v-for="field in lookup_type.lookup_fields" clickable v-close-popup @click="editor.chain().focus().insertContent(gen(field.uid)).run()">
          <q-item-section>
            <q-item-label>{{field.field_name}}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-btn-dropdown v-if="show_advanced!==undefined" label="Advanced Fields">
      <q-list>
        <q-item v-for="field in lookup_type.fields" clickable v-close-popup @click="editor.chain().focus().insertContent(gen_field(field.uid)).run()">
          <q-item-section>
            <q-item-label>{{field.field_name}}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-toolbar>
  <q-field v-if="edit" outlined @focus="editor.chain().focus('end').run()">
    <template v-slot:control>
      <editor-content :editor="editor" />
    </template>
  </q-field>
  <editor-content v-else :editor="editor" />
</template>

<script>
import {onMounted, watch, ref, provide, toRefs, toRef} from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import LookupRender from '../composables/lookupnode'
import AdvancedRender from '../composables/advancednode'
import Underline from '@tiptap/extension-underline';

export default {
  name: "TipTap",
  components: {
    EditorContent,
  },
  props: ['modelValue', 'edit', 'lookup_type', 'data', 'show_advanced', 'show_lookup_type'],
  emits: ['update:modelValue'],
  setup(props, ctx) {
    const editor = useEditor({
      editable: props.edit,
      content: '',
      extensions: [
        StarterKit,
        LookupRender,
        AdvancedRender,
        Underline,
      ],
      onUpdate: () => {
        // HTML
        //ctx.emit('update:modelValue', editor.value.getHTML())

        // JSON
        ctx.emit('update:modelValue', editor.value.getJSON())
      },
      injectCSS: false,
    })

    provide('lookup_type', toRef(props, 'lookup_type'));
    provide('data', toRef(props, 'data'));

    onMounted(() => {
      const isSame = JSON.stringify(editor.value.getJSON()) === JSON.stringify(props.modelValue)
      if (isSame) {
        return
      }
      editor.value.commands.setContent(props.modelValue, false)
    })

    watch(props, (newVal) => {
      //const isSame = editor.value.getHTML() === newVal
      // JSON
      const isSame = JSON.stringify(editor.value.getJSON()) === JSON.stringify(newVal.modelValue)
      if (isSame) {
        return
      }
      editor.value.commands.setContent(newVal.modelValue, false)
    })
    return {
      editor,
      gen(txt){
        return [{
          type: 'LookupRender',
          attrs: {
            field: txt
          }
        },{type: 'text', text: ' '}]
      },
      gen_field(txt){
        return [{
          type: 'AdvancedRender',
          attrs: {
            field: txt
          }
        },{type: 'text', text: ' '}]
      },
    }
  },
}
</script>

<style>
  .ProseMirror:focus {
    outline: none;
  }
  .ProseMirror:active{
    outline: none;
  }
  .ProseMirror:focus-visible {
    outline: none;
  }
  .ProseMirror-Focused {
    outline: none;
  }
</style>
