<template>
  <q-card>
    <q-card-section>
      <div class="row text-h5 text-primary q-mb-md">Courses</div>
      <div class="row q-col-gutter-lg">
        <div class="col-12" v-for="course in courses">
          <q-card class="col">
            <q-card-section class="row bg-amber">
              <div class="col">
              <span class="text-h6 text-bold text-primary">{{ course.course_short_name }}</span><br>
              <span class="text-subtitle2">{{ course.course_name }}</span><br>
              <span class="text-bold text-primary">End Date: </span>{{ date.formatDate(course.end_date, 'DD MMM YYYY HH:mm' ) }}<br>
              <span v-if="course.program_uid && userStore.get_user_program(org.uid, course.program_uid)"><span class="text-bold text-primary">Program: </span>{{ userStore.get_user_program(org.uid, course.program_uid).program_name }}<br></span>
              <span v-if="course.campus_uid && userStore.get_user_campus(org.uid, course.campus_uid)"><span class="text-bold text-primary">Campus: </span>{{ userStore.get_user_campus(org.uid, course.campus_uid).campus_name}}<br></span>
             </div>
              <div class="col-shrink text-right">
                <q-btn color="primary" :to="`/course/${course.uid}`">Manage Course</q-btn>
                <q-input v-model="course.course_code" dense outlined disabled label="Course Code" class="q-mt-sm">
                  <template v-slot:append>
                    <q-icon name="content_copy" @click="copy(course)"></q-icon>
                  </template>
                </q-input>
              </div>
            </q-card-section>
            <q-markup-table v-if="$q.screen.gt.sm">
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Patients</th>
                  <th>Due Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="activity in course.activities">
                  <td>{{ activity.activity_name }}</td>
                  <td style="max-width: 500px;">
                    <div style="white-space: break-spaces">
                      <q-chip size="sm" color="secondary" class="text-white" v-for="patient in activity.template_patients">{{ patient.name }}</q-chip>
                    </div>
                  </td>
                  <td>{{ date.formatDate(activity.activity_end_date, 'DD MMM YYYY HH:mm' ) }}</td>
                  <td class="q-table--col-auto-width">
                    <q-btn rounded size="sm" :to="`/course/${activity.course_uid}/activity/${ activity.uid }`" color="primary">Grade &amp; Manage</q-btn>
                    <q-btn class="q-ma-sm" rounded color="accent" :to="`/student/course/${activity.course_uid}/activity/${activity.uid}`" size="sm">Student View</q-btn>
                    <q-btn v-if="activity.activity_type==='Simulation'" class="q-ma-sm" rounded :to="`/sim/course/${activity.course_uid}/activity/${activity.uid}`" size="sm" icon="tune">Simulation Control</q-btn>
                  </td>
                </tr>
                <tr v-if="!course.activities || course.activities.length === 0">
                  <td colspan="4" class="text-center">
                    <router-link :to="`/new_activity?course_uid=${course.uid}`">Your Course doesn't have any Activities.  Click here to create a New Activity.</router-link>
                  </td>
                </tr>
                <tr v-if="course.activity_count > 5">
                  <td colspan="4" class="text-center"><q-btn :to="`/course/${course.uid}`">View All Activities</q-btn></td>
                </tr>
              </tbody>
            </q-markup-table>
            <template v-else>
              <q-card-section v-for="(activity, index) in course.activities" :class="index % 2 == 0 ? 'bg-grey-4' : ''">
                <span class="text-h6">{{ activity.activity_name }}</span><br>
                <q-chip size="sm" color="secondary" class="text-white" v-for="patient in activity.template_patients">{{ patient.name }}</q-chip><br>
                <span v-if="activity.activity_end_date" class="text-bold">Due:&nbsp;</span>{{ date.formatDate(activity.activity_end_date, 'DD MMM YYYY HH:mm' ) }}<br>
                <q-btn rounded size="sm" :to="`/course/${activity.course_uid}/activity/${ activity.uid }`" color="primary">Grade &amp; Manage</q-btn>
                <q-btn class="q-ma-sm" rounded color="accent" :to="`/student/course/${activity.course_uid}/activity/${activity.uid}`" size="sm">Student View</q-btn>
                <q-btn v-if="activity.activity_type==='Simulation'" class="q-ma-sm" rounded :to="`/sim/course/${activity.course_uid}/activity/${activity.uid}`" size="sm" icon="tune">Simulation Control</q-btn>
              </q-card-section>
            </template>
          </q-card>
        </div>
        <div v-if="!loading && courses.length === 0" class="flex-center">
          <q-card>
            <q-card-section class="text-bold text-center">
              Get Started Below<br>
              <span class="text-h5">&#128071</span>
            </q-card-section>
            <q-card-section class="text-center">
              <q-btn-dropdown color="primary" label="Add Course" class="q-mr-md">
                <q-list>
                  <q-item clickable v-close-popup @click="new_course">
                    <q-item-section>
                      <q-item-label>New Course</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="add_course">
                    <q-item-section>
                      <q-item-label>Course From Template</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </q-card-section>
            <q-card-section>
              For more information, Click to View our <a href="https://help.chartflow.io/articles/instructor-quick-start-guide" target="_blank">Instructor Quick Start Guide</a>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-card-section>
  </q-card>
  <q-inner-loading
    :showing="loading"
    label="Please wait..."
    label-class="text-secondary"
    label-style="font-size: 1.1em"
  />

</template>

<script>
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";
import {storeToRefs} from "pinia";
import {onMounted, ref} from "vue";
import {copyToClipboard, date, useQuasar} from "quasar";
import TemplateCourseSelector from "@/components/TemplateCourseSelector.vue";
import {useRouter} from "vue-router";

export default {
  name: "InstructorDashboard",
  setup() {
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { user } = storeToRefs(userStore);
    const { org } = storeToRefs(regionStore)
    const courses = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const router = useRouter();

    const $q = useQuasar()

    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'course_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined
      })
    });

    function onRequest(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination
        const filter = props.filter
        loading.value = true

        regionStore.GetStudentDashboardActivities().then(data => {
            courses.value = []
            const school = user.value.schools.find(school => school.organization_uid === org.value.uid)
            if(school) {
              for (let course of school.courses) {
                if(course.end_date && date.getDateDiff(course.end_date, new Date(), 'minutes') <= 0) continue;
                const course_data = data.data.find(course_data => course_data.course_uid === course.uid)
                if (course_data){
                  course.activities = course_data.activities;
                  course.activity_count = course_data.activity_count;
                }

                courses.value.push(course)
              }
            }
            loading.value = false
        });
    }

    return {
      userStore,
      org,
      show: true,
      courses,
      loading,
      date,
      regionStore,
      copy(course){
        copyToClipboard(course.course_code);
        $q.notify({
          color: 'positive',
          message: `Copied Course Code to Clipboard`,
          icon: 'save'
        })
      },
      add_course() {
          $q.dialog({
            component: TemplateCourseSelector,

            // props forwarded to your custom component
            componentProps: {

              // ...more..props...
            }
          }).onOk((sel_course) => {
            console.log('OK');
            console.log(sel_course);
            loading.value = true;
            regionStore.AddCourse(sel_course).then(data => {
              loading.value = false;
              if(data.course){
                courses.value.push(data.course);
              }
              if(data.user){
                user.value = data.user;
              }
              router.push(`/course/${data.course.uid}?added=true`)
            })

          }).onCancel(() => {
            console.log('Cancel')
          }).onDismiss(() => {
            console.log('Called on OK or Cancel')
          })
      },
      new_course(){
        router.push({path: '/course_new',
        })
      },

    }
  },
}
</script>

<style scoped>

</style>
