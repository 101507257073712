<template>
  <div style="width: 100%" class="bg-primary">
    <div class="relative-position q-ma-xl">
      <!--<q-img src="/patient_list_screenshot.png" class="absolute-left" width="25%" fit="scale-down"/>-->
      <div class="row justify-center">
        <div class="col-sm-6 col-12 text-center" style="max-width: 1000px">
          <!--<q-card-section>
            <img alt="Chartflow Logo" height="50" src="/logo.svg" class="q-mt-md q-pl-sm">
          </q-card-section>-->
          <h1 class="text-h3 text-white text-bold">
            The Most Powerful EHR<br> for Education &amp; Simulation
          </h1>
          <h2 class="q-pt-xl text-h5 text-white">
            Designed for Real-Time Simulation, Nursing,<br> Allied Health, Medical Assisting, and more.
          </h2>
          <h3 class="q-pt-xl text-h5 text-white text-italic">
            Starts from $15 / Student
          </h3>
          <div class="q-pt-xl flex-center">
            <q-btn size="xl" color="accent" to="/demo" class="text-bold">Free Instructor Demo</q-btn>
          </div>
        </div>
        <q-img src="/patient_chart_screenshot.png" class="gt-sm col-6" fit="scale-down" alt="Chartflow Screenshot"/>
      </div>
    </div>
  <div class="row bg-white">
    <div class="col q-ma-lg" style="font-size: 18px;">
      <h3 style="font-size: 38px" class="text-primary text-bold">Philosophy &amp; Mission</h3>
      <p class="q-mt-lg">
        ChartFlow is an educational EHR system designed for all healthcare programs.
      </p>
      <p>
        ChartFlow accelerates the practice readiness of future healthcare providers worldwide. We do this by creating products that are accessible and affordable for all disciplines and geographies.
      </p>
      <p>
        The educator and learner experience drives every decision we make. With a tenured team spanning the globe and representing multiple disciplines we understand learning is not a single solution, but an evolving continuum. We believe in relationships that are transparent and accessible—reflected in everything we do from service to pricing with a focus on improving outcomes while lowering costs.
      </p>
      <p>
        We are doing our part to lower the total cost of learning and look forward to earning the chance to serve your future practitioners.
      </p>
    </div>
    <img class="gt-sm col" src="/svgdoctors.svg" alt="doctors"/>
  </div>
  <div id="features" class="row flex-center">
    <h3 style="font-size: 38px" class="text-primary text-bold text-white q-pa-xs">Features</h3>
  </div>
  <div class="row items-stretch">
    <div class="col-12 col-md-4 q-pa-md">
      <q-card class="full-height">
        <q-card-section class="text-center text-h6 text-secondary text-bold">
          <img src="/Simulation.svg" height="80"><br>
          Simulation
        </q-card-section>
        <q-card-section>
          <q-list style="font-size: 18px;">
            <q-item class="q-mb-md">
              <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
              <q-item-section>Realistic MAR with Barcode Scanning Capabilities</q-item-section>
            </q-item>
            <q-item class="q-mb-md">
              <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
              <q-item-section>Real-Time Simulation Control allows for unfolding scenarios, time jumps, and 1-click reset between new sims</q-item-section>
            </q-item>
            <q-item class="q-mb-md">
              <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
              <q-item-section>Students can work individually or in groups</q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-md-4 q-pa-md">
      <q-card class="full-height">
      <q-card-section class="text-center text-h6 text-secondary text-bold">
        <img src="/Clinical.svg" height="80"><br>
        Clinical
      </q-card-section>
      <q-card-section>
        <q-list style="font-size: 18px;">
          <q-item class="q-mb-md">
            <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
            <q-item-section>ChartFlow was designed to simulate both Inpatient &amp; Outpatient EHRs</q-item-section>
          </q-item>
          <q-item class="q-mb-md">
            <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
            <q-item-section>Students are able to create new patients to submit clinical assignments</q-item-section>
          </q-item>
          <q-item class="q-mb-md">
            <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
            <q-item-section>Obstetrics, Respiratory, Code Blue, and other speciality documentation included</q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
    </div>
    <div class="col-12 col-md-4 q-pa-md">
      <q-card class="full-height">
      <q-card-section class="text-center text-h6 text-secondary text-bold">
        <img src="/flexible-content.svg" height="80"><br>
        Classroom
      </q-card-section>
      <q-card-section>
        <q-list style="font-size: 18px;">
          <q-item class="q-mb-md">
            <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
            <q-item-section>60+ pre-built Patient Case Studies—edit our scenarios or build your own!</q-item-section>
          </q-item>
          <q-item class="q-mb-md">
            <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
            <q-item-section>Assign patients for Case Study Review, Q&amp;A, and more</q-item-section>
          </q-item>
          <q-item class="q-mb-md">
            <q-item-section avatar><q-icon color="primary" name="arrow_forward_ios"/></q-item-section>
            <q-item-section>Grade Student assignments quickly via ChartFlow's Modern Dashboard</q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
    </div>
  </div>
  <div class="row flex-center bg-white">
    <h3 style="font-size: 38px" class="text-center text-primary text-bold">Healthcare Programs</h3>
  </div>
  <div class="row flex-center bg-white q-pa-md">
    <p style="font-size: 16px; text-align: center">We’ve designed our EHR with all healthcare disciplines in mind, including Colleges &amp; Universities, Allied Health, Technical Programs, and Organizations looking to up-skill workers.</p>
  </div>
  <div class="row flex-center bg-white q-col-gutter-xl justify-around q-pb-xl" style="font-size: 18px; overflow-wrap: break-word;">
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/nursing.svg"/></span>&nbsp;Nursing</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/medical-assistant.svg"/></span>&nbsp;Medical Assistant</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/physician-assistant.svg"/></span>&nbsp;Physician Assistant</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/emergency-medicine.svg"/></span>&nbsp;EMT / Paramedic</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/allied-health.svg"/></span>&nbsp;Allied Health</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/cna.svg"/></span>&nbsp;Certified Nursing Assistant</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/patient-care-technician.svg"/></span>&nbsp;Patient Care Technician</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/medical-doctor.svg"/></span>&nbsp;MD / DO</q-card></div>
    <div class="col-12 col-sm-4"><q-card class="q-mx-xl q-pa-md" style="border: 0.5px solid #404363;"><span style="font-size: 32px;"><q-icon name="img:/programs/interprofessional-2.svg"/></span>&nbsp;Interprofessional Education</q-card></div>
  </div>
  <div id="pricing" class="row flex-center">
    <h3 style="font-size: 38px" class="text-white text-bold q-pa-xs">Pricing</h3>
  </div>
  <div class="row text-center">
    <div class="col-12 col-md q-pa-md">
      <q-card class="full-height q-pb-lg">
        <q-card-section class="text-h4 text-white bg-accent">
          Free
        </q-card-section>
        <q-card-section>
          <span class="text-h3 text-accent">Free</span><br>
          For Instructors &amp; Faculty
          <br>
          <br>
        </q-card-section>
        <q-card-actions class="flex-center absolute-bottom">
          <q-btn class="bg-accent text-white text-bold" to="/demo">Free Instructor Demo</q-btn>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col-12 col-md q-pa-md">
      <q-card class="full-height q-pb-lg">
        <q-card-section class="text-h4 text-white" style="background-color: #2f76d3">
        6 Months
      </q-card-section>
      <q-card-section>
        <span class="text-h3 text-accent">$15</span><br>
        / Student
      </q-card-section>        
      <q-card-actions class="flex-center absolute-bottom">
        <q-btn style="background-color: #2f76d3; color: white; font-weight: bold" target="_blank" href="mailto:sales@chartflow.io">Contact Sales</q-btn>
      </q-card-actions>
    </q-card>
    </div>
    <div class="col-12 col-md q-pa-md">
      <q-card class="full-height q-pb-lg">
        <q-card-section class="text-h4 text-white" style="background-color: #2f76d3">
        12 Months
      </q-card-section>
      <q-card-section>
        <span class="text-h3 text-accent">$25</span><br>
        / Student
      </q-card-section>
      <q-card-actions class="flex-center absolute-bottom">
        <q-btn style="background-color: #2f76d3; color: white; font-weight: bold" target="_blank" href="mailto:sales@chartflow.io">Contact Sales</q-btn>
      </q-card-actions>
    </q-card>
    </div>
    <div class="col-12 col-md q-pa-md">
      <q-card class="full-height q-pb-lg">
        <q-card-section class="text-h4 text-white" style="background-color: #2f76d3">
        24 Months
      </q-card-section>
      <q-card-section>
        <span class="text-h3 text-accent">$45</span><br>
        / Student
      </q-card-section>
      <q-card-actions class="flex-center absolute-bottom">
        <q-btn style="background-color: #2f76d3; color: white; font-weight: bold" target="_blank" href="mailto:sales@chartflow.io">Contact Sales</q-btn>
      </q-card-actions>
    </q-card>
    </div>
  </div>
  <div class="row flex-center bg-primary text-white q-pa-md">
    <h6>If you have any questions or would like to speak with our team, please contact <a style="color: white" href="mailto:sales@chartflow.io">sales@chartflow.io</a></h6>
  </div>
  <div id="contact" class="row flex-center q-ma-md">
    <q-card>
      <q-card-section style="font-size: 38px" class="text-primary text-center">
        Contact Us
      </q-card-section>
      <q-card-section>
        <form @submit.prevent="contact_us()">
          <!-- Form Name -->
          <div v-if="show_form" class="row">
           <div class="col-12 col-md">
             <q-input outlined v-model="contact.first_name" id="name" title="" name="name" type="text" class="q-ma-md" required="true" label="First Name"></q-input>
             <q-input outlined v-model="contact.last_name" id="last_name" title="" name="last_name" type="text" class="q-ma-md" required="true" label="Last Name"></q-input>
             <q-input outlined v-model="contact.email" id="email" title="" name="email" type="email" style="background-color:#fff;" class="q-ma-md" required="true" label="Email"></q-input>
           </div>
           <div class="col-12 col-md">
             <q-input outlined v-model="contact.role" id="role" title="" name="role" type="text" style="background-color:#fff;" class="q-ma-md" label="Role"></q-input>
             <q-input outlined v-model="contact.school" id="School/Organization" title="" name="School/Organization" type="text" style="background-color:#fff;" class="q-ma-md" label="School/Organization"></q-input>
             <q-input outlined v-model="contact.program" id="Program_Type" title="" name="Program_Type" type="text" style="background-color:#fff;" class="q-ma-md" label="Program Type"></q-input>
           </div>
          </div>
           <q-input v-if="show_form" outlined v-model="contact.help" autogrow id="How_can_we_help?" name="How_can_we_help?" type="textarea" class="q-ma-md" label="How can we help?"></q-input>
          <div v-if="show_form" class="text-center">
           <q-btn color="secondary" type="submit">Submit</q-btn>
          </div>
         <div v-if="!show_form" class="text-h5 text-center">Thank You<br>We'll be in touch within 24 hours</div>
        </form>
      </q-card-section>
    </q-card>
  </div>
  <!--<div class="row flex-center q-ma-lg"><img alt="Chartflow Logo" height="30" src="/logo.svg" class="q-mt-md q-pl-sm"></div>-->
  <div class="row flex-center q-ma-lg q-mt-xl">
    <q-btn size="xl" color="accent" to="/demo" class="text-bold">Free Instructor Demo</q-btn>
  </div>
</div>
<div class="bg-white text-primary row q-pa-lg" style="width: 100%">
  <div class="col">
    Copyright 2024 ChartFlow LLC.
  </div>
  <div class="col text-right">
    <router-link to="/privacy">Privacy Policy</router-link>&nbsp;
    <router-link to="/terms">Terms of Service</router-link>
  </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useMeta } from 'quasar'
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";

const metaData = {
  // sets document title
  title: 'ChartFlow',
  // optional; sets final title as "Index Page - My Website", useful for multiple level meta
  titleTemplate: title => `${title} - The Most Powerful EHR for Education & Simulation`,

  // meta tags
  meta: {
    description: { name: 'description', content: 'ChartFlow is an academic EHR designed for colleges, universities, simulation labs, and technical programs. Start your free trial today!' },
    keywords: { name: 'keywords', content: 'ChartFlow, Academic EHR, Educational EHR' },
    ogTitle:  {
      property: 'og:title',
      // optional; similar to titleTemplate, but allows templating with other meta properties
      template (ogTitle) {
        return `${ogTitle} - The Most Powerful EHR for Education & Simulation`
      }
    }
  },
}


export default {
  name: "HomeView",
  setup() {
    useMeta(metaData);
    const contact = ref({});
    const show_form = ref(true);
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const router = useRouter();

    return {
      contact,
      show_form,
      contact_us(){
        show_form.value = false;
        userStore.ContactUs({
          first_name: contact.value.first_name,
          last_name: contact.value.last_name,
          email: contact.value.email,
          role: contact.value.role,
          school: contact.value.school,
          program: contact.value.program,
          help: contact.value.help,
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
