<template>
  <draggable v-model="flowsheet.flowsheets" item-key="uid" group="flowsheets">
        <template #item="{element}">
          <Component :is="flookup(element.uid)" :patient_uid="patient.uid" :flowsheet_uid="element.uid" @click="$emit('selected', element)" :style="is_active(element.uid)"></Component>
        </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import {onMounted, ref} from 'vue'
import PatientFlowsheet from "../../views/patient/PatientFlowsheet.vue";
import {usePatientStore} from "../../stores/patient";
import {storeToRefs} from "pinia";
import PatientDetails from "../../views/patient/PatientDetails.vue";
import VisitHistory from "../../views/patient/VisitHistory.vue";

export default {
  name: "Summary",
  components: {
    PatientFlowsheet,
    draggable
  },
  emits: ["selected"],
  props: ['flowsheet', 'active_flowsheet'],
  setup(props){
    const patientStore = usePatientStore();
    const { patient } = storeToRefs(patientStore);

    onMounted(() => {
      if(props.flowsheet.flowsheets===null || props.flowsheet.flowsheets === undefined){
        props.flowsheet.flowsheets = [];
      }
    });

    return {
      flookup(flowsheet_uid){
        switch(flowsheet_uid){
          case 'patient_details':
            return PatientDetails;
          case 'visit_history':
            return VisitHistory;
          default:
            return PatientFlowsheet
        }
      },
      is_active(flowsheet_uid){
        if(props.active_flowsheet && props.active_flowsheet.uid === flowsheet_uid){
          return {
            border: '1px solid #000'
          }
        }
        return {}
      },
      patient
    }
  }
}
</script>

<style scoped>

</style>