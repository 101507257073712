<template>
  <component :is="layout"></component>
</template>

<script>
import {useUserStore} from "../stores/user";
import {useRegionStore} from "../stores/region";
import Default from "./Default.vue";
import LoggedIn from "./LoggedIn.vue";
import {storeToRefs} from "pinia";
import StudentLayout from "./StudentLayout.vue";

export default {
  name: "ComputedLayout",
  setup(props, { attrs, slots }) {
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore)
    return { userStore}
  },
  computed: {
    layout() {
      if(this.userStore.default_route === '/student') return StudentLayout;
      if(this.userStore.default_route === '/dashboard') return LoggedIn;
      if(this.userStore.default_route === '/user') return LoggedIn;
      return LoggedIn;
    }
  }
}
</script>

<style scoped>

</style>
