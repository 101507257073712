<template>
  <template v-if="!edit">
    <span v-if="frequency">{{frequency}}</span> <span v-if="prn">PRN</span>
  </template>
  <template v-else>
    <div class="row">
      <q-select class="col" outlined :label=label v-model="frequency" :options="Object.keys(frequencies)"></q-select>
      <q-checkbox class="col-shrink" label="PRN" v-model="prn"></q-checkbox>
    </div>
  </template>
</template>

<script>
import {onMounted, ref, watch} from 'vue';
import {frequencies} from "@/util";

export default {
  name: "FrequencyField",
  props: ['label', 'modelValue', 'field', 'edit', 'readonly', 'outlined', 'dense'],
  emits: ['update:modelValue'],
  setup(props, context){
    const frequency = ref(null);
    const prn = ref(false);

    onMounted(() => {
      if(props.modelValue) {
        frequency.value = props.modelValue.frequency;
        prn.value = props.modelValue.prn;
      }
    });
    watch(frequency, (newVal) => {
      context.emit('update:modelValue', {
        frequency: newVal,
        prn: prn.value
      })
    })
    watch(prn, (newVal) => {
      context.emit('update:modelValue', {
        frequency: frequency.value,
        prn: newVal
      })
    })
    watch(() => props.modelValue, (newVal) => {
      if(newVal) {
        frequency.value = newVal.frequency;
        prn.value = newVal.prn
      }
      else {
        frequency.value = null;
        prn.value = false;
      }
    }, {immediate: true})

    return {
      frequencies,
      frequency,
      prn,
    }
  }

}
</script>

<style scoped>

</style>