import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Quasar, Dialog, Meta, Notify } from 'quasar'

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'

// Import Quasar css
import 'quasar/src/css/index.sass'
import "quasar/src/css/flex-addon.sass";

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from './App.vue'
import router from './router'

import { ApmVuePlugin } from '@elastic/apm-rum-vue'

const myApp = createApp(App)

myApp.use(router)
myApp.use(ApmVuePlugin, {
  router,
  config: {
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'Chartflow Frontend',
    environment: import.meta.env.VITE_STAGE,

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: import.meta.env.VITE_ELASTIC_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: ''
  }
})
myApp.use(Quasar, {
  plugins: {
    Dialog,
    Meta,
    Notify
  }, // import Quasar plugins and add here
  config: {
    /*
    brand: {
      // primary: '#e46262',
      // ... or all other brand colors
    },*/
    notify: {

      position: 'top-right',
    }
    /*loading: {...}, // default set of options for Loading Quasar plugin
    loadingBar: { ... }, // settings for LoadingBar Quasar plugin
    // ..and many more (check Installation card on each Quasar component/directive/plugin)
    */
  }
})
myApp.use(createPinia())

// Assumes you have a <div id="app"></div> in your index.html
myApp.mount('#app')


